import config from 'config'

import { DISTRIBUTION_TYPE } from 'constants/panels/distributionType'
import { PASSWORD_STRENGTH } from 'constants/passwordStrength'
import { PATHS } from 'constants/router'
import { REPORT_TYPES, HEATMAP_GRADIENT_NAMES } from 'constants/reports'
import { URL_VALIDATION_SKIP_PREFIX } from 'constants/regex'

const partials = {
	bubble_contact_us: ' If you need to extend this limit, please contact us.',
}

const tryAgainLater = 'Your request cannot be completed. Please try again later'

const liveSlides = 'LiveSlides'

export const messages = {
	// used on various places in dashboard
	_dashboard_document_title: 'GroupSolver Dashboard',
	accounts: 'Accounts',
	account_no_card: 'You have no card added under the account',
	account_no_card_short: 'No saved card',
	actions: 'Actions',
	add: 'Add',
	add_more: 'Add more',
	add_new: 'Add new',
	add_slide: 'Add slide',
	add_task: 'Add task',
	admin: 'Admin',
	admin_panel: 'Admin Panel',
	advanced_options: 'Advanced options',
	advanced_settings: 'Advanced settings',
	after: 'after',
	age: 'Age',
	agree: 'agree',
	all: 'All',
	all_saved: 'All saved',
	all_responses_data: 'All responses',
	and: 'and',
	annual: 'annual',
	annually: 'Annually',
	answer: 'Answer',
	answers: 'Answers',
	anyOf: 'any of',
	ascending: 'Ascending',
	stringAnyOf: 'any of',
	appearance: 'Appearance',
	apply: 'Apply',
	archived: 'Archived',
	are_you_sure: 'Are you sure?',
	are_you_sure_you_want_to_leave: 'Your work is not saved! Are you sure you want to leave?',
	attributes: 'Attributes',
	attribute_with_index: 'Attribute {index}',
	average: 'average',
	back_to_all_reports: 'Back to all reports',
	back: 'Back',
	before: 'before',
	beta: 'BETA',
	between: 'between',
	bottom: 'Bottom',
	bottom_shorthand: 'B',
	branch_labels: 'Branch labels',
	cancel: 'Cancel',
	cannot_be_reversed: 'Once it is done, it cannot be reversed.',
	change: 'Change',
	change_image: 'Change image',
	change_media: 'Change media',
	change_dataset: 'Change dataset',
	change_question: 'Change question',
	no_questions_select: 'No questions to select.',
	change_question_plural: 'Change questions',
	change_photo: 'Change photo',
	chart_counter: '{count} {count, plural, =0 {} one {chart} other {charts}}',
	checkpoint: 'Checkpoint',
	close: 'Close',
	collapse: 'collapse',
	collected: 'collected',
	collection: 'Collection',
	color_settings: 'Color settings',
	column_no_longer_exists: 'Column no longer exists',
	column_index: 'Column {index}',
	columns: 'Columns',
	combine: 'Combine',
	coming_soon: 'Coming soon',
	company: 'Company',
	comparison_rule: 'Comparison rule',
	complete: 'complete',
	completed: 'completed',
	completes: '{count, plural, =0 {completes} one {complete} other {completes}}',
	completes_counter:
		'{collected}/{total} {total, plural, =0 {completes} one {complete} other {completes}}',
	completes_number: '{count} {count, plural, =0 {completes} one {complete} other {completes}}',
	community_modules: 'Community modules',
	condition: 'Condition',
	conditions: 'Conditions',
	confirm: 'Confirm',
	confirm_password: 'Confirm password',
	confirm_with_your_password: 'Confirm with your password',
	constant: 'Constant',
	constants: 'Constants',
	contains: 'Containing',
	content: 'Content',
	continue: 'Continue',
	copied: 'Copied!',
	copied_to_clipboard: 'Copied to clipboard!',
	copy_to_clipboard: 'Copy to clipboard',
	copy_to_library_error_tooltip: 'Please save the flow before copying to template',
	create: 'Create',
	create_account: 'Create account',
	current_password: 'Current password',
	custom: 'custom',
	customize: 'Customize',
	dataset: 'Dataset',
	date_equals: 'date equals',
	dateEquals: 'equal to',
	date: 'Date',
	date_modules: 'Dates',
	data: 'Data',
	data_with_value: 'Data {value}',
	days_count: '{count} {count, plural, =0 {days} one {day} other {days}}',
	default: 'Default',
	default_value: 'Default value',
	delete: 'Delete',
	demo_study: 'Demo study',
	descending: 'Descending',
	description: 'Description',
	impact_brief: 'Impact brief',
	device_too_small_title: 'Your device is too small.',
	device_too_small_content_rotate_report:
		"The report doesn't quite fit in here, but you can change the screen orientation to view it.",
	device_too_small_content_rotate:
		"The page doesn't quite fit in here, but you can change the screen orientation to view it.",
	device_too_small_title_rotate: 'Rotate your phone.',
	device_too_small_for_report_title: "Oops! We can't fit the report in here.",
	device_too_small_for_report_content: 'The screen size is a bit too small to show you the report.',
	device_too_small_content: 'The screen size is a bit too small to show you the page.',
	disabled: 'Disabled',
	disagree: 'disagree',
	discard_changes: 'Discard changes',
	discard: 'Discard',
	dismiss: 'Dismiss',
	dismiss_all: 'Dismiss all',
	display_name: 'Display name',
	dispute: 'Dispute',
	domain: 'Domain',
	dots_placeholder: '...',
	download: 'Download',
	dropped: 'dropped',
	edit: 'Edit',
	editor: 'Editor',
	edit_interview: 'Edit interview',
	edit_question: 'Edit question',
	edit_message: 'Edit message',
	elaboration: '{agree} Agree\n{neutral} Neutral\n{disagree} Disagree',
	email: 'email',
	emails: 'Emails',
	email_address: 'Email address',
	email_header: 'Email',
	empty: 'empty',
	enable: 'Enable',
	enableAutoTranslate: 'Enable autotranslate',
	enter_a_number: 'Enter a number',
	equalTo: 'Exactly',
	endsWith: 'Ending with',
	errors_found: '{count, plural,one {{count} error} other {{count} errors}} found',
	errors: '{count, plural,one {{count} error} other {{count} errors}}',
	error_encountered: "We've encountered an error.",
	ethnicity: 'Ethnicity',
	everyOf: 'every of',
	expand: 'Expand',
	expected_support: 'Expected Support',
	expired: 'Expired',
	expires_on: 'Expires on {date}',
	export: 'Export',
	extend: 'Extend',
	factors: 'Factors',
	filename: 'Filename',
	filter: 'Filter',
	flow: 'Flow',
	flow_modules: 'Flow modules',
	format: 'Format',
	free_trial: 'Free trial',
	from: 'from',
	full_name: 'Full name',
	gender: 'Gender',
	generate: 'Generate',
	generated: 'Generated',
	go_back: 'Go back',
	greaterThan: 'Greater than',
	greaterThanOrEqualTo: 'Greater than or equal to',
	hide_advanced_options: 'Hide advanced options',
	hispanic: 'Hispanic',
	hours: '{count} {count, plural, =0 {hours} one {hour} other {hours}}',
	if: 'if',
	image: 'Image',
	imported: 'Imported',
	imported_modules: 'Imported modules',
	industry: 'Industry',
	incorrect_password: 'Incorrect password. Please try again.',
	info: 'Info',
	invalid_json: 'JSON is invalid',
	is: 'is',
	is_not: 'is not',
	items: 'Items',
	keep: 'Keep',
	knowledge_base: 'Knowledge base',
	label: 'Label',
	layout: 'Layout',
	templates: 'Select Templates',
	language: 'Language',
	last_change: 'Last change: {date}',
	learn_more: 'Learn more',
	leave: 'Leave',
	left: 'Left',
	left_shorthand: 'L',
	lessThan: 'Less than',
	lessThanOrEqualTo: 'Less than or equal to',
	letter: 'Letter',
	library: 'Library',
	library_delete_success: 'Template {libraryName} successfully deleted',
	library_invalid_segments: 'Segments below cannot be copied to template',
	library_search: 'Search for template',
	license_type: 'License type',
	listEmpty: 'empty',
	liveSlides,
	loading: 'Loading',
	log_in: 'log in',
	logic: 'Logic',
	logout: 'Logout',
	looped_modules: 'Looped flow modules',
	manage: 'Manage',
	manage_access: 'Manage access',
	media: 'Media',
	media_object_counter: '{count} {count, plural, =0 {} one {media object} other {media objects}}',
	met: 'met',
	min: '{count} min',
	minutes: '{count} {count, plural, =0 {minutes} one {minute} other {minutes}}',
	minutes_abbr: 'min',
	modified: 'modified',
	modified_date: 'modified: {date}',
	month: 'month',
	monthly: 'Monthly',
	name: 'Name',
	neutral: 'neutral',
	never: 'Never',
	next: 'Next',
	next_step: 'Next step',
	no_answers: 'No answers',
	no_changes: 'No changes',
	no_data_to_present: 'No data to present',
	no_dataset_exists: 'There are no datasets present.',
	no_payments: 'no payments',
	no_theme: 'No theme',
	no_title: 'No title',
	no_valid_panel: `Can't launch study without a valid panel.`,
	no_values: 'No values',
	not_available_abbr: 'N/A',
	notifications: 'Notifications',
	notifications_read: 'ALL YOUR NOTIFICATIONS HAVE BEEN READ',
	numberEqualTo: 'Equal to',
	number_of_answes: 'Number of answers',
	number_placeholder: 'Please select a number',
	number_type_placeholder: 'Please type a number',
	oops: 'Oops!',
	open_chart: 'Open chart',
	option_no_longer_exists: 'Option no longer exists',
	option_or_options_no_longer_exist: 'One or more options no longer exist',
	option_with_index: 'Option {index}',
	option: 'Option',
	options: 'Options',
	or: 'or',
	ordered_by: 'Ordered by',
	organization: 'Organization',
	organization_name: 'Organization name',
	overall_weighted_average: 'Overall weighted average:',
	overquota: 'overquota',
	package: 'Package',
	panel_deleted: 'Panel was deleted',
	panels: 'Panels',
	parameter: 'parameter',
	password: 'Password',
	// eslint-disable-next-line no-template-curly-in-string
	pay: 'Pay ${amount}',
	pay_online: 'Pay {amount} online',
	payment_logs: 'Payments',
	proceed_to_payment: 'Proceed to payment',
	payment_pending: "We're processing your payment",
	payment_pending_description:
		"This could take a few moments. The page will automatically reload after we're done.",
	payment_pending_contact_us:
		'Payment processing is taking longer than expected. Please click here to Contact us so we can look into it.',
	payment_verify: 'Verify payment',
	percent: 'percent',
	percent_value: '{value}%',
	personal_details: 'Personal details',
	phone: 'Phone',
	phone_number: 'Phone number',
	pin_all: 'Pin all',
	pipeline: 'Pipeline',
	placeholder: 'Placeholder',
	plus_n_more: '+ {count} more',
	post_analytics: 'Post-analytics',
	'post_analytics.completes_counter':
		'{total} {total, plural, =0 {completes} one {complete} other {completes}}',
	posted_on: 'Posted on: ',
	preparing_panels: "We're preparing your panels",
	preparing_panels_description: 'This could take a few moments',
	previews: '{count, plural, =0 {previews} one {preview} other {previews}}',
	previews_counter:
		'{collected}/{total} {total, plural, =0 {previews} one {preview} other {previews}}',
	previous: 'Previous',
	price: 'Price',
	print: 'Print',
	print_report: 'Print report',
	privacy_policy: 'Privacy Policy',
	proceed: 'Proceed',
	processing: 'Processing',
	processing_in_progress: 'Data processing in progress',
	profile: 'Profile',
	purchase: 'Purchase',
	quality_terminate: 'quality terminate',
	question: 'Question',
	questions: 'Questions',
	randomize: 'Randomize',
	randomize_all: 'Randomize all',
	range: 'In range',
	read_more: 'Read more',
	recalculating: 'Recalculating',
	recalculation_disabled: 'Recalculation is disabled in study tags',
	recalculation_in_progress: 'Recalculation is in progress',
	recent_blogs: 'Recent blogs',
	refresh: 'Refresh',
	regexp: 'Regexp',
	region: 'Region',
	remove: 'Remove',
	remove_custom_color: 'Remove custom color',
	reply: 'Reply',
	reports: 'Reports',
	reports_count_limit: "You've reached the limit of {count} reports per study.",
	request_early_access: 'Request early access',
	request_early_access_success: 'You are on the waiting list. We will be in touch shortly.',
	reset: 'Reset',
	no_reports: 'No reports',
	respondent: '{count, plural, =0 {respondents} one {respondent} other {respondents}}',
	respondents_count: 'Respondents count',
	response: 'Response',
	restore: 'Restore',
	request_failed:
		'Failed to complete your request. Please try again and contact us if the problem persists.',
	request_failed_auth_page:
		'Failed to complete your request. Please try again and {contactUs} if the problem persists.',
	request_failed_message: tryAgainLater,
	request_failed_try_again: 'Failed to complete your request. Click here to try again.',
	request: 'Request',
	request_error_notification: 'There was an error processing your request. Please try again.',
	request_error_notification_short: 'There was an error processing your request.',
	request_error_notification_title: 'Problem processing request',
	request_update_account_info_success_notification: 'Account information successfully updated.',
	request_update_billing_info_success_notification: 'Billing information successfully updated.',
	request_image_upload_failed: 'Image upload failed. Please try again.',
	requested: 'requested',
	revert: 'Revert',
	review: 'Review',
	right: 'Right',
	right_shorthand: 'R',
	row: 'Row',
	rows: 'Rows',
	rule: 'Rule',
	rules: 'Rules',
	rule_name: 'Rule name',
	save: 'Save',
	save_changes: 'Save changes',
	save_changes_reminder: "Don't forget to save your changes.",
	save_or_discard_to_continue: 'Please save or discard your changes to continue.',
	save_settings: 'Save settings',
	saved_card: 'Saved card',
	search: 'Search',
	search_questions_reports: 'Search questions and reports',
	sec: '{count} sec',
	seconds: '{count} {count, plural, =0 {seconds} one {second} other {seconds}}',
	sections: 'Sections',
	segment_label: 'Segment',
	segments_label: 'Segments',
	segments: 'Segments',
	segment_size: 'Segment size',
	segmentation_typing_tool: 'Segmentation typing tool',
	select_list_placeholder: 'Select list to import',
	select_list_info: 'Please select a list to import',
	selected_list_removed: 'Selected list no longer exists',
	separate: 'Separate',
	set: 'Set',
	settings: 'Settings',
	share: 'Share',
	show: 'Show',
	show_advanced_options: 'Show advanced options',
	show_all: 'Show all',
	show_unread: 'Show unread',
	size: 'Size',
	slide: 'Slide',
	slide_no_title: 'Slide without title',
	startsWith: 'Starting with',
	start_date: 'Start date',
	start_from_scratch: 'Start from scratch',
	statements: 'statements',
	study_deleted: 'Study was deleted',
	study_loading_error: 'Please try again and contact us if the problem persists.',
	study_object_no_longer_exists: 'Study object no longer exists',
	study_search: 'Search for study',
	study_bots_error:
		'Testing bots have found errors in the study. Please click here to contact our technical support team.',
	study_status_error:
		'We are very sorry, but an unexpected error occurred. Please click here to contact our technical support team.',
	study_impact_header: 'Get a research grant to create societal impact',
	study_impact_description:
		'We provide the platform and expertise needed to conduct efficient research on critical issues without expense.',
	study_impact_button_learn: 'Learn More',
	study_impact_button_view_more: 'View more',
	state: 'State',
	stringEqualTo: 'Equal to',
	style: 'Style',
	submit: 'Submit',
	succeeded: 'Succeeded',
	support: 'Support',
	table: 'Table',
	tasks: 'Tasks',
	test: 'Test',
	terminate: 'terminate',
	terminated: 'terminated',
	terms_and_conditions: 'Terms and Conditions',
	text_simple_placeholder: 'type your text',
	theme: 'Theme',
	theme_name: 'Theme Name',
	theme_support: 'Theme support',
	themes: 'Themes',
	title: 'Title',
	to_be_deleted: 'Will be deleted {time}',
	to_be_deleted_study: 'Study will be deleted {time}',
	total_support_strength: 'Total Support Strength',
	top: 'Top',
	top_shorthand: 'T',
	translate_study_design_manual: 'Manual translate',
	translate_study_design_ai: 'AI translate',
	translate_study_design_ai_in_progress: 'AI translate in progress',
	translate_study_design_ai_in_progress_message:
		'This could take a few moments. You can close this tab and come back later.',
	translate_study_download_tooltip:
		'1. Select language to download.\n2. Click on Download translation sheet.',
	translate_study_download_language: 'Download translations in:',
	translate_study_upload_tooltip:
		'1. Download translation sheet in the download section.\n2. Add translations to "translation" column.\n3. Select language of translations that you added to "translation" column.\n4. Upload modified translation sheet',
	translate_study_upload_language: 'Upload translations in:',
	translate_study_design: 'Translate study design',
	translate_answers: 'Translate answers',
	translate_download_sheet: 'Download translation sheet',
	translate_upload_sheet: 'Upload translation sheet',
	translate_study_design_upload_confirmation: 'Uploading translations overrides the study design',
	translate_study_design_download: 'Download study design translations',
	translate_study_design_upload: 'Upload study design translations',
	translate_answers_upload_confirmation:
		'Uploading translations overrides the answers in collected data',
	translate_answers_download: 'Download answers translations',
	translate_answers_upload: 'Upload answers translations',
	translate_study_ai_button: 'AI translate study design',
	translate_study_ai_from_language: 'Translate study design from',
	translate_study_ai_to_language: 'Translate study design to',
	translate_study_ai_same_from_to_error:
		'To language must be a different language than From language',
	translate_study_ai_info:
		'AI study design translation could take few moments and study design will not be editable during translating.\n\nThis process cannot be interrupted.',
	translate_study_ai_confirm_title: 'Translate study design?',
	translate_study_ai_confirm_message:
		'This action will override all labels in {toLanguage} with labels translated from {fromLanguage}.',
	try_again: 'Try again',
	try_again_click: 'Click here to try again',
	type: 'Type',
	unnamed_group: 'Unnamed group',
	unnamed_module: 'Unnamed module',
	unnamed_variable: 'Unnamed variable',
	unsaved_changes_title: 'You have unsaved changes',
	unthemed_answers: 'Unthemed answers',
	unthemed: 'Unthemed',
	unused: 'Unused',
	update: 'Update',
	user: 'User',
	users: 'Users',
	user_id: 'User ID',
	user_no_longer_exists: 'User no longer exists',
	user_page: 'User page',
	using: 'using',
	view_more: 'view more...',
	was: 'was',
	was_not: 'was not',
	weighted_average: 'Weighted average:',
	weighted_average_label: 'Weighted average',
	weighted_average_segmented: 'Weighted average for {segmentLabel}:',
	word_cloud: 'Word Cloud',
	year: 'year',
	your_email: 'Your email',
	your_name: 'Your name',
	display_name_label_short: 'What name should we call you?',
	display_name_label_long: 'What name should we call you? (in emails, comments, etc.)',
	incorrect_sms_code: 'Incorrect SMS code. Please try again.',
	no_more_data_to_show: 'No more data to show',

	/**
	 * COLORS
	 */
	azure_blue: 'Azure blue',
	red: 'Red',
	forest_green: 'Forest green',
	sky_blue: 'Sky blue',
	yellow: 'Yellow',
	magenta: 'Magenta',
	cyan: 'Cyan',
	orange: 'Orange',
	green: 'Green',
	eggplant: 'Eggplant',
	yellow_orange: 'Yellow orange',
	lime: 'Lime',
	violet: 'Violet',
	royal_blue: 'Royal blue',
	black: 'Black',
	blue: 'Blue',
	white: 'White',

	/**
	 * ACTION_BUTTON
	 */
	'action_button.minTimer':
		'Proceed button enabled {count, plural,=0 {immediately} one {after {count} second} other {after {count} seconds}}',
	'action_button.maxTimer':
		'Auto-proceed {count, plural,=0 {immediately} one {after {count} second} other {after {count} seconds}}',
	'action_button.maxTimer.disabled': 'Auto-proceed',
	'actionButton.title': 'Action button',
	'actionButton.add_module.title': 'Proceed',
	'actionButton.description':
		"Use this button whenever you need to confirm the respondent's consent to proceed in your interview before displaying any further questions.",
	'uicommand.proceed_button': 'Proceed button',
	'uicommand.proceed_button.settings': 'Button settings',

	/**
	 * HISTORY_FILTER
	 */
	'historyFilter.title': 'History filter',
	'historyFilter.add_module.title': 'History',
	'historyFilter.description':
		"Whenever you need to limit the respondent's ability to view his or her past verbatims, you can blur them temporarily or remove them entirely.",
	'historyFilter.unblur.title': 'Unblur history',
	'historyFilter.unblur.description':
		'In case your history is blurred at this point, it will get sharpened back to readable state.',
	'historyFilter.blur.title': 'Blur history',
	'historyFilter.blur.description':
		"If you want to just reduce respondent's contact with history, you can blur it to make it unreadable. It can be unblurred in following checkpoint.",
	'historyFilter.clear.title': 'Clear history',
	'historyFilter.clear.description':
		"If you want to create a visually stronger break in the flow of interview, you can remove the dialogue history and continue from the blank screen. Cleared history can't be brought back.",
	'historyFilter.settings_label': 'Filter settings',

	/**
	 * routes/_study/StudyDesign/Detail/FlowDetail/_details/If/_components/Conditional/Conditional.js
	 */
	'checkpoint_conditional.no_options':
		'There are no modules to base condition on yet. Add Choice module before this module to enable conditions.',
	'checkpoint.conditional.name.block': 'Block name',
	'checkpoint.conditional.name.track': 'Track name',
	'checkpoint.conditional.name.randomizer': 'Randomizer name',
	'checkpoint.conditional.name.track_randomizer': 'Track randomizer name',
	'checkpoint.conditional.module_filter': 'Filter modules by',
	'checkpoint.conditional.name.default': 'Conditional branch name',

	/**
	 * components/_formik/_complex/ConditionBuilder/ConditionBuilder.js
	 */
	'condition_builder.all_apply': 'All conditions are met',
	'condition_builder.one_applies': 'At least one condition is met',
	'condition_builder.add_condition': 'Add condition',
	'condition_builder.add_another_condition': 'Add another condition',

	/**
	 * components/_formik/_complex/ConditionBuilder/_components/Condition/Condition.js
	 */
	'condition.has_invalid_selection': 'Please select comparison function',
	'condition.no_study_object_selected': 'Please select a respondent attribute',
	'condition.invalid_option_selected': 'One or more selected options no longer exist',
	'condition.study_object_missed': 'Question that was selected no longer exists.',
	'condition.invalid_freetext_input': '{type} is invalid.',
	'condition.every_of': 'Every of',
	'condition.any_of': 'Any of',
	'condition.exactly': 'Exactly',
	'condition.anyOf.min': 'MIN {count} of {type} selected',
	'condition.anyOf.max': 'MAX {count} of {type} selected',
	'condition.seen_by_respondent': 'seen by respondent',
	'condition.in_target_group': 'in quota target group',
	'condition.label.in_taget_group': 'in target group of {quota}',
	'condition.minAnyOfError': 'Min selection cannot be bigger than Choice question max selection',
	'condition.include_no_data': 'Count respondents who did not see the question',
	'condition.invalidExpectedValue': 'The {type} condition is invalid. Please enter a number.',
	'condition.invalidDateSequenceError': 'Selected date range is not applicable',
	'condition.invalidPointsSequenceError': 'Selected points range is not applicable',
	'condition.invalidDateFormatError': 'Date has invalid format',
	'condition.from': 'From',
	'condition.to': 'To',
	'condition.type.follow_up': 'Follow up',
	'condition.type.evaluation_position': 'Evaluation position',
	'condition.evaluation_is': 'evaluation is',
	'condition.evaluation_is_not': 'evaluation is not',
	'condition.follow_up_is': 'answer is',
	'condition.follow_up_is_not': 'answer is not',
	'condition.respondent_is': 'respondent is',
	'condition.respondent_is_not': 'respondent is not',
	'condition.oeq.evaluation_before_solve':
		'Conditions based on AI Open-End™ are available after solving the study.',
	'condition.equal_to': 'Equal to',
	'condition.greater_than': 'Greater than',
	'condition.greater_than_or_equal_to': 'Greater than or equal to',
	'condition.less_than_or_equal_to': 'Less than or equal to',
	'condition.less_than': 'Less than',
	'condition.contains': 'Containing',
	'condition.starts_with': 'Starting with',
	'condition.ends_with': 'Ending with',
	'condition.regexp': 'Regexp',
	'condition.string_any_of.add': 'Add value',
	'condition.value_is': 'value is',
	'condition.value_is_not': 'value is not',
	'condition.position_is': 'position is',
	'condition.position_is_not': 'position is not',
	'condition.quota.cannot_edit_name': 'Quota name cannot be edited in live study',
	'condition.invalid_follow_up': 'Follow up input cannot be empty',
	'condition.list_length.is': '# of items is',
	'condition.list_length.is_not': '# of items is not',
	'condition.respondent_source.in_panel': 'in panel',
	'condition.respondent_source.matches': 'matches',
	'condition.respondent_source.does_not_match': 'does not match',

	/**
	 * components/_formik/_complex/ConditionBuilder/_components/Condition/StatementsMultiselect.js
	 */
	'multiselect.no_statement_selected': 'Please select an answer',
	'multiselect.cannot_load_statements':
		'{clickHere} to try again or {contactUs} if the problem persists',
	click_here: 'Click here',
	/**
	 * components/_scaffolding/ConditionLabel/ConditionLabel.js
	 */
	the_answer: 'the answer',

	/**
	 * UI_COMMAND - CONDITION
	 */
	'condition.title': 'Condition',
	'condition.description':
		'You can build logical conditions which will route incoming respondents to specific branches of the flow. Respondents who do not meet the condition will be automatically routed to the module directly following this conditional branch',
	'condition.conditions_label': 'Proceed to conditional branch if',

	/**
	 * UI_COMMAND -QUOTA
	 */
	'quota.title': 'Quota',
	'quota.description':
		'This checkpoint increments the number of respondents that have satisfied the defined quota every time a respondent fits the conditions set below and completes the interview. After reaching the quota limit, all further respondents satisfying the condition will be routed to the overquota endpoint.',
	'quota.conditions_label': 'Increment the count when',
	'quota.limit_value': 'Limit value',
	'quota.units': 'Units',
	'quota.definition': 'Quota definition',
	'quota.container_title': 'Over {number}{unit} of {branchName}',

	/**
	 * UI_COMMAND - BLOCK
	 */
	'block.title': 'Block',
	'block.add_module.title': 'Block',
	'block.description':
		'Blocks allow you to divide study flow to smaller parts that behave as one flow module. Respondents do not interact with blocks.',
	'block.shortname.placeholder': 'Unnamed block',
	'block.settings': 'Block settings',

	/**
	 * UI_COMMAND - TRACK
	 */
	'track.title': 'Track',
	'track.add_module.title': 'Track',
	'track.description':
		'Tracks allow you to divide study flow to smaller parts that behave as one flow module. Respondents do not interact with tracks.',
	'track.shortname.placeholder': 'Unnamed track',
	'track.settings': 'Track settings',

	/**
	 * UI_COMMAND - RANDOMIZER
	 */
	'randomizer.add_module.title': 'Module randomizer',
	'randomizer.title': 'Module randomizer',
	'randomizer.description':
		'Modules inside the randomizer are presented to respondent in random order. You can use slider to set how many modules should respondents see.',
	'randomizer.modules_shown': 'Show {count, plural, one {{count} module} other {{count} modules}}',
	'randomizer.container_description.of': 'Randomize and show {count} of {all} modules',
	'randomizer.container_description.all': 'Randomize and show ALL modules',
	'randomizer.empty.error':
		'Randomizer cannot be empty. Add modules inside the Randomizer or remove it from the flow.',

	/**
	 * UI_COMMAND - TRACK RANDOMIZER
	 */
	'track_randomizer.add_module.title': 'Track randomizer',
	'track_randomizer.title': 'Track randomizer',
	'track_randomizer.description':
		'Tracks inside the randomizer are presented to respondent in random order. You can use slider to set how many tracks should respondents see.',
	'track_randomizer.modules_shown':
		'Show {count, plural, one {{count} track} other {{count} tracks}}',
	'track_randomizer.container_description.of': 'Randomize and show {count} of {all} tracks',
	'track_randomizer.container_description.all': 'Randomize and show ALL tracks',
	'track_randomizer.empty.error':
		'Track randomizer cannot be empty. Add tracks inside the Track randomizer or remove it from the flow.',
	// used in filtered randomizer, filtered matrix, list matrix and loop
	'randomizer.allow_skip': "Skip if there's nothing to present to respondent",
	'randomizer.allow_skip.enabled':
		'Respondent will skip the module and its nested modules and continue in the study.',
	'randomizer.allow_skip.enabled.matrix':
		'Respondent will skip the module and continue in the study.',
	'randomizer.allow_skip.disabled':
		"Respondent won't be able to continue if there's nothing to present and will be counted as drop-off",

	/**
	 * UI_COMMAND - LOOP
	 */
	'loop.add_module.title': 'Loop',
	'loop.title': 'Loop',
	'loop.description':
		'Select a list you want to include and add questions to the loop. The loop will cycle through the list and ask questions for each item.',
	'loop.detail.short_name.label': 'Loop name',
	'loop.detail.max_iterations': 'Max iterations: {count}',
	'loop.detail.max_iterations.matrix': 'Max questions: {count}',
	loop_settings: 'Loop settings',
	'loop.detail.empty.error':
		'Loop cannot be empty. Add modules inside the Loop or remove it from the flow.',

	/**
	 * LoopItemReferencingTooltip
	 */
	'loop.referencing_items.headline.list': 'Referencing list items in loops',
	'loop.referencing_items.headline.matrix': 'Referencing list items in matrix question',
	'loop.referencing_items.tip.list':
		'Use [[item]] syntax to reference list items in loop messages.\n• [[item]] translates to value of list identifier column\n• [[item.column_name]] translates to value in column_name column of uploaded file',
	'loop.referencing_items.tip.matrix':
		'Use [[item]] syntax to reference list items in matrix questions.\n• [[item]] translates to value of list identifier column\n• [[item.column_name]] translates to value in column_name column of uploaded file',

	/**
	 * A_MESSAGE
	 */
	'message.detail.stop_for_review': 'Stop for review',
	'message.detail.seconds_to_read': 'Seconds to read',
	'message.detail.media_alt.label': 'Media description',
	'message.detail.media_alt.error': 'Media description cannot be empty.',
	'message.detail.media_alt.info':
		'Media description for screen readers used by visually impaired users.',
	'message.detail.proceed_button_label': 'Proceed button label',
	'message.detail.media.start_in_fullscreen': 'Start in fullscreen view',
	'message.detail.media.blur': 'Blur before the first view',
	'message.detail.media.videoAutoPlay': 'Autoplay video',
	'message.detail.media.disable': 'Disable after the first view',
	'message.placeholder': 'New message',
	'message.title': 'Message',
	'message.title.preview': 'Preview message',
	'message.description':
		'Anything you need to tell or ask respondents can be communicated through messages. You can add text and/or media.',
	'message.description.preview':
		'Preview message is displayed to respondents in preview mode. Respondents do not see it after launching the study',
	'message.min_timeout_error': 'Timeout has to be bigger than 0.5 seconds',
	'message.text_too_long': 'Message is too long',
	'message.question.default': 'Type in your question',
	'message.tooltip_text': '"Stop for review" cannot be turned off for video files.',
	'message.missing_media': 'Please add a media object or remove media objects from all languages',

	/**
	 * A_OEQ
	 */
	'oeq.title': 'AI Open-End™',
	'oeq.description':
		'AI Open-End™ questions use GroupSolver proprietary technology to collect and quantify natural language answers to open-ended questions.',
	'oeq.default_dataset_name': 'AI Open-End',
	'oeq.phase.ideation.enable_splitter': 'Use sentence splitter',
	'oeq.phase.elimination': 'Elimination phase',
	'oeq.phase.elaboration': 'Evaluation phase',
	'oeq.phase.elaboration.minEvaluations': 'Min evaluations',
	'oeq.phase.elaboration.minEvaluationsError': 'Min evaluations cannot be less than 0',
	'oeq.phase.elaboration.maxEvaluations': 'Max evaluations',
	'oeq.phase.elaboration.maxEvaluationsError': 'Max evaluations cannot be less than 0',
	'oeq.phase.elaboration.minMaxError': 'Min evaluations cannot be bigger than max evaluations',
	'oeq.preseeded_statements': 'Preseeded answers',
	'oeq.preseeded_statements.placeholder': 'Enter answer',
	'oeq.preseeded_statements.error.length': 'Preseeded answer cannot be empty',
	'oeq.preseeded_statements.add': 'Add preseeded answer',
	'oeq.preseeded_statements.language_info': 'Enter preseeded statements in {language}',
	'oeq.ideation.idea_pool_limit': 'Idea pool limit',
	'oeq.custom_translations': 'Custom translations',
	'oeq.ideate_placeholder': 'Ideation placeholder',
	'oeq.elaborate_intro': 'Evaluation introduction',
	'oeq.elaborate_agree_label': 'Agree button label',
	'oeq.elaborate_disagree_label': 'Disagree button label',
	'oeq.elaborate_indifferent_label': 'Neutral button label',
	'oeq.elaborate_unclear_statement_label': 'Unclear answer button label',
	'oeq.custom_translations.reset_defaults': 'Reset defaults',

	/**
	 * A_FREE_TEXT
	 */
	'freetext.add_module.text': 'Text',
	'freetext.add_module.date': 'US Date',
	'freetext.add_module.email': 'Email',
	'freetext.add_module.zip': 'ZIP',
	'freetext.add_module.integer': 'Integer',
	'freetext.add_module.number': 'Number',
	'freetext.add_module.amount': 'Amount',
	'freetext.add_module.phone': 'US Phone',
	'freetext.title': 'Formatted text',
	'freetext.description':
		'You can choose to force respondents to enter a predefined form of input, set maximum limit for number of characters, or allow respondent to skip to next question.',
	'freetext.question.description':
		'Formatted text question allows you to collect answers as typed exactly by respondents.',
	'freetext.text.title': 'Text',
	'freetext.text.description':
		'You can choose between single line input or multiline textarea, modify input placeholder, set maximum number of characters or allow the respondent to skip the question.',
	'freetext.date.title': 'US Date',
	'freetext.date.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.phone.title': 'US Phone',
	'freetext.phone.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.email.title': 'Email',
	'freetext.email.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.zip.title': 'ZIP code',
	'freetext.zip.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.integer.title': 'Integer',
	'freetext.integer.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.number.title': 'Number',
	'freetext.number.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.amount.title': 'Amount',
	'freetext.amount.description':
		'You can modify the input instruction or allow the respondent to skip the question.',
	'freetext.detail.max_input_length_label': 'MAX {count} characters can be entered',
	'freetext.detail.use_textarea': 'Use text area instead of linear input',
	'freetext.detail.enter_your_answer': 'Enter your answer',
	'freetext.detail.skip': 'Show skip button',
	'freetext.detail.expression_hint': 'Expression instruction',

	/**
	 * A_CHOICE
	 */
	'choice.title': 'Choice',
	'choice.dataset.description':
		'Use sliders to control how many options must be selected to proceed. You can also randomize order of options, anchor them in place or assign numeric codes.',
	'choice.question.description':
		'Choice question allows respondents to pick from the options provided by you.',
	'choice.detail.collapse_all': 'Collapse all',
	'choice.detail.expand_all': 'Expand all',
	'choice.detail.max_options_label': 'MAX {count} of options allowed to choose',
	'choice.detail.min_options_label': 'MIN {count} of options mandatory to choose',
	'choice.detail.options_shown_limit':
		'Show {count} {count, plural,=0 {options} one {option} other {options}}',
	'choice.detail.pin_options': 'Pin all options',
	'choice.detail.pinned': 'Pinned',
	'choice.detail.randomize_all': 'Randomize all options',
	'choice.detail.randomized': 'Randomized',
	'choice.detail.reset_code_values': 'Reset code values',
	'choice.detail.show_option_labels': 'Show option labels',
	'choice.detail.use_select': 'Enable search within options',
	'choice.detail.no_image':
		'All options must have an image. Add the image or remove the option from the list.',
	'choice.detail.empty_options': 'Option label cannot be empty.',
	'choice.detail.non_unique_options': 'Label has to be unique for each option',
	'choice.detail.missing_option_code': 'CODE value is required',
	'choice.detail.non_unique_simple_names': 'Simplename has to be unique for each option',
	'choice.detail.non_unique_ids': 'Id has to be unique for each option',
	'choice.detail.none_of_these.label': 'None of these label',
	'choice.detail.none_of_these.add': 'Add "None of these" option',
	'choice.detail.add_option': 'Add option',
	'choice.detail.option.label': 'Option {index}',
	'choice.option.shortName': 'Shortname',
	'choice.option.code': 'Code',
	'choice.option.id': 'Option id',
	'choice.option.invalid_id': 'Invalid option id',
	'choice.option.generate_id': 'Generate new id',
	'choice.option.default_label': 'Choice option {index}',
	'choice.detail.trap.mark_as_trap': 'Mark as trap question',
	'choice.detail.trap.correct_answers': 'Correct answer',
	'choice.detail.trap.selection': 'Selection',
	'choice.question.format': 'Question format',
	'choice.question.format.checkbox': 'List',
	'choice.question.format.select': 'Dropdown with search',
	'choice.question.format.image': 'Image choice',
	'choice.question.format.image_no_labels': 'Image choice without labels',
	'choice.option.label': 'Option label',
	'choice.detail.mode': 'Forward options from a list',
	'choice.detail.list.label': 'Select option label',
	'choice.detail.list.image_choice': 'Lists cannot be imported to Image choice questions',
	'choice.detail.list.invalid.column':
		'Selected column no longer exists in list or it does not contain unique values',
	'choice.detail.list.options_order': 'Options order',
	'choice.detail.list.options_order.list': 'Use order from input list',

	'choice.options.pin_option': 'Pin this option',
	'choice.options.randomize_option': 'Randomize this option',
	'choice.options.add_above': 'Add new option above',
	'choice.options.add_below': 'Add new option below',

	'choice.detail.align_options': 'Align options',
	'choice.detail.align_options.text_start': 'Text start',
	'choice.detail.align_options.text_end': 'Text end',

	/**
	 * A_IMAGE_CHOCIE
	 */
	'imageChoice.title': 'Image Choice',
	'imageChoice.question.description': 'In image choice questions, choices are presented as images.',
	'imageChoice.dataset.description':
		'Use sliders to control how many images must be selected to proceed. You can also randomize their order, anchor them in place or assign numeric codes. Each image option must include unique text in its description.',

	/**
	 * MATRIX_CHOICE
	 */
	'matrixChoice.matrixQuestion': 'Matrix question',
	'matrixImageChoice.title': 'Image matrix',
	'matrixImageChoice.question.description':
		'Matrix questions allows you to ask one question about multiple attributes (matrix rows), which will be all evaluated using the same options (matrix columns). The options are presented as images.',
	'matrixImageChoice.dataset.description':
		'Use sliders to control how many options must be selected to proceed. You can also randomize order of options, anchor them in place or assign numeric codes.',
	'matrixChoice.attribute.label': 'Attribute {index}',
	'matrixChoice.title': 'Matrix',
	'matrixChoice.question.description':
		'Matrix questions allows you to ask one question about multiple attributes (matrix rows), which will be all evaluated using the same options (matrix columns).',
	'matrixChoice.dataset.description':
		'Use sliders to control how many options must be selected to proceed. You can also randomize order of options, anchor them in place or assign numeric codes.',
	'matrixChoice.shared_message': 'Shared message',
	'matrixChoice.detail.shortNamePrefix': 'Display name prefix',
	'matrixChoice.detail.add_question': 'Add attribute',
	'matrixChoice.detail.randomizer.show_questions':
		'Show {count} {count, plural,=0 {questions} one {question} other {questions}}',
	'add_module.matrixChoice': 'Matrix',
	'add_module.matrixImageChoice': 'Image Matrix',

	'matrixChoice.detail.filter_questions': 'Filter attributes by',
	'matrixChoice.detail.filter_questions_error': 'Filter is invalid',
	'matrixChoice.question.actions.add_above': 'Add new question above',
	'matrixChoice.question.actions.add_below': 'Add new question below',
	'matrixChoice.question.nonUniqueSimpleName':
		'All display names within the matrix must be unique.',
	'matrixChoice.detail.mode': 'Forward attributes from a list',
	'matrixChoice.list.question.default_text':
		'Make sure to include {itemLabel} in question text to present unique question for every list item',
	'matrixChoice.list.attribute_label': 'Select attribute display name',

	/**
	 * A_SNIPPET
	 */
	'snippet.title': 'Snippet',
	'snippet.description':
		'Snippets are custom module with extended capabilities created by GroupSolver internal team.',
	'snippet.functionality_description': 'Functionality description',
	'snippet.code_validator': 'Code validator',
	'snippet.quota.condition': 'Quota condition',
	'snippet.module.description': 'Snippet description',
	'snippet.name': 'Snippet name',

	/**
	 * SET_VARIABLE
	 */
	'set_variable.title': 'Set variable',
	'set_variable.description':
		'Evaluates proFlow function with given arguments and sets the result to variable defined by Variable name field.',
	'set_path.title': 'Set path',
	'set_path.description':
		'Evaluates proFlow function with given arguments and sets the result to path defined by Path name field.',
	'set_literal.title': 'Set literal',
	'set_literal.description': 'Sets given JSON to variable specified in Variable name field.',
	'set_path_literal.title': 'Set path literal',
	'set_path_literal.description': 'Sets given JSON to path specified in Path name field.',

	'set_variable.function': 'Function',
	'set_variable.variable_name': 'Variable name',
	'set_variable.path_name': 'Path name',
	'set_variable.arguments': 'Arguments',

	'set_variable.invalid_variable_name': 'Variable name is invalid',
	'set_variable.enter_variable_name': 'Please enter variable name',
	'set_variable.variable_name_contains_dot': 'Variable name cannot contain dots',
	'set_variable.variable_name_contains_space': 'Variable name cannot contain spaces',
	'set_variable.invalid_argument': 'Argument is invalid',
	'set_variable.argument_contains_dot': 'Keys in variable value cannot contain dots',

	'set_variable.functions.add': 'Add numbers',
	'set_variable.functions.add.description':
		'adds provided numbers and stores the result in "Variable name"',
	'set_variable.functions.append': 'Append to array',
	'set_variable.functions.append.description':
		'appends "what" to array specified in "to" and stores the result in "Variable name"',
	'set_variable.functions.arrayLength': 'Array length',
	'set_variable.functions.arrayLength.description':
		'stores length of the specified array to "Variable name"',
	'set_variable.functions.choose': 'Choose',
	'set_variable.functions.choose.description':
		'picks the element of array or key of object specified in "expression" from array/object specified in "options" and stores it in "Variable name"',
	'set_variable.functions.concatenate': 'Concatenate',
	'set_variable.functions.concatenate.description':
		'concatenates strings and stores the result in "Variable name"',
	'set_variable.functions.divide': 'Divide',
	'set_variable.functions.divide.description':
		'divides "numerator" by "denominator" and stores the result in "Variable name"',
	'set_variable.functions.firstElement': 'First element',
	'set_variable.functions.firstElement.description':
		'picks first element of array and stores it in "Variable name"',
	'set_variable.functions.multiply': 'Multiply',
	'set_variable.functions.multiply.description':
		'multiplies provided numbers and stores the result in "Variable name"',
	'set_variable.functions.randomElement': 'Random from variable',
	'set_variable.functions.randomElement.description':
		'picks random element from "variable" array and stores it in "Variable name"',
	'set_variable.functions.randomFromRange': 'Random from range',
	'set_variable.functions.randomFromRange.description':
		'picks random element from range "from" - "to" and stores it in "Variable name"',
	'set_variable.functions.removeElement': 'Remove element',
	'set_variable.functions.removeElement.description':
		'removes element "exclude" from array "elements" and stores the new array in "Variable name"',
	'set_variable.functions.round': 'Round',
	'set_variable.functions.round.description':
		'rounds "number" to "decimals" and stores the result in "Variable name"',
	'set_variable.functions.set': 'Set value',
	'set_variable.functions.set.description':
		'value entered in value will be stored in variable specified in "Variable name"',
	'set_variable.functions.split': 'Split string to array',
	'set_variable.functions.split.description':
		'splits string stored in "value" variable using "delimiter" and stores the result in "Variable name"',
	'set_variable.functions.subString': 'Substring',
	'set_variable.functions.subString.description':
		'creates substring of "string" using "first" and "last" indexes and stores the result in "Variable name"',
	'set_variable.functions.var_or_default': 'Set value from variable or default',
	'set_variable.functions.var_or_default.description':
		'picks value of "variable" and stores it in "Variable name". "default" is used if the variable is not defined.',

	/**
	 * ALLOCATION
	 */
	'allocation.title': 'Allocation',
	'allocation.description':
		'Allocation question lets respondents assign points to presented options. Available points to assign can be limited by setting the limit value.',
	'allocation.option.default_label': 'Allocation option {index}',
	'allocation.option': 'Allocation option',
	'allocation.detail.limit.label': 'Limit settings',
	'allocation.detail.limit.enable': 'Limit available points',
	'allocation.detail.limit.force_min': 'Must allocate all points',
	'allocation.detail.limit.value.type.label': 'Limit value source',
	'allocation.detail.limit.value.type.freetext.question_changed':
		'Selected question is no longer available',
	'allocation.detail.limit.value.type.freetext.no_options':
		'There are no modules to base limit on. Add a Formatted text (integer, number, amount) before this module to enable dynamic limit',
	'allocation.detail.limit.value.type.freetext.option_label': 'Answer to {shortName}',
	'allocation.detail.limit.value.type.option.freetext': 'Text question answer',
	'allocation.detail.limit.value.type.option.value': 'Number',
	'allocation.detail.limit.value.type.option.variable': 'Variable value',
	'allocation.detail.limit.value.value.label': 'Limit value',
	'allocation.detail.limit.value.value.invalid': 'Limit value cannot be empty',
	'allocation.detail.limit.value.value.invalid_number': 'Limit value has to be bigger than 0',
	'allocation.detail.range.label': 'Range settings',
	'allocation.detail.range.max.label': 'Range maximum',
	'allocation.detail.range.min.label': 'Range minimum',
	'allocation.detail.range.min.required': 'Range minimum is required and cannot be less than 0',
	'allocation.detail.range.invalid': 'Range maximum has to be bigger than range minimum',
	'allocation.detail.max.range.limit.invalid':
		'Range maximum allows to allocate only {points} points.',
	'allocation.detail.min.range.limit.invalid':
		'Limit has to be bigger than range minimum and bigger than {points} points.',
	'allocation.detail.option.default.out.of.range': 'Default value is out of range',
	'allocation.detail.option.default.empty': 'Default value cannot be empty',
	'allocation.detail.hint.label': 'Respondent instruction settings',
	'allocation.detail.hint.unit.label': 'Respondent instruction value unit',
	'allocation.detail.hint.unit.add.space':
		'Add space between respondent instruction value and unit',
	'allocation.detail.hint.unit.position': 'Respondent instruction value unit position',
	'allocation.detail.none_of_these':
		'Selecting the None of these option will set all values entered by respondent to 0',
	'allocation.detail.instruction.settings': 'Instruction settings',
	'allocation.detail.instruction.isVisible': 'Display instruction',
	'allocation.detail.instruction.isCustom': 'Use custom instruction text',
	'allocation.detail.instruction.text': 'Custom instruction text',
	'allocation.detail.instruction.text.error': 'Instruction text cannot be empty',

	/**
	 * MAXDIFF
	 */
	'maxDiff.title': 'MaxDiff',
	'maxDiff.image.title': 'Image MaxDiff',
	'maxDiff.image.no_label': 'Image MaxDiff without labels',
	'maxDiff.description':
		'MaxDiff, or Best-Worst Scaling, is used to estimate preference/importance scores for a set of items (e.g., brands, product features, etc.).',
	'maxDiff.option.default_label': 'Item {index}',
	'maxDiff.detail.number_of_rounds': 'Number of MaxDiff rounds',
	'maxDiff.detail.number_of_items_per_round': 'Number of items per round',
	'maxDiff.detail.best_question': '"Best" question',
	'maxDiff.detail.worst_question': '"Worst" question',
	'maxDiff.chart.diff_type': 'Diff',

	'maxDiff.options.add_above': 'Add new item above',
	'maxDiff.options.add_below': 'Add new item below',
	'maxDiff.detail.empty_options': 'Item label cannot be empty.',
	'maxDiff.detail.non_unique_simple_names': 'Simplename has to be unique for each item',
	'maxDiff.detail.non_unique_options': 'Label has to be unique for each item',
	'maxDiff.detail.too_few_options':
		'Number of items per round cannot be higher than number of options',
	'maxDiff.detail.too_few_items_per_round': 'Number of items per round cannot be lower than 2',
	'maxDiff.detail.blur': 'Blur history between rounds',

	/**
	 * RANKING
	 */
	'ranking.title': 'Ranking',
	'ranking.description':
		'Ranking question lets respondent order presented options from most to least important.',
	'ranking.option': 'Ranking option',
	'ranking.option.default_label': 'Ranking option {index}',
	'ranking.require_unique_values': 'Require unique values',
	'ranking.subtype.input_type': 'Input method',
	'ranking.subtype.drag_and_drop': 'Ordering options by drag and drop',
	'ranking.subtype.select': "Selecting options' rank from dropdown menu",

	/**
	 * CHOICE / ALLOCATION / RANKING options filter
	 */
	filter_options_by: 'Filter options by',
	'options.filter.invalid': 'Filter cannot contain spaces',

	/**
	 * END_STUDY
	 */
	'endstudy.add_module.complete': 'Complete',
	'endstudy.add_module.terminate': 'Terminate',
	'endstudy.add_module.overquota': 'Overquota',
	'endstudy.add_module.quality_terminate': 'Quality terminate',
	'endstudy.invalidUrl': 'Please set a valid URL as Redirect link or disable redirect override',
	'endstudy.redirect_button_label.required': 'Redirect button label is required',
	'endstudy.redirect_button_label.too_long': 'Redirect button label is too long',
	'endstudy.redirect_url': 'Redirect URL',
	'endstudy.redirect_button.enable': 'Enable redirect button',
	'endstudy.redirect.override': 'Override panel redirect',
	'endstudy.redirect_button.label': 'Redirect button label',
	'endstudy.type_url': 'Type your URL here',
	'endstudy.go_to_website': 'Go to website',
	'endstudy.quality_terminate': 'End as quality terminate',
	'endstudy.quality_terminate.shortName': 'Quality terminate: {shortName}',
	'endstudy.quality_terminate.description':
		'End study for current respondent considering the solution "quality terminated" -  it won\'t be included in statistics.',
	'endstudy.terminated': 'End as terminate',
	'endstudy.terminated.shortName': 'Terminate: {shortName}',
	'endstudy.terminated.description':
		'End study for current respondent considering the solution "terminated" -  it won\u0027t be included in statistics.',
	'endstudy.overquota': 'End as overquota',
	'endstudy.overquota.shortName': 'Overquota: {shortName}',
	'endstudy.overquota.description':
		'End study for current respondent tagging the respondent as "overquota" to enable panel providers to take appropriate measures.',
	'endstudy.complete': 'End as complete',
	'endstudy.complete.shortName': 'Complete: {shortName}',
	'endstudy.complete.description':
		'End study for current respondent considering the solution "complete" - it will be included in statistics.',
	'endstudy.title': 'Endpoint',

	/**
	 * LIST modue
	 */
	'list.add_module.section.title': 'Lists',
	'list.module.title': 'List',
	'list.module.description':
		'Store your data in a list and use it to dynamically create matrix questions or loops in your study.',
	'list.add_module.title': 'List',
	'list.detail.identifier': 'Select item identifier',
	'list.detail.identifier.disabled':
		'Identifier was used to generate flow items and cannot be changed',
	'list.detail.identifier.change.info':
		'Changing item identifier changes all related Matrix questions',
	'list.detail.option_label_identifier': 'Select option label column',
	'list.detail.option_label_identifier.disabled':
		'Option label column was used to generate options in questions and cannot be changed',
	'list.detail.option_label_identifier.change.info':
		'Changing option label column changes labels of all options in related questions',
	'list.detail.option_label_identifier.missing': 'Please select an option label identifier column',
	'list.detail.upload.no_file': 'No file uploaded',
	'list.detail.upload': 'Upload',
	'list.detail.replace_file': 'Replace',
	'list.module_content.fallback': 'Please select an item identifier to display list items',
	'list.detail.empty_error': 'List is empty. Please upload a file or remove the module',
	'list.detail.input_type.file': 'Upload file',
	'list.detail.input_type.list': 'Use existing list',
	'list.detail.input_type.no_type_error': 'Select an input type to continue',
	'list.detail.input_type.cannot_change': 'Delete the module and add it again to change input type',
	'list.item': 'List item',
	'list.live_upload.identifier.missing': 'Original identifier column is missing in the new list',
	'list.live_upload.identifier.not_unique':
		'Original identifier column is not unique in the new list',
	'list.live_upload.missing_columns': 'Some of the original columns are missing in the new list',
	'list.live_upload.missing_items': 'Some of the original items are missing in the new list',
	'list.detail.manage_items': 'Manage items',
	'list.detail.manage_items.always_carry_forward': 'Always carry forward',
	'list.detail.manage_items.add_column': 'Add column',
	'list.detail.manage_items.column_name': 'Column name',
	'list.detail.manage_items.column_name_empty': 'Column name cannot be empty',
	'list.detail.manage_items.column_name_exists': 'Column with name "{columnName}" already exists',
	'list.detail.manage_items.values_empty': 'Values cannot be empty',

	/**
	 * LIST FILTER
	 */
	'list_filter.detail.input': 'Select list',
	'list_filter.detail.empty_name': 'Please select an existing list to continue',
	'list_filter.detail.list_does_not_exist': 'Selected list no longer exists',
	'list_filter.detail.add_filter_rule': 'Add filter condition',
	'list_filter.detail.add.community': 'Use data from the community panel',
	'list_filter.detail.add.matrix': 'Use answers to matrix question',
	'list_filter.detail.add.question': 'Use answers to choice question',
	'list_filter.detail.add.allocation_question': 'Use answers to allocation question',
	'list_filter.detail.add.ranking_question': 'Use answers to ranking question',
	'list_filter.detail.add.item_attribute': 'Use item attributes',
	'list_filter.detail.rule.community': 'Condition using data from the community panel',
	'list_filter.detail.rule.community.list_item_param': 'List column',
	'list_filter.detail.rule.community.respondent_information': 'Respondent information',
	'list_filter.detail.rule.community.missing_module': 'Selected community column no longer exists',
	'list_filter.detail.rule.community.missing_list_key': 'Selected list column no longer exists',
	'list_filter.detail.rule.community.select_module': 'Please select community column',
	'list_filter.detail.rule.community.select_list_key': 'Please select list column',
	'list_filter.detail.rule.matrix': 'Condition using answers from the matrix question',
	'list_filter.detail.rule.matrix.question': 'Matrix question',
	'list_filter.detail.rules.empty':
		'No filter conditions are active. All items will be included. To narrow down your list, add filter conditions.',
	'list_filter.detail.rules.matrix.select_matrix': 'Select a matrix question',
	'list_filter.detail.rules.matrix.input_list_mismatch':
		'Input list of selected Matrix question has to match selected input list',
	'list_filter.detail.rule.question': 'Condition using answer to question',
	'list_filter.detail.rule.question.question': 'Question',
	'list_filter.detail.rule.question.missing_question': 'Selected question no longer exists',
	'list_filter.detail.rule.question.select_question': 'Please select question',
	'list_filter.detail.rule.question.options_mismatch':
		'Following choice options do not match items in selected list: {mismatchedOptions}',
	'list_filter.detail.rule.item_attribute': 'Condition using item attribute',
	'list_filter.detail.rule.item_attribute.list_item_param': 'List column',
	'list_filter.detail.rule.item_attribute.list_item_param.empty': 'Please select list column',
	'list_filter.detail.rule.item_attribute.function.empty': 'Please select comparison rule',
	'list_filter.detail.rule.item_attribute.value': 'Value',
	'list_filter.detail.rule.item_attribute.value.nan_error': 'Value has to be a number',
	'list_filter.detail.rule.item_attribute.column_not_found':
		'Selected column no longer exists in list',
	'list_filter.detail.rule.allocation.title': 'Condition using answer to allocation question',
	'list_filter.detail.rule.ranking.title': 'Condition using answer to ranking question',
	'list_filter.detail.rule.allocation.max': 'Max',
	'list_filter.detail.rule.allocation.min': 'Min',
	'list_filter.detail.rule.allocation.value_empty': 'Value cannot be empty',
	'list_filter.detail.rule.include_no_data.question.true': 'Ignore if respondent skipped question',
	'list_filter.detail.rule.include_no_data.question.false': 'And respondent answered the question',
	'list_filter.detail.rule.include_no_data.community.true':
		'Ignore if respondent does not have data',
	'list_filter.detail.rule.include_no_data.community.false': 'And respondent has data',

	/**
	 * HEATMAP
	 */
	'heatmap.title': 'Heatmap',
	'heatmap.description':
		'Heatmap question lets respondents highlight one or more points in provided image',
	'heatmap.detail.min_answers': 'Min answers',
	'heatmap.detail.max_answers': 'Max answers',
	'heatmap.detail.min_answers.error': 'Min answers cannot be less than 1',
	'heatmap.detail.max_answers.error': 'Max answers cannot be less than min answers',
	'heatmap.detail.image.upload': 'Upload image',
	'heatmap.detail.image.change': 'Change image',
	'heatmap.detail.image.alt': 'Heatmap image',
	'heatmap.detail.image.upload.error': 'Upload an image to continue',

	/**
	 * routes/_study/StudyDesign/Detail/FlowDetail/_details/EndStudy/_components/PanelRedirects/PanelRedirects.js
	 */
	no_redirect: 'no redirect',
	panel_provider_redirect: 'Panel provider redirect',
	panel_redirects: 'Panel redirects',

	/**
	 * respondents
	 */
	respondents: 'Respondents',
	'respondents.requested': 'Requested',
	'respondents.completed': 'Completed',
	'respondents.dropped': 'Dropped',

	/**
	 * segments
	 */
	all_completes: 'All completes',
	all_completes_description: 'All respondents are included in the All completes segment.',
	segment_count: '{count, plural,=0 {segments} one {segment} other {segments}}',
	confirm_removing: 'Confirm Removing',
	segment_detail_remove_segment_button: 'Remove segment',
	segment_bar_chart_title_calculating: 'Calculating',
	segment_bar_chart_title_invalid: 'Invalid',
	segment_no_modules: 'There are no modules to base segments on yet',
	segment_detail_segment_description: 'Create conditions that qualify respondents for this segment',
	segment_detail_segment_name: 'Segment name',
	segment_respondent_included:
		'Respondents are included in {label} segment if they pass following {count, plural, one {condition} other {conditions}}:',
	segment_oeq_evaluation_description:
		'evaluation position on {answerCount, plural, one{answer} other{answers}} {answers} is {position}.',

	/**
	 * src/helpers/reportBuilder/amcharts/createSeriesBound.js
	 */
	upper_bound: 'upper bound',
	lower_bound: 'lower bound',

	/**
	 * routes/_admin/User/CreateUser/CreateUser.js
	 */
	'create_user.new': 'Create new user',
	'create_user.test_new': 'Create new test user',
	'create_user.password_match': 'Password and password confirmation have to match',
	'create_user.password_confirm': 'Password confirmation',
	'create_user.test_email_has_start': 'Email has to start with cypress- or with gqltesting',
	'create_user.test_email_has_end': 'Email has to end with @groupsolver.com',
	'create_user.email_taken': 'Email is already taken',

	/**
	 * routes/_admin/User/AssignAccount/AssignAccess.js
	 */
	assign: 'Assign',

	/**
	 * routes/_admin/Study/StudyDetail/_components/ActionLog/ActionLog.js
	 */
	'action_log.header': 'Study action log',
	'action_log.table.date': 'Date',
	'action_log.table.action': 'Action',
	'action_log.table.result': 'Result',
	'action_log.table.id_user': 'ID User',
	'action_log.table.id_account': 'ID Account',

	/**
	 * routes/_admin/Study/StudyDetail/_components/Recalculate/Recalculate.js
	 */
	'admin_recalculate.trigger_recalculation': 'Trigger recalculation',
	'admin_recalculate.recalculate': 'Recalculate',
	'admin_recalculate.recalc_started': 'Recalculation queued',

	/**
	 * routes/_study/StudyDesign/_store/studyDesign.js
	 */
	segments_added:
		'New {count, plural, one {segment has} other {segments have}} been added to your segment list.',

	/**
	 * routes/Inbox/Inbox.js
	 */
	'inbox.notifications_unread': 'Unread',
	'inbox.notifications_mark_read': 'Mark all as read',
	'inbox.notifications_mark_as_read': 'Mark as read',
	'inbox.notifications_past': 'Past notifications',
	'inbox.notifications_new': 'You have no new notifications.',
	'inbox.notifications_settings': 'Notification settings',
	'inbox.all_caught_up': 'All caught up',
	'inbox.no_notifications_yet': 'No notifications yet',
	'inbox.no_notifications':
		'Your notifications will show up here. In the meantime, you can check the notification settings.',
	'inbox.notification.message.study_state': 'study has been {studyState} by {user}',
	'inbox.notification.message.collection': 'study is {percentage}% complete',
	'inbox.notification.message.study_delete_indication':
		'study has been marked for deletion by {user}',
	'inbox.notification.message.study_delete_reminder':
		'study will be deleted in {days} {days, plural,=0 {days} one {day} other {days}}',
	'inbox.notification.message.study_auto_close_reminder':
		'study will be moved into {nextState} state in {days} {days, plural,=0 {days} one {day} other {days}}',
	'inbox.notification.message.account_prepaid_respondents_reached':
		'account reached the limit of {count} {count, plural, =0 {respondents} one {respondent} other {respondents}}',
	'inbox.notification.message.account_trial_expires_in_5_days': 'trial will expire in 5 days.',
	'inbox.notification.message.account_trial_expires_in_1_day': 'trial will expire tomorrow.',
	'inbox.notification.message.account_trial_expired': 'trial has expired.',
	'inbox.notification.message.account_will_be_locked_in_5_days':
		'account will be locked in 5 days.',
	'inbox.notification.message.account_will_be_locked_in_1_day': 'account will be locked tomorrow.',
	'inbox.notification.message.account_was_locked_and_will_be_deleted':
		'account is locked and will be deleted in 30 days.',
	'inbox.notification.message.account_will_be_deleted_in_5_days':
		'account will be deleted in 5 days.',
	'inbox.notification.message.account_will_be_deleted_in_1_day':
		'account will be deleted tomorrow.',
	'inbox.notification.message.account_was_deleted': 'account has been deleted.',
	'inbox.notification.label.account_no_longer_exists': 'Account no longer exists',
	'inbox.notification.message.account_subscription_expires_in_5_days':
		'subscription will expire in 5 days.',
	'inbox.notification.message.account_subscription_expires_in_1_day':
		'subscription will expire tomorrow.',
	'inbox.notification.message.account_subscription_expired': 'subscription has expired today.',
	'inbox.notification.message.account_subscription_will_be_deleted_in_30_days':
		'will be deleted in 30 days.',
	'inbox.notification.message.account_subscription_will_be_deleted_in_5_days':
		'will be deleted in 5 days.',
	'inbox.notification.message.account_subscription_will_be_deleted_in_1_day':
		'will be deleted tomorrow.',
	'inbox.notification.message.account_subscription_was_deleted': 'has been deleted.',
	'inbox.notification.message.subscription_will_be_renewed_in_1_day':
		'subscription will renew tomorrow.',
	'inbox.notification.message.subscription_renewed':
		'subscription has been renewed and is valid until {date}.',
	'inbox.notification.account_not_accessible': 'account is no longer accessible.',

	//autorenewal & billing cycle notifications
	'inbox.notification.message.subscription_auto_renewal_enabled':
		'Auto-renewal for the {accountName} account was enabled.',
	'inbox.notification.message.subscription_auto_renewal_canceled':
		'Auto-renewal for the {accountName} account was canceled.',
	'inbox.notification.billing_cycle_changed': 'Billing cycle for your plan was changed to {cycle}.',

	//payments notifications
	'inbox.notification.message.subscription_payment_successful':
		'Thank you. Your purchase is successfully completed.',
	'inbox.notification.message.payment_unsuccessful':
		'Payment unsuccessful from your credit card ending in {cardLast4}.',

	//user role notifications
	'inbox.notification.admin.user_role_changed':
		"{initiator} changed {affectedUser}'s user role to {newRole}.",
	'inbox.notification.affected_user.user_role_changed':
		'Your role in {accountName} account was changed from {previousRole} to {newRole}.',
	'inbox.notification.initiator.user_role_changed':
		"You changed {affectedUser}'s user role to {newRole}.",
	'inbox.notification.admin.user_role_removed':
		'{initiator} removed {affectedUser} from {accountName} account.',
	'inbox.notification.affected_user.user_role_removed':
		'You were removed from the {accountName} account.',
	'inbox.notification.initiator.user_left_account': 'You left {accountName} account.',
	'inbox.notification.admin.user_left_account': '{initiator} left {accountName} account.',
	'inbox.notification.initiator.user_role_removed':
		'You removed {affectedUser} from {accountName} account.',

	//card info notifications
	'inbox.notification.message.card_changed': '{companyName} account card was changed.',
	'inbox.notification.message.card_added': 'New card was added to {companyName} account.',
	'inbox.notification.message.card_removed': '{companyName} account card was removed.',
	'inbox.notification.message.billing_information_updated':
		'Billing information for {companyName} account was updated.',

	//invitations
	'inbox.invites.headline': 'Your pending invites',
	'inbox.invites.created': 'invited {whom} to {accountName}.',
	'inbox.invites.accept': 'Accept',
	'inbox.invites.reject': 'Reject',
	'inbox.invites.accepted': 'accepted the invite to {accountName}.',
	'inbox.invites.rejected': 'rejected the invite to {accountName}.',
	'inbox.invites.canceled': 'canceled {whom} invite to {accountName}.',
	'inbox.invites.expired': 'invite to {accountName} expired.',
	'inbox.invites.invite-expired-overlay.header': 'Invite expired',
	'inbox.invites.invite-expired-overlay.message':
		'Your invite to {accountName} expired. Contact the account admin to request a new invite.',
	'inbox.invites.invite-rejected.message':
		'You have rejected your invite to the {accountName} account.',

	/**
	 * Account limits
	 */
	nesting_limit_reached: 'Maximum nesting level reached',
	account_limits_flow:
		`You are allowed to ask for only {count} {count, plural,
			one {
				{ type, select, dataset {Answer} oeq {AI Open-End™} quota {Quota} module {Module} other {} }
			}
			other {
				{ type, select, dataset {Answers} oeq {AI Open-Ends™} quota {Quotas} module {Modules} other {} }
			}
		}	in one study.` + partials.bubble_contact_us,
	account_limits_segments:
		'You are allowed to build only {count, plural,one {{count} segment} other {{count} segments}} in one study.' +
		partials.bubble_contact_us,
	account_limits_respondents:
		'Please contact us if you need more than {count} respondents for this study.',
	account_limits_slides:
		'You are allowed to create only {count, plural,one {{count} slide} other {{count} slides}} in one report.' +
		partials.bubble_contact_us,
	account_limits_launched_studies:
		'Your current subscription allows you to have only {count, plural,one {{count} launched study.} other {{count} launched studies.} } If you need to extend this limit, please contact us.',

	account_will_expire_warning:
		'Your account will expire in {days, plural, one {{days} day} other {{days} days}}!',
	account_will_be_deleted_warning: 'Your account has expired and will be deleted on {date}.',
	trial_has_expired_warning:
		'Your free trial has expired. You can collect and access your data until {date}',
	previews_limit_reached: 'You have reached the previews limit in this study.',
	library_errors_in_flow: 'Testing with bots is not enabled in templates with invalid flow.',
	launched_studies_limit_reached: 'Launched studies limit reached',

	'account_limits.reached.datasets': "You've reached the limit of {count} questions per study.",
	'account_limits.reached.oeq':
		"You've reached the limit of {count} AI Open-End™ questions per study.",
	'account_limits.reached.quotas': "You've reached the limit of {count} quotas per study.",
	'account_limits.contact_us_higher_plan': 'Contact us for a higher plan',

	'account.free_trial.expired.contact_us.title':
		'Want more deep insights? Purchase a plan to unlock your account!',
	'account.free_trial.expired.contact_us.message':
		'Your current trial account is locked and will be deleted on {date} unless renewed by one of our plans. Fill out the form below to chat about options.',

	subscription: 'Subscription',
	'account_expired.tooltip_action.launch_study': 'launch a study',
	'account_expired.tooltip_action.create_study': 'create new studies',
	'account_expired.tooltip_base_message':
		"You can't {action} since your {accountType} has expired.",
	'account_expired.tooltip_suffix.free_trial': 'Upgrade your subscription or contact us.',
	'account_expired.tooltip_suffix.full_service': 'Contact us to renew your subscription.',
	'account_expired.tooltip_suffix.subscriptions': 'Upgrade your subscription.',

	/**
	 * Account Management
	 */
	account_studies: 'Account studies',
	account_users: 'Account users',
	account_manage_account: 'Manage account',
	account_manage_users: 'Manage users',
	account_manage_studies: 'Manage studies',
	account_list: 'List of accounts',
	account_assigned_accounts: 'Assigned accounts',
	account_assign_more_accounts: 'Assign more accounts',
	account_name: 'Account name',
	company_name: 'Company name',
	expiration_date: 'Expiration date',
	account_id: 'Account ID',
	license_limits: 'License limits',
	license_limit_updated: 'License limits updated',
	stripe_customer: 'Stripe customer',
	user_limit_reached: 'User limit reached',

	/**
	 * User Management
	 */
	user_accounts: 'User accounts',
	user_edit: 'Edit user',
	user_assigned_users: 'Assigned users',
	user_assign_more_users: 'Assign more users',

	/**
	 * Study Management
	 */
	study_label: 'Study label',
	study_id: 'Study ID',
	study_assigned_studies: 'Assigned studies',
	study_limits: 'Study limits',
	study_limits_updated: 'Study limits updated',

	/**
	 * routes/_home/Home
	 */
	'home.subtitle.get_started':
		'Get started by creating your first study or head to our knowledge base to learn more about GroupSolver.',
	'home.subtitle.welcome_back': 'Welcome back to GroupSolver, find the most recent updates below.',

	'home.sidebar.get_started': 'Get started',
	'home.sidebar.what_makes_groupsolver_unique': 'What makes GroupSolver unique?',
	'home.sidebar.how_to_design_a_survey': 'How to design a survey',
	'home.sidebar.review_and_analyze_survey_data': 'Review and analyze survey data',

	'home.sidebar.have_a_question': 'Have a question?',
	'home.sidebar.help_and_getting_started': 'Help & getting started',
	'home.sidebar.reach_out_to_us': 'Reach out to us',

	'blog.latest_from_the_blog': 'Latest from the blog',
	'blog.read_story': 'Read story',
	'blog.read_more_on_our_blog': 'Read more on our blog',

	'home.content.title.favorites': 'Favorites',
	'home.content.title.recent_updates': 'Recent updates',
	'home.content.title.demo_studies': 'Demo studies',
	'home.content.title.purchase_plan': 'Purchase a plan to unlock your account.',
	'home.content.subtitle.trail_account_locked':
		'Your current trial account is locked and will be deleted on {date} unless renewed by one of our plans. Contact us to chat about options.',
	'home.content.subtitle.demo_studies':
		"We've prepared a few sample studies for you to look around and learn more.",

	'list_section.last_updated': 'Updated {date} at {time}',

	/**
	 * src/routes/_home/Home/_components/ListBlock/ListBlock.js
	 */
	'listblock.tooltip': "You can't access this {sectionType} because your {licenseType} is expired.",

	/**
	 * src/routes/Dashboard/DashboardComponent.js
	 */
	'study_list.sort_options.name': 'Sort by name',
	'study_list.sort_options.create_date': 'Sort by create date',
	'study_list.sort_options.data_update_date': 'Sort by last data update',
	'study_list.sort_options.study_update_date': 'Sort by last study update',
	'study_list.filter.all': 'All studies',
	'study_list.filter.draft': 'Drafts ({count})',
	'study_list.filter.launched': 'Active studies ({count})',
	'study_list.filter.paused': 'Paused studies ({count})',
	'study_list.filter.solved': 'Solved studies ({count})',
	'study_list.filter.archived': 'Archived studies ({count})',
	'study_list.filter.error': 'Errors ({count})',
	'study_list.filter.post_analytics': 'Post-analytics studies ({count})',

	/**
	 * routes/Dashboard/_components/FilterBar/_components/CreateStudyMenu/CreateStudyMenu.js
	 */
	'templates.choose_from_template': 'Choose from templates',

	/**
	 * Help section
	 */
	'study_list.help.create_a_study.title': 'Create a Study',
	'study_list.help.create_a_study.description':
		'Learn how to navigate GroupSolver and build a successful study',
	'study_list.help.launch_study.title': 'Launch Study and Track Collection',
	'study_list.help.launch_study.description':
		'Launch your study and track respondent collection in real-time',
	'study_list.help.get_help.title': 'Get Help',
	'study_list.help.get_help.description':
		'Refer to our Knowledge Base for guidance on GroupSolver capabilities',

	'study_list.help.title': 'How it works',
	'study_list.help.description':
		'We are here to help you answer your business questions. Visit our knowledge base or contact us to get help.',

	/**
	 * src/routes/Dashboard/components/CreateStudyComponent/CreateStudyComponent.js
	 */
	'create_study.header': 'Create your first GroupSolver study',
	'create_study.select_template': 'or select  from templates:',
	'enable-post-analytics': 'Enable post-analytics in this study',

	/**
	 * routes/Dashboard/_components/FilterBar/FilterBar.js
	 */
	'study_list.filter_studies': 'Filter studies',
	/**
	 * components/_scaffolding/PresentationBuilder/SelectStudies
	 */
	'study_list.search_studies': 'Search studies',

	/**
	 * routes/Dashboard/_components/StudiesLimitReachedMessage/StudiesLimitReachedMessage.js
	 */
	launched_studies_info:
		'You have reached the limit of your subscription. Please contact us to get an upgrade and launch more studies.',

	/**
	 * components/_scaffolding/StudyListItemDetail.js
	 */
	study_copy_in_progress: 'Copying in progress',
	'study_detail_label.mongo_export': 'Mongo export',
	'study_detail_label.copy_study': 'Copy study',
	'study_detail_label.change_study_state': 'Change study state',
	'study_detail_label.change_custom_translations': 'Change custom translation',
	'study_detail_label.change_max_bots': 'Change max bots',
	'study_detail_label.update_respondent_sources': 'Update respondent sources',
	'study_detail_label.move_study_to_account': 'Move study to different account',

	/**
	 * routes/_study/CreateStudy/_components/SelectTemplate/SelectTemplate.js
	 */
	select_study_template: 'Select study template',
	tile_scratch_description:
		'Start from the ground up and build your own study that helps you answer your specific business question. Explore without fear – we have your back. Just reach out to our team if you get stuck!',
	tile_estimated_loi: 'LOI: {minutes} {minutes, plural, =0 {minutes} one {minute} other {minutes}}',
	tile_number_of_questions:
		'{count} {count, plural, =0 {questions} one {question} other {questions}}',
	tile_post_analytics_description:
		'Impactful decision-making with Post Analytics - upload your data for AI-driven insights. Gain a deeper understanding with our user-friendly visualizations and analytic tools.',

	/**
	 * routes/_study/Panels/Panels.js
	 */
	study_statistics: 'Study statistics',
	panel_create: 'Create panel',
	'panel_default.add': 'Add Self Distribution panel',
	panel_default: 'Self Distribution',
	panel_groupsolver: 'GroupSolver panel', // <-- product translation, do not delete
	panel_community: 'Community panel', // <--  do not delete
	'panel_community.add': 'Add Community panel',
	'panel_groupsolver.add': 'Add GroupSolver panel',
	'panel_groupsolver.add.card_needed': 'Please add a payment method to create GroupSolver panel',
	panel_update: 'Update panel',
	panel_new: 'Panel {index}',
	gs_panel: 'GroupSolver Panel',
	recruit_respondents_title: 'Recruit study respondents',
	request_custom_panel: 'request a custom panel',
	recruit_respondents_message:
		'Let us do the heavy lifting for you. With {panel} you can easily choose your preferred demographics and get quality respondents to your survey in just a few clicks.{br}{br}If you need to target a different audience, {requestPanel}.',
	self_distribution_completes: 'Self Distribution completes',
	respondents_limit_tooltip:
		'You can recruit up to {count} {count, plural,=0 {respondents} one {respondent} other {respondents}} for all your free studies. If you need more, purchase GroupSolver Panels, or contact us.',
	recruit_respondents_counter: '{current} / {prepaid}',
	'panels.disabled': 'Disabled panels',
	'panels.disabled_message':
		'When you are reaching out to a research community, all other panels are disabled. Community panels are disabled when you remove community file. Note that disabled panels must be removed before study launch.',

	/**
	 * routes/_study/Panels/_components/Panel/Panel.js
	 */
	panel_email_test: 'Send test email',
	'panel_email_test.draft_tooltip': 'Add questions to study interview to send preview email',
	panel_email_send_invitations_100: 'Send up to 100 email invitations',
	panel_email_send_invitations_all: 'Send all email invitations',
	panel_settings: 'Panel settings',
	panel_edit: 'Edit panel',
	panel_remove: 'Remove panel',
	panel_stop_collecting_data: 'Stop collecting data',
	// eslint-disable-next-line no-template-curly-in-string
	'panel.payment.verify': 'Verify the payment of ${price} to start collecting data.',
	'panel.payment.error.default':
		"There's been an issue with your payment. Try again to start collecting responses.",
	'panel.payment.error.add_card':
		"There's been an issue with your payment. Add card and try again to start collecting responses.",
	'panel.stop_data_collection_message':
		'Once stopped, you cannot resume collecting data with the panel. Respondents that are currently answering the survey will be able to finish it.',
	'panel.stop_data_collection_badge':
		'Stopped at {count} {count, plural,=0 {completes} one {complete} other {completes}}',
	'panel.limit_warning':
		"Oops! You've reached the {count} self-distribution {count, plural,=0 {completes} one {complete} other {completes}} limit for your free trial.",
	'panel.community.missing_email_sender_settings':
		'E-mail sender not configured. Please contact us.',
	'panel.community.edit_email_template': 'Edit invitation email',
	'panel.community.filter': 'Filter respondents',
	'panel.community.no_conditions': 'No conditions yet.',
	'panel.community.send_invitations_success': 'Invitations sent succesfuly.',
	'panel.community.send_invitations_error': 'Invitations failed to send! Please try again.',

	/**
	 * routes/_study/Panels/_components/PanelForm/PanelForm.js
	 */
	'panel_form.match_params': 'Match parameters settings',
	'panel_form.match_params.tooltip':
		'Add match parameters to restrict which respondents will be counted as a part of the panel.\nOnly respondents with matching url parameters (part of login url after ?) will be matched to this panel.\nLeave match parameters empty to match anyone with or without url parameters.',
	'panel_form.match_params.add': 'Add match parameter',
	'panel_form.match_parameter_key': 'Key',
	'panel_form.match_parameter_key.tooltip': 'Name of the parameter (e.g. rid, password)',
	'panel_form.match_parameter_value': 'Value',
	'panel_form.match_parameter_value.tooltip':
		'Leave empty to match any non-empty value, add a number or string to match an exact value or add a regular expression',
	'panel_form.login_options': 'Login options',
	'panel_form.login_show': 'Show login page',
	'panel_form.login_anonymous': 'Allow anonymous login',
	'panel_form.login_email': 'Allow email login',
	'panel_form.redirects_enable': 'Enable forced redirects',
	'panel_form.redirects_complete': 'Redirect on complete',
	'panel_form.redirects_terminate': 'Redirect on termination',
	'panel_form.redirects_quality_terminate': 'Redirect on quality termination',
	'panel_form.redirects_overquota': 'Redirect on overquota',
	'panel_form.groupsolver.survey_quota_id': 'Survey quota ID',
	'panel_form.multiple_match_always':
		'Study already contains panel that accepts respondents without match parameters',
	'panel_form.has_unique_match_params': 'Has unique matching parameters',
	'panel_form.has_unique_match_params.enabled':
		'Respondents with unique matching parameters will be able to log in and continue in the study from different device\nRespondents without unique matching parameters will not be recognized as different respondents',
	'panel_form.has_unique_match_params.disabled':
		'Users without unique matching parameters will be logged in as separate respondents if they access the study from different device\nRespondents will not be able to continue in the study using different device',
	'panel_form.unique_match_params.tooltip':
		'Enable if you have a unique link for every respondent (e.g. link with respondent id)\nDisable if each respondent logs in with the same link (e.g. link with source=facebook_campaign)',
	'panel_form.empty_unique_match_params':
		'Anyone with the study link will be able to log in as a respondent',

	/**
	 * routes/_study/Panels/_components/Panel/_components/DeletePanelForm/DeletePanelForm.js
	 */
	panel_delete: 'Remove panel',
	'panel_delete.title': 'Remove {label} panel',
	'panel_delete.panel_name': 'Panel name',
	'panel_delete.message':
		'To remove the {label} panel, please type the name of the panel to the confirmation input',
	'panel_delete.error': 'Provided panel name does not match the name of the selected panel',

	/**
	 * routes/_study/Panels/_components/Panel/_components/PanelLabel/PanelLabel.js
	 */
	'panel_description.match_url': 'match {uniqueParameter}',
	'panel_description.match_always': 'anyone can login',
	'panel_description.stop_data_collection': 'Data collection stopped',
	'panel_description.community_match': 'invitations sent by email',

	/**
	 * routes/_study/Panels/_components/Panel/_components/StopDataCollectionForm/StopDataCollectionForm.js
	 */

	'panel_stop_data_collection.confirm': 'Stop collecting',
	'panel_stop_data_collection.message':
		'To stop collecting data with {label}, enter the panel name and click "Stop collecting".',

	/**
	 * routes/_study/Panels/_components/CreateGroupsolverPanel/CreateGroupsolverPanel.js
	 */
	'panel.groupsolver.create_and_order': 'Create and order a panel',
	'panel.groupsolver.steps.confirm.order_panel': 'Order panel',
	'panel.groupsolver.steps.name.label': 'Name of the panel',
	'panel.groupsolver.steps.price_estimate.label': 'Panel settings and price estimate',
	'panel.groupsolver.steps.confirm_order.label': 'Review your panel',
	'panel.groupsolver.successfully_created':
		'Panel was successfully created and will start collecting data after study launch.',
	'panel.groupsolver.successfully_updated':
		'Panel was successfully updated and will start collecting data after study launch.',
	'panel.groupsolver.successfully_created_launched':
		'Panel was succesfully created and will start collecting data once processed.',
	'panel.groupsolver.successfully_updated_launched':
		'Panel was succesfully updated and will start collecting data once processed.',
	'panel.groupsolver.steps.demographics.label': 'Demographics',
	'panel.payment': 'Panel payment',
	'panel.no_payment_required': 'Order panel and start collecting data',
	'panel.no_payment_required_update': 'Edit panel and continue collecting data',

	/**
	 * routes/_study/Panels/_components/CreateGroupSolverPanel/_components/PanelNameStep/PanelNameStep.js
	 */
	'panel.groupsolver.steps.name.collect_personal_data': 'We are collecting personal data',
	'panel.groupsolver.steps.name.require_payment': 'Require payment before panel launch',

	/**
	 * src/routes/_study/Panels/_components/CreateGroupSolverPanel/_components/PriceEstimateStep/PriceEstimateStep.js
	 */
	'panel.groupsolver.steps.price_estimate.get_estimate': 'Get price estimate',
	'panel.groupsolver.steps.price_estimate.continue_to_review': 'Continue to review',
	'panel.groupsolver.steps.price_estimate.completes': 'Number of completes',
	'panel.groupsolver.steps.price_estimate.lengthOfInterview': 'Length of interview',
	'panel.groupsolver.steps.price_estimate.days': 'Days',
	'panel.groupsolver.steps.price_estimate.incidenceRate': 'Incidence rate',
	'panel.groupsolver.steps.price_estimate.price_per_respondent': 'Estimated price per respondent',
	'panel.groupsolver.steps.price_estimate.total': 'Total estimated price*',
	'panel.groupsolver.steps.price_estimate.disclaimer':
		'Final panel price is subject to achieving panel parameters, including but not limited to length of interview (LOI), incidence rate (IR), and fielding time.',
	'panel.groupsolver.steps.price_estimate.second_disclaimer':
		"The estimated price will be charged on the study launch. When we collect all responses, we'll refund you a potential difference between estimated and total prices.",
	'panel.groupsolver.steps.price_estimate.not_valid':
		'We are not able to provide quote at this time. Please change panel parameters or contact us for a custom quote.',

	/**
	 * src\routes\_study\Panels\_components\CreateGroupSolverPanel\_components\Demographics\Demographics.js
	 */
	'demographics.us_census_label': 'US Census',
	'demographics.us_census_message': 'Reflect the distribution of US Census.',
	'demographics.custom_sampling_label': 'Custom sampling',
	'demographics.custom_sampling_message': 'Set your own distribution criteria.',
	'demographics.no_restrictions_label': 'No restrictions',
	'demographics.no_restrictions_message': "Don't set any distribution rules.",
	'demographics.more_completes': 'Sum of completes is more than {completes} (+{difference})',
	'demographics.less_completes': 'Sum of completes is less than {completes} (-{difference})',

	/**
	 * src\routes\_study\Panels\_components\CreateGroupSolverPanel\_components\Demographics\_components/CustomSamplingContent/CustomSamplingContent.js
	 */
	'custom.sampling.gender_male': 'Male',
	'custom.sampling.gender_female': 'Female',
	'custom.sampling.age_18_24': '18 - 24',
	'custom.sampling.age_25_44': '25 - 44',
	'custom.sampling.age_45_64': '45 - 64',
	'custom.sampling.age_65_99': '65 - 99',
	'custom.sampling.hispanic': 'Hispanic',
	'custom.sampling.not_hispanic': 'Non-Hispanic',
	'custom.sampling.ethnicity_black': 'Black or African American',
	'custom.sampling.ethnicity_white': 'White',
	'custom.sampling.ethnicity_other': 'Other',
	'custom.sampling.region_midwes': 'Midwest',
	'custom.sampling.region_northeast': 'Northeast',
	'custom.sampling.region_south': 'South',
	'custom.sampling.region_west': 'West',
	'custom.sampling.checkbox_reset_to_census': 'Reset to census',

	/**
	 * routes/_study/Panels/_components/OrderedPanel/OrderedPanel.js
	 */
	'ordered.panel.interview_length': 'LOI: {lengthOfInterview}min',
	'ordered.panel.incidence_rate': 'IR: {incidenceRate}%',

	/**
	 * GroupSolver panel distribution type
	 */
	[DISTRIBUTION_TYPE.US_CENSUS]: 'US Census',
	[DISTRIBUTION_TYPE.CUSTOM_SAMPLING]: 'Custom sampling',
	[DISTRIBUTION_TYPE.NO_RESTRICTIONS]: 'No restrictions',

	/**
	 * routes/_study/Panels/_components/Statistics/Statistics.js
	 */
	'statistics.first_complete': 'First complete',
	'statistics.last_complete': 'Last complete',
	'statistics.last_recalc': 'Last recalculation',
	'statistics.LOI.average': 'LOI - average',
	'statistics.LOI.01': 'LOI - 1st percentile',
	'statistics.LOI.05': 'LOI - 5th percentile',
	'statistics.LOI.95': 'LOI - 95th percentile',
	'statistics.LOI.99': 'LOI - 99th percentile',
	'statistics.LOI.median': 'LOI - median',
	'statistics.active_solvers': 'Active solvers',
	'statistics.incidence_rate': 'Incidence rate',
	'statistics.collection_time': 'Collection time',
	'statistics.collection_progress': 'Collection progress',

	/**
	 * routes/_study/Panels/_components/EngagementPieChart/EngagementPieChart.js
	 */
	'engagement.pie.completed': '{count, plural, =0 {completes} one {complete} other {completes}}',
	'engagement.pie.overquota': '{count, plural, =0 {overquotas} one {overquota} other {overquotas}}',
	'engagement.pie.terminated':
		'{count, plural, =0 {terminates} one {terminate} other {terminates}}',
	'engagement.pie.dropped': '{count, plural, =0 {dropoffs} one {dropoff} other {dropoffs}}',

	/**
	 * routes/_study/Panels/_components/CommunityPanelForm/CommunityPanelForm.js
	 */
	'panel.community.steps.name.label': 'Add community panel',
	'panel.community.steps.filter.label': 'Filter respondents',
	'panel.community.steps.email_settings.label': 'Email settings',
	'panel.community.filter.disclaimer':
		'Add conditions to narrow down list of respondents who are eligible to recieve email invitation.',

	/**
	 * routes/_study/Panels/_components/CommunityPanelForm/CommunityPanelForm.js
	 */
	'panel.community_filter.matching_rows':
		'Showing {numberOfShownRows} of {numberOfMatchingRows} rows matching filter',

	/**
	 * routes/_study/StudyDesign/Detail/Statistics/Statistics.js
	 */
	answer_resources: 'Answer Resources',
	engagement: 'Engagement',
	termination_statistics: 'Termination statistics',
	edit_quota: 'Edit quota',

	'statistics.quotas': 'Quotas statistics',
	'statistics.quota_name': 'Quota name',
	'statistics.no_quotas': 'The study does not have any quotas defined',
	'statistics.no_terminates': 'The study does not have any terminates yet',
	'statistics.quota_state':
		'collected {collected}{collectedUnit}\nmax {requested}{requestedUnit} completes',
	'statistics.quota_requested': `{format, select,
		long {requested}
		short {}
		other {}
	} {count}{isPercentage, select,
		true {% of}
		other {}
	} {count, plural, =0 {completes} one {complete} other {completes}}`,
	'statistics.quota_collected':
		'collected {count} {count, plural, =0 {completes} one {complete} other {completes}}',
	'statistics.quota_collected_header': 'collected',

	/**
	 * routes/_study/StudyDesign/Detail/Statistics/_components/QuotaForm/QuotaForm.js
	 */
	'statistics.quota.overlay.title': 'Edit quota: {agName}',
	'statistics.quota.updated': 'Quota successfully updated',

	/**
	 * routes/_study/StudyDesign/Detail/Statistics/_components/Engagement/Engagement.js
	 */
	'engagement.title': 'Respondent engagement',
	'engagement.last_6_hours': 'Show last 6 hours',
	'engagement.last_12_hours': 'Show last 12 hours',
	'engagement.last_24_hours': 'Show last 24 hours',
	'engagement.last_48_hours': 'Show last 48 hours',
	'engagement.last_7_days': 'Show last 7 days',
	'engagement.last_15_days': 'Show last 15 days',
	'engagement.last_30_days': 'Show last 30 days',
	'engagement.all': 'Show the whole collection',
	'engagement.total': 'Show total',

	/**
	 * routes/_study/StudyDesign/Detail/Statistics/_components/Engagement/_components
	 */
	'engagement.complete': 'Completes',
	'engagement.terminate': 'Terminates',
	'engagement.overquota': 'Overquotas',
	'engagement.dropOff': 'Drop offs',
	'engagement.engaged': 'Engaged',

	/**
	 * layouts/ReportBuilder/ReportBuilder.js
	 */
	'report_builder.your_slide_is_loading_now': 'Your slide is loading now...',
	'report_builder.no_slides_exist_yet': 'There are no slides in this report yet.',
	'report_builder.no_data_corresponding_to_current_slide_settings':
		'There are no data corresponding to current slide settings.',
	'report_builder.change_settings_or_remove':
		'You can find slide Settings or Remove button at the top of your screen.',
	'report_builder.slide_invalid': 'This slide is invalid',
	'report_builder.slide_settings_no_longer_valid': 'Slide settings are no longer valid.',
	'report_builder.confirm_removing_title': 'Confirm removing',
	'report_builder.confirm_removing_message':
		"This report and all of its settings are about to be removed. It can't be undone.",
	'report_builder.slide_settings': 'Slide settings',

	/**
	 * layouts/AuthBaseLayout/
	 */
	good_company: 'You are in a good company',
	need_help: 'Need help?',
	reach_to_us: 'Reach out to us.',

	/**
	 * routes/_scaffolding/Auth/_components/AccountInvitation
	 */
	'invitation.email_missmatch': 'Provided email does not match the invitation email',
	'invitation.header': 'Sign up to join your company on GroupSolver',
	'invitation.expired':
		'Your invite expired. Please contact your account admin to request a new invite.',
	'invitation.trial_link': 'Sign up for a free trial!',
	'invitation.trial_try': 'Do you want to give GroupSolver a try? {trial}',

	/**
	 * routes/Inbox/_components/AccessInviteOverlay/AccessInviteOverlay.js
	 */
	'access_invitation.header': "You can't access this invite.",
	'access_invitation.emails':
		'{invitedBy} invited {invitedEmail}, but you are currently signed up as {userEmail}.',
	'access_invitation.sign_out':
		'To accept your invite, sign out and click on the link in the invite to sign up with the correct email.',
	sign_out: 'Sign out',

	/**
	 * routes/_scaffolding/Login/LoginFormComponent.js
	 */
	unauthorized: 'Invalid credentials',

	/**
	 * routes/_scaffolding/NewLogin/EntryFormContainer.js
	 */
	agreement: 'I agree to the ',
	account_disabled_label: 'Your user account is disabled',
	contact_us: 'contact us',
	contact_us_capitalized: 'Contact us',
	send: 'Send',
	email_not_verified: 'Your email is not verified',
	verification_instruction:
		'Please click on the button to complete the verification process for {email}',
	send_verification_email: 'Send verification email',
	sign_in: 'Sign in',
	password_does_not_match_password_confirmation: `Password does not match password confirmation!`,
	you_must_agree_to_the_terms: `You must agree to the terms`,
	forgot_password: 'Forgot password',
	forgot_your_password: 'Forgot your password?',
	confirm_new_password: 'Confirm new password',
	new_password: 'New password',
	new_password_instruction:
		'An email with instructions to create a new password has been sent to you.',
	email_verify: 'Please check your inbox',
	email_verify_subtitle: 'To complete your registration you will need to verify your email.',
	email_verify_was_send: "We've sent you a verification email to {email}",
	verify_phone_failed_title: 'Phone verification failed',
	verify_phone_failed_subtitle:
		'We were unable to verify your phone number. Please get in touch with our support.',
	verify_phone_failed_message:
		'We created your account, but we failed to verify your phone number. You might be unable to log in.',
	verify_phone_failed_support: 'Please',
	verify_phone_failed_support_link: 'contact support',
	verify_code: 'Security verification',
	verify_code_label: 'Enter the code',
	verify_code_action: 'Verify code',
	verify_code_help: "Didn't get the code?",
	verify_code_instruction:
		"Please enter the code we've sent to your number ending with {phoneNumber}.",
	verify: 'Verify',
	something_wrong: 'Something wrong?',
	password_change_required: 'Password change required',
	password_changed: 'Password changed',
	email_verified: 'Email verified',
	email_verified_instruction: 'Yay! We verified your email.',
	email_verified_error:
		"We weren't able to verify your email. Please try again and contact us if the problem persists.",
	change_password: 'Change password',
	message_sent: 'Your message has been sent.',
	your_message: 'Your message',
	recaptcha_must_be_solved: 'Recaptcha must be solved!',
	login_go_back: 'Go back to {login}',
	sign_up: 'Sign up',
	password_change_required_message: 'Password reset required',
	'change_password.subtitle': 'Please enter a new password to recover access to your accounts.',
	'check_domain.subtitle': "Don't have an account? {contactUs}.",
	'forgot_password.button': 'Send reset email',
	'forgot_password.email_sent': "We've sent you an email with instructions to {email}.",
	'forgot_password.subtitle': "Please enter the email address you've created your account with.",
	'password_changed.title': 'You changed your password.',
	'password_changed.message':
		'Your password has been changed successfully. You can now log in using your new password.',
	'sign_up.additional_details':
		'{additionalDetails} will help us properly address you and your company in the product and improve your user experience.',
	'sign_up.additional_details.bold': 'Additional details',
	'sign_up.agreement': 'I agree to the {privacyPolicy} and {termsAndConditions}',
	'sign_up.already_have_account': 'Already have an account? {login}',
	'sign_up.display_name.placeholder': 'What name would you like to be addressed by?',
	'sign_up.extended_security':
		'{extendedSecurity} is standard at GroupSolver. As an extra layer of protection, we will send you a code when you log in.',
	'sign_up.extended_security.bold': 'Extended security',
	'sign_up.subtitle': '4 months of product and data access, no credit card required.',
	'sign_up.title': 'Sign up for a free trial today!',
	'sign_up.invitation': 'Sign up to join {company} on GroupSolver',
	'sign_up.phone_verification_issue': 'Phone verification issue',
	'log_in.invitation': 'Log in to accept your invite to join {company}',
	'log_in.invitedBy': '{name} invited you to join their GroupSolver account.',

	/**
	 * FAQs
	 */

	'faqs.answer.collection-speed':
		'For simpler studies, we can deliver insights in a quick few hours - take a look at our <a>different plans</a> options that could meet your needs. But we have you covered even for more complex urgent projects. Whether you have a deadline coming up in a few days or few weeks, we will work with you to make your project a success!',
	'faqs.answer.pricing':
		'Pricing flexibility is important to our customers, and we are happy to offer plans for those who have an ad hoc project or those looking for subscription access. We have DIY solutions as well as an internal, full-service agency to help with any aspect of the execution of a project. Check out our <a>plans page</a> for more information.',
	'faqs.answer.plans':
		'A professional plan is meant for those who are doing research on their own. A team plan is for those who are working with a couple of folks on the same study (or studies) and need to collaborate more efficiently. You can also choose between a monthly or annual plan, but remember that an annual plan can provide you savings equal to two months worth of research. Still not sure what is best for you or need something else? <a>Chat with us</a>!',
	'faqs.answer.non-profit':
		'We have a new grant initiative called <firstLink>Impact Briefs</firstLink>.It is the perfect opportunity for those in the academic and non-profit space who are conducting meaningful societal research. Additionally, we offer favorable pricing options for instructors looking for a survey platform for their class. <secondLink>Contact us</secondLink> to learn more.',
	'faqs.answer.custom-plans':
		'	We do! <a>Tell us</a> what you are looking for and we will build a plan that fits your budget and research needs.',
	'faqs.answer.collect-respondents':
		"For most of our studies, we lean on a network of global panel providers to collect the data. However, we are flexible with using our clients' preferred panel providers or customer lists.",
	'faqs.answer.help':
		"We've got your back! We have an extensive online knowledge base and “how-to” guides. And if you need to talk to a live professional who has experience with building and analyzing surveys, we will have someone ready to support you.",
	'faqs.answer.sign-up':
		'You can <firstLink>sign up</firstLink> on our website or <secondLink>contact us</secondLink> to get started.',
	'faqs.answer.services':
		'We offer a full spectrum of services ranging from a DIY option to fully managed project execution and anywhere in between. In addition to having access to next generation research technology, we offer the following services: <ul><li>Programming and testing</li> <li>Sample recruitment and fielding</li> <li>Analysis</li> <li>Report building</li> <li>Debrief call to discuss key insights</li></ul> Advanced analytics (conjoint, discrete choice, willingness to pay, segmentation, etc.)',
	'faqs.answer.list-of-respondents':
		'Our studies generate survey links you can send out to your email list. We will be happy to work with you if you need any help.',
	'faqs.answer.what-makes-groupsolver-unique':
		'We have re-imagined what an online survey ought to look like. By blending machine learning with crowd intelligence and statistics, we extract more information than traditional surveys thus making the survey experience more engaging for respondents yet more data-rich for researchers. Our AI Open-End™ questions clean, organize and quantify natural text answers in real-time. This allows us to provide you with deeper insights, faster and with more confidence. That is why many call us a “survey platform with a brain”.',
	'faqs.answer.traditional-survey':
		'Of course! GroupSolver can do anything a traditional survey platform can. Our platform is built for professionals, so it can handle anything from simple pulse-check surveys to complex conjoint studies.',
	'faqs.answer.types-of-studies':
		'From concept testing to pricing research, we can help you address many different types of research needs. Take a look at our <a>solutions page</a> for examples of the different types of studies we can do. Please let us know if you are not finding what you are looking for. We will gladly talk to you through your projects and we can determine together if GroupSolver is the right fit for you.',
	'faqs.answer.focus-group-difference':
		'The biggest differences between our platform and a focus group are the scope of the project we can execute and the speed of collecting insights. Our platform can collect responses from thousands of respondents within days, whereas focus groups generally collect data from just a few dozen people. Additionally, we quantify the open-ended data in real-time, so you get deeper insights faster.',
	'faqs.answer.first-oeq-answer':
		"The first respondent will only ideate and will not get to evaluate others' responses, because there are no responses to evaluate quite yet. However, starting with 2nd respondent, each respondent will be able to react to answers by others in addition to providing their own answer.",
	'faqs.answer.global-capabilities':
		'Yes, our customers frequently use the GroupSolver platform for global studies that require collecting data in a number of languages. We currently support 17 languages and we are planning on adding more. Let us know if you are interested in running a study in a language we have not covered yet, and we will be happy to talk with you about options.',

	'faqs.question.collection-speed':
		'I have a steep deadline, how quickly can GroupSolver deliver insights?',
	'faqs.question.pricing': 'How does GroupSolver pricing work?',
	'faqs.question.plans': "What's the difference between Professional and Team Plans?",
	'faqs.question.non-profit': 'Do you offer discounts for academia and non-profits?',
	'faqs.question.custom-plans': 'Do you offer custom plan options?',
	'faqs.question.collect-respondents': 'How do you collect respondents?',
	'faqs.question.help': 'Is there anyone who can help me after I sign up for a plan?',
	'faqs.question.sign-up': 'How do I get a GroupSolver account?',
	'faqs.question.services': 'What services do you offer?',
	'faqs.question.list-of-respondents':
		'What if I have my own list of people I want to reach out to?',
	'faqs.question.what-makes-groupsolver-unique': 'What makes GroupSolver unique?',
	'faqs.question.traditional-survey': 'Can GroupSolver do more than open-ended analysis?',
	'faqs.question.types-of-studies':
		'What are the different types of studies I can run on the platform?',
	'faqs.question.focus-group-difference':
		'How is a GroupSolver study different from a focus group?',
	'faqs.question.first-oeq-answer':
		'What happens with the first respondent who answers an AI Open-End™ question?',
	'faqs.question.global-capabilities': 'Does the platform have global capabilities?',

	/**
	 * components/_formbuilder/_base/ImageUploader/ImageUploader.js
	 */
	'imageUploader.unknown_error': 'Your image was not uploaded due to an unknown error',

	/**
	 * Account license warnings
	 */
	study: '{count, plural,=0 {studies} one {study} other {studies}}',
	license_expired: 'Your license has expired',
	license_purchase_plan: 'Renew your license to unlock this account.',
	license_account_expired: 'Your account license is expired.',
	license_contact_us: 'Please contact us to extend your subscription.',
	license_account_locked:
		'{name} account is locked as your license expired on {date}. You need to renew your plan to access your data. Contact us to chat about options.',

	/**
	 Payments.js
	 */
	payments_history: 'Your payments history',
	payments_history_subtitle: 'View all payments made on your GroupSolver account.',
	payments_no_payments_title: 'No payments yet',
	payments_no_payments_subtitle: 'Your payments will show up here.',
	payments_product_not_found: 'Product not found',

	// product translations, do not delete
	'payment_product.subscription_monthly_professional': 'Subscription Monthly Professional',
	'payment_product.subscription_monthly_team': 'Subscription Monthly Team',
	'payment_product.subscription_annually_professional': 'Subscription Annual Professional',
	'payment_product.subscription_annually_team': 'Subscription Annual Team',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/PlansAndBillingInfo.js
	 */
	plans_and_billing: 'Plans & Billing',
	'plans_and_billing.description': 'Your current plan',
	'plans_and_billing.faq_content_title': "Questions? We've got answers.",
	'plans_and_billing.subscription_details': 'Subscription details & activity',
	'plans_and_billing.subscription_details.completes': 'Completes',
	'plans_and_billing.subscription_details.research': 'Research expert services',
	'plans_and_billing.subscription_details.studies': 'Studies',
	'plans_and_billing.subscription_details.users': 'Users',
	'plans_and_billing.subscription_details.liveslides_built': 'LiveSlides Built',
	'plans_and_billing.subscription_details.up_to_completes': 'Up to {count} completes',
	'plans_and_billing.subscription_details.limit_hours':
		'{count}{count, plural,=0 {hrs} one {hr} other {hrs}} of research expert services',
	'plans_and_billing.subscription_details.limit_zero_hours': 'Get help with your research project',
	'plans_and_billing.subscription_details.limit_studies': '{count} studies',
	'plans_and_billing.subscription_details.limit_user':
		'{count} {count, plural,=0 {users} one {user} other {users}}',
	unlimited: 'Unlimited',
	'plans_and_billing.subscription_details.limit_reached':
		"You've reached one or more of your account limits included in your current billing period. Need more? Purchase GroupSolver Panel™, or contact us.",
	'plans_and_billing.upgrade_now': 'Upgrade now',
	'plans_and_billing.will_be_locked_long': 'Your account will be locked on {date}',
	'plans_and_billing.will_be_locked': 'Will be locked on {date}',
	'plans_and_billing.expires': 'Expires on {date}',
	'plans_and_billing.will_be_deleted_long': 'Your account will be deleted on {date}',
	'plans_and_billing.will_be_deleted': 'Will be deleted on {date}',
	'plans_and_billing.expired_short': 'Will be locked on {date}',
	'plans_and_billing.locked_account': 'Your account will be deleted on {date}',
	'plans_and_billing.locked_short': 'Will be deleted on {date}',
	'plans_and_billing.renews': 'Renews on {date}',
	'plans_and_billing.enterprise': 'Enterprise',
	'plans_and_billing.project': 'Project',
	'plans_and_billing.professional': 'Subscription',
	'plans_and_billing.team': 'Team',
	'plans_and_billing.change_billing_cycle': 'Change billing cycle to {cycle}',
	'plans_and_billing.change_billing_cycle.message':
		'The next recurring amount will change after you change your billing cycle.',
	'plans_and_billing.change_billing_cycle.callout':
		'You will be charged at the end of your current billing cycle.',
	'plans_and_billing.description_professional':
		'Full platform access, best for self-sufficient individuals and teams who love to uncover deep stories through data.',
	'plans_and_billing.description_project':
		'Great for one time projects at the speed of light. Extend your capabilities and utilize our team of data analysts as necessary.',
	'plans_and_billing.description_enterprise':
		'All the support and flexibility to answer the most complex questions for your business. Get our data analysts and researchers at your disposal.',

	/**
	 * src/routes/_account/Account/_components/PlanTiles/_components/Plan/Plan.js
	 */
	'plan.subscription_already_active':
		'Can not purchase a plan because you already have active subscription!',

	/**
	 * routes/_account/Account/_components/ChangePlan/ChangePlan.js
	 */
	change_plan: 'Plans',
	'change_plan.whats_included': "What's included",
	'change_plan.members':
		'{count, plural,=0 {{count} team members} one {Single team member} other {{count} team members}}',
	// eslint-disable-next-line no-template-curly-in-string
	'change_plan.price_per_month': '{price} / month',
	'change_plan.from_price': 'from {price}',
	'change_plan.respondents': '{amount} respondents',
	'change_plan.custom_respondents': 'Custom amount of respondents',
	'change_plan.expert_services': '{amount}hrs of research expert services',
	'change_plan.custom_expert_services': 'Custom research expert services',
	'change_plan.lets_talk': "Let's talk",
	'change_plan.changing_plans':
		'Changing plans will be available soon. In the meantime, <span>contact us</span> with any changes related to your plan.',
	'change_plan.subscription_management_permissions':
		'<strong>You don’t have permission to purchase a plan.</strong> Only account admins can manage subscriptions.',
	'change_plan.contact_sales': 'Contact sales',
	'change_plan.select_plan': 'Select plan',
	'change_plan.purchase_failed':
		'Subscription payment failed. Please verify the payment or try again',
	'change_plan.renewal_failed':
		'Subscription renewal failed. Please verify the payment or try again',
	'change_plan.enter_payment_and_billing_information':
		'Enter your payment and billing information before you purchase a plan.',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/BillingCycle/BillingCycle.js
	 */
	per_month: 'per month',
	per_year: 'per year',
	'billing_cycle.title': 'Billing cycle',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/BillingCycle/_components/BillingCycleInfo/BillingCycleInfo.js
	 */
	'billing_cycle_info.plan_ends_on': 'Ends on {date}',
	'billing_cycle_info.plan_starts_on': 'Starts on {date}',
	'billing_cycle_info.plan_will_be_renewed': 'Your plan will be renewed on {date}',
	'billing_cycle_info.plan_will_expire': 'Your plan will expire on {date}',
	'billing_cycle_info.title.current_cycle': 'Current billing cycle: {cycleType}',
	'billing_cycle_info.title.next_cycle': 'Next billing cycle: {cycleType}',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/BillingCycle/_components/BillingCycleInfo/_components/ChangeBillingCycle/ChangeBillingCycle.js
	 */
	'change_billing_cycle.tooltip.autorenewal_canceled':
		"You can't change the billing cycle. With Auto-renewal canceled, your plan will not be renewed for the next billing cycle.",
	'change_billing_cycle.tooltip.not_admin': 'Only admin can change / revert billing cycle.',
	'change_billing_cycle.tooltip.no_card':
		'Cannot change your billing cycle, while card information is not set.',
	'change_billing_cycle.button': 'Change to {cycle}',
	'revert_billing_cycle.button': 'Revert to {cycle}',
	'change_billing_cycle.monthly_price': '{price} / month',
	'change_billing_cycle.annual_price': '{price} / year',
	'change_billing_cycle.saved_money': 'Save {price}/yr compared to monthly',
	'change_billing_cycle.saved_money.short': '{price}/yr saved',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/BillingCycle/_components/AutoRenewal/AutoRenewal.js
	 */
	'auto_renewal.title.enabled': 'Auto-renewal is enabled',
	'auto_renewal.title.canceled': 'Auto-renewal is canceled',
	'auto_renewal.title.next_subscription':
		'Auto-renewal will be switched to the {cycle} period on {date}',
	'auto_renewal.button.cancel': 'Cancel auto-renewal',
	'auto_renewal.button.enable': 'Enable auto-renewal',
	'auto_renewal.tooltip.admins_can_disable_or_enable_renewal':
		'Only account admin can disable / enable auto-renewal.',
	'auto_renewal.tooltip.subscription_not_active': 'Your account subscription must be active!',
	'auto_renewal.tooltip.next_subscription':
		'To cancel auto-renewal, you need to revert your billing cycle changes first.',

	/**
	 * components/_scaffolding/BillingCycleOverlay/BillingCycleOverlay.js
	 */
	'cancel_autorenewal.title': 'Cancel automatic renewal?',
	'cancel_autorenewal.message':
		'Are you sure you want to cancel auto-renewal for the {account} account?',
	'cancel_autorenewal.date_message':
		'With canceled auto-renewal, we will not charge you when your next payment is due on {date}.',
	'cancel_autorenewal.success.notification': 'Auto-renewal was canceled successfully.',
	confirm_with_password: 'Confirm with your password',
	'enable_autorenewal.title': 'Would you like to enable automatic renewal?',
	'enable_autorenewal.message':
		'Auto-renewal will renew your {account} account subscription automatically without you needing to lift a finger.',
	'enable_autorenewal.date_message':
		'With auto-renewal enabled, we will charge you when your next payment is due on {date}.',
	'enable_autorenewal.success.notification': 'Auto-renewal was enabled successfully.',

	//change billing cycle
	'change_billing_cycle.title.annual': 'Change to annual billing?',
	'change_billing_cycle.title.monthly': 'Change to monthly billing?',
	'change_billing_cycle.message.monthly_to_annual':
		'This action will change the billing cycle from monthly to annual.',
	'change_billing_cycle.message.annual_to_monthly':
		'This action will change the billing cycle from annual to monthly.',
	'change_billing_cycle.date_message.monthly':
		'Your plan will renew for the next month on {date}, and we will continue to bill you monthly. You can change your billing cycle and auto-renewal settings in the Plans & Billing section of your account settings.',
	'change_billing_cycle.date_message.annual':
		'Your plan will renew for the next year on {date}, and we will continue to bill you annually. You can change your billing cycle and auto-renewal settings in the Plans & Billing section of your account settings.',
	'change_billing_cycle.success.notification': 'Billing cycle was changed successfully',

	'revert_billing_cycle.title.annual': 'Revert back to annual billing?',
	'revert_billing_cycle.title.monthly': 'Revert back to monthly billing?',
	'revert_billing_cycle.message.annual_to_monthly':
		'This action will cancel the billing cycle change from annual to monthly.',
	'revert_billing_cycle.message.monthly_to_annual':
		'This action will cancel the billing cycle change from monthly to annual.',
	'revert_billing_cycle.success.notification': 'Billing cycle was reverted successfully',

	/**
	 * components/_scaffolding/BillingCycleOverlay/_components/AfterExpiration/AfterExpiration.js
	 */
	'after_expiration.title': 'After your plan expires...',
	'after_expiration.first_message': 'You will not be able to create and launch new studies.',
	'after_expiration.second_message':
		'Your account and data will be deleted after 12 months without renewal.',

	/**
	 * routes/_account/Account/_components/GeneralInfo/GeneralInfo.js
	 */
	general_info: 'General',
	'general_info.title': `Good {greeting, select,
    morning {Morning}
    afternoon {Afternoon}
    other {Evening}
} {name}`,
	'general_info.message': 'You are logged in as {email}',
	'general_info.form_description':
		'The main point of contact for this account. This person will be always notified about payments.',
	'general_info.tooltip_message':
		'Only admin can change account details. Please, contact account admin if you need permission.',

	/**
	 * routes\_upgrade\UpgradePage\UpgradePage.js
	 */
	'upgrade.title': 'Upgrade your account',
	'upgrade.selected_plan_message':
		'Great choice! Please make sure all information is correct before completing your purchase.',
	'upgrade.not_selected_plan_title': 'Select a plan that fits you the best.',
	'upgrade.selected_plan_title': "Let's set up a {type} plan",
	'upgrade.account_expired': 'Your account is expired',
	'upgrade.invalid_plan_warning':
		'The plan you tried to purchase is invalid. Please choose from the plans below.',
	'upgrade.account_expired_message':
		"You can't create or launch any new studies using this account. If you do not renew your plan in the next 12 months, this account will be permanently deleted on {date}",

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/NewBillingCycleSummary/NewBillingCycleSummary.js
	 */
	'summary_note.title': "You're switching to {cycle} billing",
	'summary_note.message':
		"Starting {date}, we'll charge you {price} {period} on your card ending in {cardLast4}.",
	change_billing_cycle: 'Change billing cycle',
	'new_billing_cycle.subtotal': 'Subtotal',
	'new_billing_cycle.tax': 'Tax',
	'new_billing_cycle.effective_date': 'Effective date',

	/**
	 * routes/_account/Account/_components/PlansAndBillingInfo/_components/CurrentBillingCycle/CurrentBillingCycle.js
	 */
	'current_billing_cycle.title': 'Current billing cycle',
	'current_billing_cycle.billing_period': 'Billing period',
	'current_billing_cycle.charged_amount': 'Charged amount',
	'current_billing_cycle.started': 'Started',
	'current_billing_cycle.ends': 'Ends',
	'current_billing_cycle.billing_notifications': 'Billing notifications are sent to',

	/**
	 * routes\_summary\SummaryPage\_components\PlanPayment\PlanPayment.js
	 */
	// eslint-disable-next-line no-template-curly-in-string
	'plan_payment.pay': 'Complete and pay {price}',
	'plan_payment.cycle_description': 'Renews automatically, cancel anytime.',
	// eslint-disable-next-line no-template-curly-in-string
	'plan_payment.saved_money': 'Save {price}/yr',
	'plan_payment.total': 'Total',
	'plan_payment.verifying_taxId_tooltip': `You can't buy a plan while we are verifying your {country, select,
		US {Tax}
		other {VAT}
	} ID or when your {country, select,
		US {Tax}
		other {VAT}
	} ID is not valid.`,
	'plan_payment.missing_info_tooltip':
		'Please complete payment method or billing information before paying.',

	/**
	 * routes\_planPaymentStatus\PlanPaymentStatusPage\PlanPaymentStatusPage.js
	 */
	'payment_status.verify_title': 'Verify your payment.',
	'payment_status.successful_title': 'Thank you!',
	'payment_status.unsuccessful_title': 'Payment not successful',
	'payment_status.processing_title': 'Processing your payment',
	'payment_status.successful_subtitle': "You've successfully purchased a {planType} plan.",
	'payment_status.unsuccessful_subtitle': 'Transaction was declined. Your card was not charged.',
	'payment_status.processing_subtitle':
		'Please wait while we are processing your request. This may take a while.',
	'payment_status.verify_message':
		// eslint-disable-next-line no-template-curly-in-string
		'To keep things secure, your payment of ${amount} needs additional verification. Click the button below and follow the instructions.',
	'payment_status.successful_message':
		'A receipt has been sent to your billing email adress. You can check your <a>Payments history</a> anytime in the account settings.',
	'payment_status.unsuccessful_message':
		'There was a problem with processing your payment. If the problem persists, please <span>contact us.</span>',
	'payment_status.download_receipt': 'Download receipt',
	'payment_status.successful_notification': 'Your request was successful',

	/**
	 * layouts/_components/Navigation/_components/StudyStatus/_components/StudyActions/StudyActions.js
	 */
	study_status_archive_button: 'Archive',
	study_status_delete_preview_data: 'Delete preview data',
	study_status_launch_button: 'Launch study',
	study_status_pause_button: 'Pause study',
	study_status_resume_button: 'Resume collection',
	study_status_resume_button_short: 'Resume',
	study_status_solve_button: 'Solve study',
	study_status_internally_managed:
		'Study transitions are unavailable because your study contains custom services. Please contact us to make changes.',
	study_status_start_feeding_bots: 'Test with bots',
	study_state_feeding_bots: 'Testing with bots',

	/**
	 * layouts/_components/Navigation/_components/StudyStatus/_components/RespondentsCounter/RespondentsCounter.js
	 */
	study_status_reaching_preview_limit: 'Preview limit almost reached.',
	study_status_reached_preview_limit:
		'Preview limit reached. You can delete preview data if you want to test the study further.',
	study_status_previews_collected: 'previews\ncollected',
	study_status_respondents_collected: 'completes\ncollected',

	/**
	 * layouts/_components/Navigation/_components/StudyStatus/StudyStatusComponent.js
	 */
	study_status_label_title_is_library: 'Template: {libraryTitle}',

	/**
	 * components/_scaffolding/LibraryForm/LibraryForm.js
	 */
	'library_form.request_success_notification': '{templateName} template has been saved.',
	'library_form.view_template': 'View template',

	/**
	 * layouts/_components/Navigation/_components/StudyStatus/_components/StudyActions/_components/StudyStateOverlay/StudyStateOverlay.js
	 */
	'start_feeding_bots.overlay_button': 'Test with bots',
	'start_feeding_bots.overlay_title': 'Test with bots',
	'start_feeding_bots.overlay_message': 'Study flow cannot be changed during the testing session.',

	'delete_preview_data.overlay_button': 'Delete',
	'delete_preview_data.overlay_title': 'Delete preview data',
	'delete_preview_data.overlay_message':
		'Do you want to delete preview data collected in "{label}" study? This cannot be reversed.',

	'launch.overlay_description':
		"You will be charged the total estimated price. When we collect all responses, we'll refund you a potential difference between estimated and final prices.",
	'launch.overlay_address_warning':
		"You haven't provided a billing address for your account yet. You can do it later in the account settings.",
	'launch.overlay_ordered_panels':
		'Ordered GroupSolver {count, plural, =0 {panels} one {panel} other {panels}}',
	'launch.overlay_estimated_price': 'Est. price',
	'launch.overlay_button': 'Launch',
	'launch.overlay_title': 'Launch study',
	'launch.overlay_message':
		'Do you want to launch "{label}" and start collecting data? This will erase all the Preview data. This action cannot be reversed.',
	// eslint-disable-next-line no-template-curly-in-string
	'launch.overlay.launch_and_pay': 'Launch and pay ${price}',

	'pause.overlay_button': 'Pause',
	'pause.overlay_title': 'Pause study',
	'pause.overlay_message':
		'Do you want to pause "{label}"? Respondents won\'t be able to access the study while it is paused.',

	'resume_collection.overlay_button': 'Resume',
	'resume_collection.overlay_title': 'Resume collection',
	'resume_collection.overlay_message':
		'Do you want to resume collection in "{label}" study? The study will be opened for respondents again.',

	'solve.overlay_button': 'Finish',
	'solve.overlay_title': 'Finish collection',
	'solve.overlay_message':
		'Do you want to finish collection in "{label}" study? This cannot be reversed.',

	'archive.overlay_button': 'Archive',
	'archive.overlay_title': 'Archive study',
	'archive.overlay_message': 'Do you want to archive "{label}"? This cannot be reversed.',

	/**
	 * layouts/_components/Navigation.js
	 */
	'navigation.search_for_account': 'Search for account',
	'navigation.unsaved_changes': 'Please save or discard your changes before leaving.',
	'navigation.pending-invites': '{count} pending {count, plural,one {invite} other {invites}}',

	/**
	 * layouts/_components/Navigation/_components/NavigationAccount/NavigationAccount.js
	 */
	'navigation.account_license_expired': 'Your license has expired.',
	'navigation.account_locked': 'Your account is locked.',

	/**
	 * routes/_study/StudyDesign/Flow/Flow.js
	 */
	'flow.show_module_ids': 'Show module ids',
	'flow.hide_module_ids': 'Hide module ids',
	'flow.study_design': 'Study design',
	'flow.module_no_longer_exists': 'Module no longer exists',

	/**
	 * routes/_study/StudyDesign/MediaManager/MediaManager.js
	 */
	'media_manager.title': 'Media manager',
	'media_manager.upload_completed': 'upload completed',
	'media_manager.no_files': 'No media files',
	'media_manager.failed_to_load_media': 'Failed to load media',
	'media_manager.failed_to_upload_media': 'Image upload failed. Please try again.',
	'media_manager.link_copied': 'Link copied!',

	'dropzone.dnd_default_message': 'Drag & drop here or select files',
	'dropzone.dnd_drag_message': 'Drop your files here.',
	'dropzone.upload_files': 'Select Files',

	'media_manager.cannot_delete.live_study': 'Cannot delete media in live study',

	/**
	 * routes/_study/StudyDesign/Flow/_components/LOIEstimator/LOIEstimator.js
	 */
	estimated_LOI: 'Estimated time:',
	up_to_minutes: 'up to {count, plural,one {{count} minute} other {{count} minutes}}',

	/**
	 * routes/_study/StudyDesign/Flow/_components/FlowButtons/FlowButtons.js
	 * routes/_study/StudyDesign/Flow/_components/FlowButtons/_components/SaveFlowButton/SaveFlowButton.js
	 */
	'library_flow_error.tooltip': 'Preview is disabled in templates with errors in flow',
	flow_empty_message: 'Please add modules to save flow',
	nothing_to_save: 'Nothing to save',
	'live_flow_save.no_datasets': 'Add or restore a question module to enable save',

	/**
	 * routes/_study/StudyDesign/Flow/_components/FlowButtons/_components/FlowButtons/FlowButtons.js
	 */
	view_diagram: 'View Diagram',
	view_code: 'View Code',

	/**
	 * routes/_study/StudyDesign/Flow/_components/FlowButtons/_components/StudyPreviewButton/StudyPreviewButton.js
	 */
	copy_link: 'Copy link',
	'copy_link.copied': 'Your study link was copied to clipboard!',
	'copy_link.save_changes': 'Please save or discard the changes',
	live_preview: 'Preview',
	'live_preview.no_questions': 'Please add Answer module to flow to enable Live preview',

	/**
	 * StudyActions select menu
	 */
	'study_actions.duplicate': 'Duplicate study',
	'study_actions.duplicate.from_template': 'Create study using template',
	'study_actions.duplicate.template': 'Duplicate template',
	'study_actions.save_as_template': 'Create template from study',
	study_settings: 'Study settings',
	library_settings: 'Template settings',
	study_tags: 'Study tags',
	library_tags: 'Template tags',
	'study_actions.duplicate.save_flow':
		'Please save or discard the changes before creating new study',
	'study_actions.save_as_template.save_flow':
		'Please save or discard the changes before copying to template',
	'study_actions.duplicate.study.low_version': 'Duplicating this study is no longer supported',
	'study_actions.duplicate.library.low_version': 'Duplicating this template is no longer supported',
	'study_actions.duplicate.study_to_library.low_version':
		'This study is old and cannot be used to create a template',
	'study_actions.duplicate.library_to_study.low_version':
		'This template is old and cannot be used to create a study',

	/**
	 * routes/_study/StudyDesign/Flow/_components/Diagram/Diagram.js
	 */
	'diagram.entry_point': 'Entry point',
	'diagram.end_point': 'Endpoint',
	'diagram.welcome_screen': 'Welcome screen',
	'diagram.library_start': 'Start of template',
	'diagram.library_end': 'End of template',
	'diagram.drop_your_module_here': 'Drop your module here',

	/**
	 * routes/_study/StudyDesign/_dnd/Module/_components/DescriptionHolder/DescriptionHolder.js
	 */
	'module_stats.seen_by_completes':
		'Module seen by {count} {count, plural, =0 {completes} one {complete} other {completes}}',
	'module_stats.seen_by_all':
		'Module seen by {count} {count, plural, =0 {respondents} one {respondent} other {respondents}}',
	'module_stats.counters': '{completes} / {all}',
	'module_stats.looped.title': 'Looped module',
	'module_stats.looped.description':
		'This represents a unique module for every item in the looped list.',

	/**
	 * routes/_study/StudyDesign/Detail/FlowDetail/FlowDetail.js
	 */
	welcome_screen_title: 'Welcome screen',
	welcome_screen_description:
		'Welcome screen is the first screen that respondents see.\nBelow you can add Study logo, name and description and change theme color visible to the respondents.',
	dataset_nonUniqueSimpleName: 'All display names within the study must be unique.',
	dataset_invalid: 'Display name is invalid',
	snippet_nonUniqueSimpleName: 'All display names and quota names within the study must be unique.',

	dataset_requiredSimpleName: 'Display name cannot be empty',
	dataset_simpleNameTooLong: 'Display name is too long',
	dataset_shortNameContainsDot: 'Display name cannot contain dots',
	branch_name_required: 'Branch name cannot be empty',
	branch_name_invalid: 'Branch name is invalid',

	dataset_shortname_label: 'Display name',

	'flow_detail.initial_message':
		'Pick a midpoint in flowline\nto add new module\n\nOR\n\nSelect a module to edit\nits content and settings',

	/**
	 * routes/_study/StudyDesign/Detail/FlowDetail/_details/StudySettingsDetail/StudySettingsDetail.js
	 */
	'welcome_screen_settings.internally_managed':
		'Welcome screen settings are unavailable because your study contains custom services. Please contact us to make changes.',
	'welcome_screen_settings.title': 'Welcome screen title',
	'welcome_screen_settings.description': 'Welcome screen description',
	'welcome_screen_settings.description.placeholder': 'Briefly describe what study is about',
	'welcome_screen_settings.theme_color': 'Study theme color',
	'welcome_screen_settings.error.required_title': 'Please add study title',
	'welcome_screen_settings.error.description_too_long': 'Welcome screen description is too long',

	/**
	 * routes/_study/StudyDesign/_dnd/Item/Item.js
	 */
	'item.cannot_restore_hidden_parent':
		'Drag the module out of hidden branch or restore the branch to enable module restore',

	/**
	 * routes/_study/StudyDesign/Detail/FlowDetail/_components/Header/Header.js
	 */
	duplicate: 'Duplicate',
	copy_to_library: 'Create template from {subject}',
	create_study_from_flow: 'Create study from flow',
	create_library_from_flow: 'Create template from flow',
	delete_library: 'Delete template',
	delete_library_message: 'Delete template',
	confirm_library_name: 'Enter "{libraryName}" to delete the template.',
	'confirm_library_name.input_label': 'Confirm template name',
	module: 'module',

	/**
	 * routes/_study/StudyDesign/_dnd/Module/Module.js
	 */
	'module.remove.title': 'Remove module with all of its settings?',
	'module.remove.condition': 'Remove condition only',
	'module.remove.block': 'Remove block only',
	'module.remove.randomizer': 'Remove randomizer only',
	'remove.all': 'Remove all',
	'remove.keep_all': 'Keep all',

	/**
	 * routes\_study\StudyDesign\_dnd\Item\_components\ModuleContent\_components\ChoiceItem\ChoiceItem.js
	 */
	'item.list.choice_item.default_value': 'Default value: {defaultValue}',

	/**
	 * routes/_study/StudyDesign/_dnd/AddModule/_components/AddModuleMenu/AddModuleMenu.js
	 */
	'add_module.questions': 'Questions',
	'add_module.formatted_text': 'Formatted text',
	'add_module.logic_commands': 'Flow logic',
	'add_module.ui_commands': 'Flow management',

	/**
	 * routes/_study/StudyDesign/_dnd/AddModule/_components/AddModuleMenu/_components/LibrarySelect/LibrarySelect.js
	 */
	'library.public_libraries': 'Public templates',
	'library.private_libraries': 'Private templates',
	'library.internal_libraries': 'Internal templates',
	'library.load_failed': 'Failed to load the library. Please try again.',

	/**
	 * routes/_study/StudyDesign/_dnd/Item/_components/NoteWindow/_components/StudyNotes/_components/TasksSummary/TasksSummary.js
	 */
	'study_notes.open_task': 'Open tasks',
	'study_notes.closed_task': 'Closed tasks',
	no_tasks: 'There are no tasks in this study.',

	/**
	 * routes/_study/StudyDesign/_dnd/Item/_components/NoteWindow/_components/StudyNotes/_components/Comment/Comment.js
	 */
	'backend_translate.note_deleted': 'Note was deleted',
	edit_comment: 'Edit your comment',
	write_comment: 'Write comment',
	add_a_comment: 'Add a comment',

	/**
	 * routes/_study/StudyDesign/_dnd/Item/_components/NoteWindow/_components/StudyNotes/_components/Comment/_components/Tasks/_components/TaskInput/TaskInput.js
	 */
	create_new_task: 'Create new task',
	edit_task: 'Edit task',

	/**
	 * routes/_user/User/User.js
	 */
	'account.users': '{count} {count, plural,=0 {users} one {user} other {users}}',
	'account.studies': '{count} {count, plural,=0 {studies} one {study} other {studies}}',
	'user.admin_role_count':
		'Admin to {count, plural,=0 {no accounts} one {{count} account} other {{count} accounts}}',
	'user.user_role_count':
		'User in {count, plural,=0 {no accounts} one {{count} account} other {{count} accounts}}',
	'user.accounts_count':
		'{count, plural,=0 {No accounts} one {Your account ({count})} other {Your accounts ({count})}}',
	'user.leave_account': 'Leave account',
	'user.you_dont_have_account': "We'll need to create an account. Please click here to contact us",

	/**
	 * routes/_user/User/components/UserDetail/UserDetail.js
	 */
	'userDetail.last_password_changed': 'Last changed: {date}',

	/**
	 * routes/_user/User/components/Notifications/Notifications.js
	 */
	back_to_notifications: '← Back to notifications',

	/**
	 * routes/_user/User/components/Notifications/_components/UserNotifications/components/UserNotification/UserNotification.js
	 */
	'notification.message.study_state': 'Your study has been {studyState} by {user}',
	'notification.message.collection': 'Your study is at {percentage}% completes',
	'notification.message.study_delete_indication':
		'Your study has been marked for deletion by {user}',
	'notification.message.study_delete_reminder':
		'Your study will be deleted in {days} {days, plural,=0 {days} one {day} other {days}}',
	'notification.message.study_auto_close_reminder':
		'Your study will be moved into {nextState} state in {days} {days, plural,=0 {days} one {day} other {days}}',

	/**
	 * routes/_study/DataDownloader/DataDownloader.js
	 */
	'dataDownloader.data_downloader': 'Data Downloader',
	'dataDownloader.request_download': 'Request download',
	'dataDownloader.banner_message':
		'Select the file format and click Request download to generate your data files. Requests are usually processed within a few seconds. For more nuanced download options, click Customize.',

	/**
	 * routes/_study/DataDownloader/_components/Downloader
	 */
	'dataDownloader.all_available': 'Download data as',

	/**
	 * routes/_study/DataDownloader/_components/Downloader/_components/CustomDownloader/CustomDownloader
	 */
	offer_choices_results_count_label: 'Count',
	offer_choices_results_percentage_label: 'Percentage',
	offer_detailed_data_output_codes_label: 'Choice options as codes',
	offer_detailed_data_output_labels_label: 'Choice options as labels',
	offer_termination_report_output_codes_label: 'Choice options as codes',
	offer_termination_report_output_labels_label: 'Choice options as labels',
	offer_header_settings_options_values_single_label: 'Headers as single line',
	offer_header_settings_options_values_multi_label: 'Headers as multiple lines',
	select_all: 'Select all',
	not_available_select: 'Download not available in selected file format',

	/**
	 * routes/_study/DataDownloader/_components/DownloadHistory/DownloadHistory.js
	 */
	'downloadHistory.cancel_title': 'Cancel Download',
	'downloadHistory.cancel_question': 'Are you sure you want to cancel the download?',
	'downloadHistory.cancel_confirm': 'Cancel download',
	'downloadHistory.delete_title': 'Delete Download',
	'downloadHistory.delete_question': 'Are you sure you want to delete the download?',
	'downloadHistory.empty': 'Download History is empty',
	'downloadHistory.downloads_show_here': 'Your downloads will show up here.',

	/**
	 * constants/dataDownloader.js
	 */
	'dataDownloader.preparing': 'Preparing',
	'dataDownloader.finished': 'Finished',
	'dataDownloader.old_data': 'Old Data',
	'dataDownloader.waiting': 'Waiting',
	'dataDownloader.error': 'Error',
	'dataDownloader.canceled': 'Canceled',
	'dataDownload.canceling': 'Canceling',

	/**
	 * routes/_study/RespondentManager/RespondentManager.js
	 */
	'respondentManager.header': 'Respondent Manager',
	'respondentManager.excluded.header': 'Excluded Respondents',
	'respondentManager.included.header': 'Included Respondents',
	'respondentManager.exclude.button': 'Exclude',
	'respondentManager.include.button': 'Include',
	'respondentManager.login.params': 'Login params',
	'respondentManager.login.type': 'Login type',
	'respondentManager.study.start': 'Start of the study',
	'respondentManager.study.end': 'End of the study',

	/**
	 * routes/_study/RespondentManager/_components/BulkExclude/BulkExclude.js
	 */
	'respondentManager.bulk_exclude': 'Bulk exclude',
	'respondentManager.ids_respondents': 'Respondents ids list',
	'respondentManager.invalid_ids': 'Invalid ids: {invalidIds}',
	'respondentManager.hint': 'Enter ids separated by new line',

	/**
	 * routes/_study/DataImporter
	 */
	'community.page_description':
		'Upload your community data document and select a parameter to match respondents with. This data will then merge with the data in the community panel.',
	'community.manage_community_panels': 'Manage Community panels',
	data_importer: 'Data Importer',
	'data_importer.page_description':
		'Data importer lets you pair additional information with individual respondents. Upload your own data document and select a parameter to match respondents with.',
	'data_importer.no_file_message': '<bold>Import your own data.</bold> {pageDescription}',
	'data_importer.upload_file': 'Upload file',
	'data_importer.respondent_attribute_error': 'Respondent attribute cannot be empty',
	'data_importer.do_not_import': 'Do not import',
	'data_importer.import_as_oeq': 'AI Open-End™',
	'data_importer.import_as_freetext': 'Formatted text',
	'data_importer.import_as_choice': 'Choice',
	'data_importer.import_column': 'Import column',
	'data_importer.import_as': 'Import as',
	'data_importer.imported_file': 'Imported file',
	'data_importer.respondent_attribute': 'Respondent attribute',
	'data_importer.remove_file': 'Remove file',
	'data_importer.remove_file_title': 'Remove Uploaded File',
	'data_importer.remove_file_confirmation': 'Are you sure you want to remove the uploaded file?',
	'data_importer.overlay.title': 'Processing imported data.',
	'data_importer.overlay.mesage': 'This could take a while. Please do not close your browser.',
	'data_importer.data_changed': 'Import data have been modified',
	'data_importer.save_rules_initial': 'Save changes to begin data processing',
	'data_importer.invalid_file':
		'The uploaded file is invalid. Please remove it and upload it again.',
	'data_importer.invalid_file_format':
		'The uploaded file has an invalid format. Please use a .csv file.',
	'data_importer.parse_error': 'Error at row {row}: {error}',
	'data_importer.non_unique_rules': 'Respondent attributes have to be unique',
	'data_importer.no_unique_column_error':
		'Error: Uploaded file has to have at least one column with unique values',
	'data_importer.duplicate_column_error': 'Error: Columns have to be unique',
	'data_importer.no_email_column_error':
		'Error: Uploaded file has to have at least one column with email values',
	'data_importer.missing_columns': 'Error: Uploaded file is missing some columns',
	'data_importer.merge_rows_where': 'Merge rows where',
	'data_importer.from_file': 'from file equals',
	'data_importer.in_respondent': 'in respondent',
	'data_importer.select_respondent_key': 'Column with unique email values:',
	'data_importer.respondent_key_callout_message':
		'You must choose merge keys from both file and respondent',
	'data_importer.login_parameter': 'login parameter',
	'data_importer.nothing_imported': 'You have not imported any column yet.',
	'data_importer.upload_additional_data': 'Upload additional data',
	'data_importer.can_not_edit_while_processing':
		'You can not edit import while the file is being processed.',

	/**
	 * components/_statusBar/simple/RecalculationStatus/RecalculationStatus.js,
	 * layouts/_components/Navigation/_components/Section/RecalculationStatus/RecalculationStatus.js
	 */
	recalc_status_title_recalculating: 'Reports are being recalculated',
	recalc_status_text_recalculating:
		'Every update in ideapool or segment list triggers recalculations that may change outputs of your reports. Please wait for this icon to change before reviewing reports.',

	recalc_status_title_waiting_for_respondents: 'Waiting for respondents',
	recalc_status_text_waiting_for_respondents:
		'There are no respondents in your study at the moment.',

	recalc_status_title_up_to_date: 'Reports are up to date',
	recalc_status_text_up_to_date:
		'All recalculations have been finished and your reports are ready to be reviewed.',

	/**
	 * components/_statusBar/composed/SelectAction/SelectAction.js
	 */
	'status_bar.select_action.themes.use_themes': 'Use themes',
	'status_bar.select_action.themes.use_answers': 'Use answers',

	/**
	 * components/_statusBar/composed/General/Crosstabs.js
	 */
	segment_no_longer_exists: 'Segment no longer exists',

	/**
	 * routes/_account/Account/_components/Users/Users.js
	 */
	'account.user_management.make_admin': 'Make admin',
	'account.user_management.make_user': 'Make user',
	'account.user_management.you_title': '{fullName} (You)',
	'account.user_management.contact_person_title': '{fullName} (Contact person)',
	'account.user_management.header_title': '{title} {count}',
	'account.user_management.user_role': '{user}\u2000({role})',
	'account.user_management.admin_email': 'Admin  \u00B7 {email}',
	'account.user_management.admins_title': 'Admins',
	'account.user_management.editors_title': 'Editors',
	'account.user_management.groupsolver_title': 'GroupSolver team',
	'account.user_management.groupsolver_title_secondary':
		'Our experts who have access to your account to improve performance.\nYou can remove them anytime.',
	'account.user_management.admin_option': 'Admin',
	'account.user_management.editor_option': 'Editor',
	'account.user_management.you_option': 'You',
	'account.user_management.remove_user_option': 'Remove from account',
	'account.user_management.faq': "Questions? We've got answers.",
	'account.user_management.overlay_title_remove': 'Remove {userName} from account',
	'account.user_management.overlay_title_leave': 'Are you sure you want to leave {accountName}?',
	'account.user_management.overlay_title_update':
		"Are you sure you want to change {userName}'s role?",
	'account.user_management.overlay_message_remove':
		"You are about to remove {userName} from {accountName} account. Don't worry, their comments and history will not be erased.",
	'account.user_management.overlay_message_leave':
		"You will lose access to this account's studies, LiveSlides, and data. You cannot undo this action.",
	'account.user_management.overlay_message_update_admin':
		'Admins have control over account settings and billing.',
	'account.user_management.overlay_message_update_editor':
		'Editors can still edit all studies, reports, and LiveSlides.',
	'account.user_management.overlay_confirmation_remove': 'Yes, I want to remove this person',
	'account.user_management.overlay_confirmation_update': 'Change role to {role}',
	'account.user_management.invited_users_headline': 'Invited users',
	'account.user_management.invite_expires_in':
		'Pending (expires in {dayCount} {dayCount, plural, one {day} other {days}})',
	'account.user_management.invite_resent': 'Invite resent',
	'account.user_management.resend_invite': 'Resend',
	'account.user_management.invite_cancel.confirm_message':
		'Are you sure you want to cancel the invite to {email}?',
	'account.user_management.invite_cancel.yes_button': 'Yes',
	'account.user_management.invite_cancel.no_button': 'No',
	'account.user_management.no_invites_message': 'Invited users will appear here...',
	'account.user_management.user_already_invited_error':
		'This email is already invited to the account.',
	'account.user_management.user_already_assigned_error':
		'{userName} is already a user of this account.',
	'account.user_management.invite_expired':
		'This invite recently expired. To resend it, please invite {email} again.',

	/**
	 * routes/_account/Account/_components/Users/_components/PendingInvites.js
	 */
	pending_invites: 'Pending invites',
	available_seats: '{count} {count, plural, one {seat} other {seats}} available',
	'pending_invites.labels': '{title}{subtitle}',
	no_available_seats: 'No seats available',
	invite_your_team: 'Invite your team to GroupSolver',
	invite_users: 'Invite users',
	'admin_invite_user.tooltip': 'You must be an account administrator to invite users.',
	'not_enough_user_seats.tooltip':
		"You don't have enough user seats available in your plan. Contact us if you need more.",

	/**
	 * routes/_account/Account/_components/Users/_components/PendingInvites/_components/InvitesOverlay.js
	 */
	seats_available: '{availableSeats}/{allowedSeats} seats available',
	send_invite: 'Send invite',
	no_seats_message: 'No more seats available. Need more?\u2000{contactUs}',
	multiple_emails_error: "You can't send multiple invites at once",
	'invites_overlay.charged_for_user_warning': 'You will be charged for each accepted invitation',

	/**
	 * components/_scaffolding/ImageBanners
	 */
	'image_banners.trial_title': 'Start your journey\nto better insights',
	'image_banners.trial_message':
		'Join global leaders who rely on GroupSolver\nto answer their toughest questions.',
	'image_banners.trial_button': 'View plans',
	'image_banners.pro_title': 'Get a plan tailored\nto your needs',
	'image_banners.pro_and_enterprise_message':
		'All the support and flexibility to answer\nthe most complex questions for your business.',
	'image_banners.enterprise_title': 'Contact your strategy\nmanager anytime',
	'image_banners.internal_title': 'Hire our market\nresearch experts.',
	'image_banners.contact_phone': '+1-619-728-6505',

	/**
	 * payment
	 */
	'payment.requires_action': 'Requires action',
	'payment.failed': 'Payment failed',
	'payment.verify': 'Verify payment',
	'payment.error.insufficient_funds':
		"Your payment couldn't be completed because of insufficient funds on your card.",
	'payment.error.authentication_required': 'Payment requires authentication',
	'payment.error.expired_card': 'Your card is expired',
	'payment.error.invalid_amount': 'Amount exceeds the card limit',
	'payment.error.payment_intent_authentication_failure': 'Payment authentication failed',
	'payment.error.default': "There's been an unknown issue with your payment.",
	'payment.title': 'Payment method',
	'payment.upgrade_warning': 'You can change your card in the Plans & Billing section.',

	/**
	 * routes/_account/Account/_components/Profile/_components/AccountInfo/AccountInfo.js
	 */
	contact_person: 'Contact person',
	contact_person_cannot_be_user: 'Contact person cannot have the editor role.',
	contact_person_cannot_remove: 'Contact person cannot be removed.',

	/**
	 * account / BillingInfoForm.js
	 */
	billing_info_tooltip: `You can't edit the billing info while we are verifying your {country, select,
		US {Tax}
		other {VAT}
	} ID. `,
	billing_info_billing_email_placeholder: 'Enter your billing email',
	billing_info_organization_name_placeholder: 'Enter organization name',
	billing_info_address_line_1_placeholder: 'Street address',
	billing_info_address_line_2_placeholder: 'Apt., suite, unit number, etc. (optional)',
	billing_info_tax_id_placeholder: 'Enter your tax ID',
	billing_info_edit_details: 'Edit details',
	billing_info_send_notifications: 'Send billing notifications to',
	billing_info_address: 'Address',
	billing_info_title: 'Billing information',
	billing_info_country: 'Country',
	billing_info_state: 'State / Province',
	billing_info_state_error: `{stateLabel} is required`,
	billing_info_city: 'City',
	billing_info_postal_code: 'Postal code',
	billing_info_address_line_1: 'Address line 1',
	billing_info_address_line_2: 'Address line 2',
	billing_info_billing_address: 'Billing address',
	billing_info_button_update_billing: 'Update billing info',
	billing_info_phone: 'Billing phone',
	billing_info_phone_contact_person_checkbox: "Use contact person's email and phone number",
	billing_info_email: 'Billing email',
	billing_info_email_error: 'Billing email is required',
	billing_info_not_supported_country: 'This country is not currently supported',
	billing_info_tax_status: `{country, select,
		US {Tax}
		other {VAT}
	} status`,
	billing_info_tax_id: `{country, select,
		US {Tax}
		other {VAT}
	} ID`,
	billing_info_error_tax_id: `{country, select,
		US {Tax}
		other {VAT}
	} ID is required`,
	billing_info_invalid_tax_id: `Invalid {country, select,
		US {Tax}
		other {VAT}
	} ID`,
	billing_info_verification_status_pending: `{country, select,
		US {Tax}
		other {VAT}
	} ID validation in progress`,
	billing_info_verification_status_unverified: `{country, select,
		US {Tax}
		other {VAT}
	} ID is not valid`,
	billing_info_verification_status_unavailable: `We were not able to verify {country, select,
		US {Tax}
		other {VAT}
	} ID`,
	billing_info_verification_status_verified: 'Verified',
	billing_info_verification_status_verifying: 'Verifying',
	billing_info_change_information: 'Change billing information',
	billing_info_verify: `You will not be able to purchase GroupSolver Panels, or a subscription until we verify your {country, select,
			US {Tax}
			other {VAT}
		} ID`,
	billing_info_new_card_verify:
		'You will not be able to purchase GroupSolver Panels, or a subscription until we verify your new card.',
	billing_info_summary_warning:
		'You can change your payment details in the Plans & Billing section. Payment will not be processed during change verification.',
	billing_info_empty:
		'Enter your billing information, and get access to respondents via GroupSolver Panel, and purchase a subscription online.',
	billing_info_add: 'Add billing information',

	/**
	 * account PaymentLog
	 */
	'payment_log.panel.tooltip': `Study: {studyLabel}\nPanel: {respondentSourceLabel}`,

	/**
	 * PhotoUploader
	 */
	'photo_uploader.account_message': 'Add account picture for easier navigation.',
	'photo_uploader.profile_message': 'Profile photos make team collaboration easier.',
	'photo_uploader.change_photo': 'Change photo',
	'photo_uploader.upload_photo': 'Upload photo',

	/**
	 * src/constants/account.js
	 */
	vat_status_business: 'Business',
	vat_status_business_no_vat: 'Business no VAT',
	vat_status_business_no_tax: 'Business no TAX',
	vat_status_personal: 'Personal',

	/**
	 * routes/_user/User/components/AccountList/_components/LeaveAccountConfirmation/LeaveAccountConfirmation.js
	 */
	'leave_account_confirmation.leave_account_title': 'Leave account',
	'leave_account_confirmation.account_name': 'Account name',
	'leave_account_confirmation.correct_account_name': 'Correct account name',
	'leave_account_confirmation.confirm':
		'Please enter the name of the account you wish to leave and your password to proceed.',
	'leave_account_confirmation.title': 'Are you sure you want to leave the "{accountName}" account?',

	/**
	 * layouts/ReportBuilder/components/Tips/Tips.js
	 */
	'report_builder_tips.multisort': 'Tip: You can control multisort by holding Shift key',

	/**
	 * routes/_account/Account/_components/Profile/_components/AccountInfo/_components/AccountInfoForm/AccountInfoForm.js
	 */
	'account_info_form.display_name': 'Account name',
	'account_info_form.contact_person': 'Contact person',
	'account-info_form.title': 'Account information',
	'account_info.live_slides_colors': 'LiveSlides colors',
	'account_info.live_slides_colors.colors': 'Colors',
	'account_info.live_slides_colors.colors.info':
		'These colors are automatically assigned to answers. Automatically assigned color can be changed in chart Data tab.',
	'account_info.live_slides_colors.hightligh_colors': 'Highlight colors',
	'account_info.live_slides_colors.hightligh_colors.info':
		'These colors are not automatically assigned to any answers and can be used to highlight an answer using chart Data tab.',
	'account_info.live_slides_colors.set_default': 'Set as default',
	'account_info.live_slides_colors.delete.title': 'Delete color set?',
	'account_info.live_slides_colors.delete.message':
		'Are you sure you want to delete the {name} color set?',
	'account_info.live_slides_colors.create.button': 'Create new color set',
	'account_info.live_slides_colors.create.name': 'New color set',
	'account_info.live_slides_colors.create.title': 'Create color set',
	'account_info.live_slides_colors.color.empty': 'Color set has to have at least one color',
	'account_info.live_slides_colors.highlight_colors.empty':
		'Color set has to have at least one highlight color',

	/**
	 * components/_statusBar/composed/General
	 */
	'status_bar.general.use_themes': 'Use Themes',
	'status_bar.general.show_top_unthemed_statements': 'Show unthemed',
	'status_bar.general.top_unthemed_answers': 'Top unthemed answers',
	'status_bar.general.no_unthemed_answers': 'No unthemed answers available.',
	'status_bar.general.answers_limits': 'Answers limit: {count}',
	'status_bar.general.error_bars': 'Error bars',

	/**
	 * IdeaCloud.js
	 */
	'ideacloud.bubble_positions': 'Bubble positions',
	'ideacloud.generate_positions': 'Generate new positions',

	/**
	 * components/_statusBar/composed/General/Consensus.js
	 */
	'status_bar.general.consensus.restrict_label': `Restrict to {item, select,
		correlation {correlation}
		other {support strength}
	} between {min} and {max}`,
	'status_bar.general.consensus.central_statement': 'Central answer',

	/**
	 * components/_statusBar/composed/General/IntelliSegment/IntelliSegment.js
	 */
	'status_bar.general.intellisegment.num_of_axes': 'Number of axes',
	'status_bar.general.intellisegment.segment.top': 'Top Y segment',
	'status_bar.general.intellisegment.segment.right': 'Right X segment',
	'status_bar.general.intellisegment.segment.bottom': 'Bottom Y segment',
	'status_bar.general.intellisegment.segment.left': 'Left X segment',

	/**
	 * components/_statusBar/composed/Layout
	 */
	'status_bar.layout.legend': 'Chart legend',
	'status_bar.layout.chart_width': 'Chart width: {count}px',
	'status_bar.layout.default_texts.segments': '{segmentLabel} Expected Support',
	'status_bar.layout.default_texts.segments_with_direction':
		'{segmentLabel} Expected Support ({direction})',
	'status_bar.layout.default_texts': 'Bubble detail settings',
	'status_bar.layout.detail_bubbles.default_texts':
		'{count, plural,=0 {No texts are} one {{count} text is} other {{count} texts are}} currently set as default.',
	'status_bar.layout.scales.use_autoscale': 'Trim whitespace',

	/**
	 * components/_statusBar/composed/Layout/Basic.js
	 */
	'status_bar.layout.basic.graph_type': 'Graph type',
	'status_bar.layout.basic.bar_chart': 'Bar chart',
	'status_bar.layout.basic.donut_chart': 'Donut chart',
	'status_bar.layout.basic.donut': 'Donut',
	'status_bar.layout.basic.attribute_label': 'Attribute label',
	'status_bar.layout.basic.show_bars_as': 'Show bars as',
	'status_bar.layout.basic.vertical': 'Vertical',
	'status_bar.layout.basic.column': 'Column',
	'status_bar.layout.basic.horizontal': 'Horizontal',
	'status_bar.layout.basic.row': 'Row',
	'status_bar.layout.basic.vertical.chart': 'Column Chart',
	'status_bar.layout.basic.horizontal.chart': 'Horizontal Chart',
	'status_bar.layout.basic.show_labels_as': 'Show labels as',
	'status_bar.layout.basic.slanted': 'Slanted',
	'status_bar.layout.basic.show_values': 'Show values',
	'status_bar.layout.basic.show_round_type': 'Round type',

	/**
	 * components/_statusBar/composed/Layout/IdeaCloud.js
	 */
	'status_bar.layout.ideacloud.font_size': 'Font size: {min}px - {max}px',
	'status_bar.layout.ideacloud.offset': 'Offset {axis}',

	/**
	 * src/components/_statusBar/composed/Layout/Consensus.js
	 */
	'status_bar.layout.consensus.number_of_guidelines': 'Number of guidelines: {count}',
	'status_bar.layout.consensus.bubble_size': 'Bubble size: {count}px',
	'status_bar.layout.consensus.bubble_size_type': 'Bubble size type',
	'status_bar.layout.consensus.toggle_trimming': 'Toggle trimming',

	/**
	 * components/_statusBar/composed/Layout/Matrix.js
	 */
	'status_bar.layout.matrix.chartType': 'Type of chart',
	'status_bar.layout.matrix.chartType.bar': 'Bar',
	'status_bar.layout.matrix.chartType.stacked': 'Stacked',
	'status_bar.layout.matrix.chartType.stacked_chart': 'Stacked chart',
	'status_bar.layout.matrix.groupBy': 'Group by',
	'status_bar.layout.matrix.groupBy.rows': 'Rows',
	'status_bar.layout.matrix.groupBy.columns': 'Columns',
	'status_bar.layout.matrix.groupLegendBy': 'Group legend by',
	'status_bar.layout.matrix.groupLegendBy.groupSegment': 'Group segment columns',
	'status_bar.layout.matrix.groupLegendBy.groupDataset': 'Group dataset columns',

	/**
	 * components/_statusBar/composed/SortAndFilter/Basic/Basic.js
	 */
	'status_bar.sort_and_filter.sort': 'Sort by columns',
	'status_bar.sort_and_filter.filter': 'Filter by columns',
	'status_bar.sort_and_filter.add_column': 'add a column',
	'status_bar.sort_and_filter.excluded_themes': 'Excluded themes',

	/**
	 * components/_statusBar/composed/SortAndFilter/Matrix/Matrix.js
	 */
	'status_bar.sort_and_filter.matrix.visible_rows': 'Visible rows',
	'status_bar.sort_and_filter.matrix.visible_rows.placeholder':
		'{count, plural,=0 {No questions are} one {{count} question is} other {{count} questions are}} currently visible.',
	'status_bar.sort_and_filter.matrix.visible_rows.placeholder_all':
		'All questions are currently visible.',

	/**
	 * reportBuilder chart containers
	 */
	'report_builder.chart.number_of_ideas_shown':
		'{shown} of {total} {total, plural, =0 {answers} one {answer} other {answers}} displayed',
	'report_builder.chart.number_of_questions_shown':
		'{shown} of {total} {total, plural, =0 {questions} one {question} other {questions}} displayed',
	'report_builder.chart.number_of_themes_shown':
		'{shown} of {total} {total, plural, =0 {themes} one {theme} other {themes}} displayed',
	'report_builder.chart.number_of_segments_shown':
		'{shown} of {total} {total, plural, =0 {segments} one {segment} other {segments}} displayed',

	/**
	 * report types
	 */
	[REPORT_TYPES.ALLOCATION_ANSWERS]: 'Allocation Answers',
	[REPORT_TYPES.CHOICE_ANSWERS]: 'Choice Answers',
	[REPORT_TYPES.CONSENSUS]: 'Consensus Solution™',
	[REPORT_TYPES.FREE_TEXT]: 'Freetext',
	[REPORT_TYPES.HEATMAP]: 'Heatmap',
	[REPORT_TYPES.IDEACLOUD]: 'IdeaCloud™',
	[REPORT_TYPES.IDEACLUSTER]: 'IdeaCluster™',
	[REPORT_TYPES.INTELLISEGMENT]: 'IntelliSegment™',
	[REPORT_TYPES.MATRIX]: 'Matrix',
	[REPORT_TYPES.MAXDIFF]: 'MaxDiff',
	[REPORT_TYPES.OPEN_ANSWERS]: 'AI Open-End™',
	[REPORT_TYPES.QUANT_QUAL]: 'QuantQual',
	[REPORT_TYPES.RANKING_ANSWERS]: 'Ranking answers',
	[REPORT_TYPES.SUNBURST]: 'HaloGraph™',
	[REPORT_TYPES.SEGMENTATION_TYPING_TOOL]: 'Segmentation typing tool',

	/**
	 * routes/_admin/Account/StudyDetail/UpdateAccount/AccountTags.js
	 */

	'account_tags.internal': 'Internal account',
	'account_tags.test': 'Test account',
	'account_tags.quantQualAccessRequested': 'Requested QuantQual Access',
	'account_tags.quantQualAccessGranted': 'Allow QuantQual Access',

	input_too_low_zero: 'Inserted value should be equal to or greater than 0',
	input_max_spent_time: 'Your spent time should not be more than max spent hours',
	'account.update_research_expert_hours_spent': 'Update research expert services time spent',
	'account.spent_hours': 'Spent hours',

	/**
	 * routes/_admin/Study/StudyDetail/_components/MongoExport/MongoExport.js
	 */
	request_mongo_export: 'Request Mongo export',
	mongo_export_queued: 'Mongo export queued',
	mongo_stage_not_allowed: 'Stage {stage} is not allowed',

	/**
	 * routes/_admin/Study/StudyDetail/_components/CopyStudy/CopyStudy.js
	 */
	copy_study_queued: 'Copy study queued',
	copy_data: 'Copy data',
	copy_study_action_log: 'Copy study action log',
	request_copy_study: 'Request copy study',
	copy_study_old_version: 'Study version: {version}. Cannot copy study with version < 4',

	/**
	 * routes/_admin/Study/StudyDetail/_components/ChangeCustomTranslations/ChangeCustomTranslations.js
	 */
	custom_translation: 'Custom translations JSON',
	custom_translation_message: 'Custom translations successfully changed',

	/**
	 * routes/_admin/Study/StudyDetail/_components/ChangeMaxBots/ChangeMaxBots.js
	 */
	input_too_low: 'Inserted value should be bigger then 0',
	input_type_error: 'Inserted value should be a number',
	max_bots_count: 'Max bots count',
	max_bots_change_message: 'Max bots count successfully changed',

	/**
	 * routes/_admin/Study/StudyDetail/_components/UpdateRespondentSources/UpdateRespondentSources.js
	 */
	respondent_sources_message: 'Respondent sources successfully updated',
	respondent_source_JSON: 'Respondent source JSON',
	social_panels: 'Social panels',
	social_panel_id: 'Social panel {id}',
	groupsolver_panels: 'GroupSolver panels',
	groupsolver_panel_id: 'GroupSolver panel {id}',
	'groupsolver_panel.match_params': 'Respondent match parameters',
	'groupsolver_panel.increment': 'Max increment',
	'groupsolver_panel.match_params.empty': 'Match params cannot be empty object',
	'groupsolver_panel.match_params.invalid': 'Match params object is invalid',

	/**
	 * routes/_admin/Study/StudyDetail/_components/ChangeStudyState/ChangeStudyState.js
	 */
	study_state: 'Study state',
	state_changed_successfully: 'Study state successfully changed',
	'study_state_change.panels_notification':
		'Study state change will not affect following GroupSolver panels.',
	'study_state_change.panels_notification_detail':
		"Panel state has to be changed by hand using provider's Dashboard.",
	'panel.survey_number': 'Survey number: {surveyNumber}',
	'study_state_change.no_actions_allowed': 'Study state cannot be changed in {studyState} state.',

	/**
	 * routes/_admin/Study/StudyDetail/_components/MoveStudyToAccount/MoveStudyToAccount.js
	 */
	move_study: 'Move study',
	study_moved_successfully: 'Study successfully moved to different account',

	/**
	 * routes/_admin/User/UpdateUser/forms/UserSetDisabledForm.js
	 */
	'admin.user.change_is_active': 'Disable or Enable the user',
	'admin.user.disable': 'Disable user',
	'admin.user.enable': 'Enable user',
	'admin.user.status': 'User is {userStatus}',
	'admin.user.enabled': 'Enabled',

	/**
	 * routes/_admin/User/UpdateUser/forms/ForceUpdatePasswordForm.js
	 */
	'admin.user.force_change_password': 'Force change password',

	/**
	 * components/_scaffolding/ContactUsForm/ContactUsForm.js
	 */
	'contact_us_form.message': 'Send us a message using following form, or send email to',
	'contact_us_form.button': 'Send message',
	'contact_us_form.name_placeholder': 'Enter name',
	'contact_us_form.email_placeholder': 'Enter email',

	/**
	 * components/_scaffolding/PlatformStatus/PlatformStatus.js
	 */
	'platform_status.unavailable.header': 'Platform update in progress',
	'platform_status.unavailable.message': 'The page will refresh once the Dashboard is available.',

	/**
	 * routes/_study/_components/StudyForm/StudyForm.js
	 */
	study_name: 'Study name',
	study_language_default: 'Default study language',
	study_language_default_error: 'Please select a default langauge',
	create_study: 'Create study',
	library_name: 'Template name',
	create_library: 'Create template',
	library_description: 'Template description',
	library_description_placeholder:
		"Description that will be displayed in Create study template selection. Leave empty if study won't be used as template",
	create_study_template_languages: 'Study templates are available only in English.',
	create_study_post_analytics_languages: 'Post-analytics study is available only in English.',

	/**
	 * StudyLanguages
	 */
	study_languages: 'Study languages',
	study_languages_error: 'Please select at least one language',
	study_languages_add: 'Add new language',
	study_languages_add_disabled_tooltip:
		'Languages cannot be added or removed after launching the study.',
	study_languages_solver_default: 'Respondent default',
	study_languages_make_solver_default: 'Make respondent default',
	study_languages_report_default: 'Report default',
	study_languages_make_report_default: 'Make report default',
	study_languages_manage: 'Manage languages',
	study_languages_language_choice: 'Language choice',
	study_languages_language_choice_description:
		'Allow respondent to select their preferred language.',
	study_languages_language_choice_option_info:
		'Language choice options are added or removed automatically when adding or removing a study language.',
	study_languages_translate_info_header: 'Study design with multiple languages',
	study_languages_translate_info_content:
		'You can fill in the labels in one language and use {actionsLabel} actions in topbar study actions menu to translate them to the rest of the languages to save time.\nYou will need to save or discard any changes before using {actionsLabel} actions.',

	/**
	 * routes/_study/_components/StudyTagsForm/StudyTagsForm.js
	 */
	'study_tags.toBeDeleted': 'Delete study',
	'study_tags.test': 'Test study',
	'study_tags.isInternallyManaged': 'Internally Managed',
	'study_tags.experimental': 'Experimental study',
	'study_tags.disableRecalculations': 'Disable recalculations',
	'study_tags.useBertMatcher': 'Use bert matcher',
	'study_tags.forceIdeaBank': 'Save all collected ideas to IdeaBank',
	'study_tags.impactBrief': 'Impact brief',
	'study_tags.anonymizeGeolocation': 'Anonymize geolocation',
	'study_tags.disableRecalculationTimeshift': 'Disable recalculation timeshift',
	'study_tags.idProductTour': 'Product tour ID',
	'study_tags.ipmKeepPreseeded': 'Keep preseeded answers in separate clusters',
	'study_tags.isTerminationReportEnabled': 'Enable terminates report',
	'study_tags.isAccessibilityEnabled': 'Enable accessibility features',

	/**
	 * routes/_study/UpdateStudy/UpdateStudy.js
	 */
	'update_study.success': 'Study successfully updated',
	'update_template.success': 'Template successfully updated',

	/**
	 * routes/_study/AnswerManager/AnswerManager.js
	 */
	'answer_manager.save_or_discard': 'Please save or discard changes before changing the view',
	'answer_manager.themes.answers_in_themes': 'Answers in themes',
	'answer_manager.themes.run_autotheme': 'Run AutoTheme™',
	'answer_manager.themes.run_similar_autotheme': 'Similar',
	'answer_manager.themes.run_neutral_autotheme': 'Neutral',
	'answer_manager.themes.run_loose_autotheme': 'Loose',
	'answer_manager.build_themes.run_autotheme.headline': 'Choose similarity',
	'answer_manager.build_themes.run_autotheme.description':
		'How similar to each other should answers be in a theme?',
	'answer_manager.build_themes.run_autotheme.theme_generation_in_progress_warning': `Theme generation is in progress.{br}Please wait until it's finished for further use.`,
	'answer_manager.generating_themes.header': 'Generating themes',
	'answer_manager.generating_themes.message':
		"This could take few minutes. Please don't leave the page.",

	/**
	 * routes/_study/AnswerManager/_components/BuildThemes/BuildThemes.js
	 */
	'answer_manager.build_themes': 'Build themes',
	'answer_manager.build_themes.disabled':
		'Building themes is available only in studies with finished data collection',
	'answer_manager.build_themes.unsaved_changed':
		'Running AutoTheme™ will reset all unsaved changes',

	/**
	 * routes/_study/AnswerManager/_components/ThemesView/_components/ThemesTable/ThemesTable.js
	 */
	'answer_manager.themes.statements_in_one_theme': 'Answers in{nbsp}theme',
	'answer_manager.themes.statements_without_theme': 'Answers without theme',
	'answer_manager.themes.theme_support': 'Theme support (All completes)',

	/**
	 * routes/_study/AnswerManager/_components/ThemesView/_components/ThemesTable/_components/ExpandedView/ExpandedView.js
	 */
	'answer_manager.themes.no_statements': 'No answers',
	'answer_manager.themes.remove_from_theme': 'Remove from theme',
	'answer_manager.themes.move_to_another_theme': 'Move to another theme',
	'answer_manager.themes.create_label': '+ Create new theme',
	'answer_manager.themes.edit': 'Edit theme',

	/**
	 * routes/_study/AnswerManager/_components/ThemesView/ThemesView.js
	 */
	'answer_manager.themes_view.show_all_answers': 'Show all answers',
	'answer_manager.themes_view.intersect_search': 'Intersect search',
	'answer_manager.keyword_search.intersect_description':
		'Only show answers that match all entered keywords.',
	'answer_manager.keyword_search.show_all_description':
		'Show answers that match at least one of the entered keywords.',
	'answer_manager.keyword_search.use_synonyms': 'Use synonyms',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/StatementAnswerCell/StatementAnswerCell.js
	 */
	'answer_manager.make_invalid': 'Make invalid',
	'answer_manager.group.create': 'Create group',
	'answer_manager.statement.elaborations.agree':
		'{count} {count, plural, =0 {completes agree} one {complete agrees} other {completes agree}} with the answer',
	'answer_manager.statement.elaborations.disagree':
		'{count} {count, plural, =0 {completes disagree} one {complete disagrees} other {completes disagree}} with the answer',
	'answer_manager.statement.elaborations.indifferent':
		'{count} {count, plural, =0 {completes were} one {complete was} other {completes were}} indifferent',
	'answer_manager.statement.add_to_group': 'Add to answer group',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/StatementGroupAnswerCell/StatementGroupAnswerCell.js
	 */
	'answer_manager.group.edit': 'Edit group',
	'answer_manager.group.merged_group': 'Merged group',

	/**
	 * routes/_study/AnswerManager/AnswerManager.js
	 */
	'answer_manager.themes.create': 'Create theme',
	'answer_manager.included_statements': 'Included answers',
	'answer_manager.groups.expand_all': 'Expand all groups',
	'answer_manager.groups.collapse_all': 'Collapse all groups',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/StatementGroupForm/StatementGroupForm.js
	 */
	'answer_manager.group.main_statement': 'Main answer',
	'answer_manager.group.main_statement_placeholder': 'Please select the main answer',

	/**
	 * routes\_study\AnswerManager\_components\AnswersView\_components\DeleteGroupsConfirmation\DeleteGroupsConfirmation.js
	 */
	'answer_manager.group.delete': 'Delete {count, plural, =0 {groups} one {group} other {groups}}',
	'answer_manager.group.delete_message':
		'Are you sure you want to delete {title} {count, plural, =0 {groups} one {group} other {groups}}?',

	/**
	 * routes/_study/AnswerManager/_components/ThemesView/_components/ThemesTable/_components/StatementThemeForm/StatementThemeForm.js
	 */
	'answer_manager.themes.name_and_color': 'Theme name and color',
	'answer_manager.themes.delete': 'Delete theme',
	'answer_manager.themes.delete_message': 'Are you sure you want to delete "{name}" theme?',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/ThemeCell/ThemeCell.js
	 */
	'answer_manager.theme.add': 'Add theme',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/InvalidStatements/InvalidStatements.js
	 */
	'answer_manager.invalid_ideas': 'Invalid answers',

	/**
	 * routes/_study/AnswerManager/_components/AnswersView/_components/InvalidStatementAnswerCell/InvalidStatementAnswerCell.js
	 */
	'answer_manager.make_valid': 'Make valid',

	/**
	 * routes/_study/AnswerManager/_components/AnswerManagerToolbar/AnswerManagerToolbar.js
	 */
	'answer_manager_toolbar.add_theme.disabled':
		'Adding manual themes is available only for solved or post-analytics studies ',
	'answer_manager_toolbar.run_autotheme.disabled':
		'Running AutoTheme™ is available only for solved or post-analytics studies',

	/**
	 * routes/_study/RespondentManager/_components/RespondentsTable/RespondentsTable.js
	 */
	'respondent_manager.id_respondent': 'Respondent ID',
	'respondent_manager.panel': 'Panel',
	'respondent_manager.login_params': 'loginParams',
	'respondent_manager.start_time': 'Start time',
	'respondent_manager.duration': 'Solving time',
	'respondent_manager.deviation_class': 'Solving time deviation class',
	'respondent_manager.end_study_module': 'Complete module',
	'respondent_manager.number_of_failed_trap_questions': 'Failed trap questions',

	/**
	 * routes/_user/User/components/EmailNotificationsSettings/EmailNotificationsSettings.js
	 */
	'email_notifications.study_progress.title': 'Study progress email notifications',
	'email_notifications_preset.never.description': "Don't send email notifications",
	'email_notifications_preset.never.title': 'Never',
	'email_notifications_preset.never.option_title': 'Never*',
	'email_notifications_preset.every_hour.description': 'Send email notifications every hour',
	'email_notifications_preset.every_hour.disclaimer':
		'* You will continue to receive important email notifications including but not limited to license changes, account or security updates.',
	'email_notifications_preset.every_hour.title': 'Every hour',
	'email_notifications_preset.every_4_hours.description': 'Send email notifications every 4 hours',
	'email_notifications_preset.every_4_hours.title': 'Every 4 hours',
	'email_notifications_preset.every_12_hours.description':
		'Send email notifications every 12 hours',
	'email_notifications_preset.every_12_hours.title': 'Every 12 hours',
	'email_notifications_preset.daily.description':
		'Send email notifications daily at {time} {timezone} time',
	'email_notifications_preset.daily.option_description':
		'Send email notifications daily at selected time',
	'email_notifications.daily.setting': 'Select time when you want to receive email',
	'email_notifications_preset.daily.title': 'Daily',
	'email_notifications.updated': 'Email notifications settings updated',
	'email_notifications.label': 'Email notifications',
	'email_notifications.subtitle':
		'How often should we send you notifications about progress of your studies?',
	'email_notifications.never.disclaimer':
		'We will still send you important emails including, but not limited to license changes, account, or security updates.',

	/**
	 * Dashboard notifications settings
	 */
	'notifications_settings.title': 'Notifications settings',
	'notifications_settings.subtitle': 'Select notifications you want to receive.',
	'notifications_settings.study_changes.title': 'Study changes',
	'notifications_settings.study_changes.subtitle':
		'Get notified every time someone changes a study state (e.g. study has been launched by John)',
	'notifications_settings.progress_notifications.title': 'Progress notifications',
	'notifications_settings.progress_notifications.subtitle':
		'Get updates on study completion (e.g. study is 50% complete)',
	'notifications_settings.updated': 'Notifications settings updated',

	/**
	 * routes/_user/User/components/UserNotificationsSettings/UserNotificationsSettings.js
	 */
	'user_notifications.dashboard_notifications.title': 'Dashboard notifications',
	'user_notifications.study_changes.description': 'Study changes notifications',
	'user_notifications.all_notifications.description': 'All notifications from dashboard',
	'user_notifications.no_notifications.description': 'No notifications from dashboard',

	/**
	 * routes/_study/UpdateStudy/_components/DangerZone/DangerZone.js
	 */
	'danger_zone.title': 'Danger Zone',
	'danger_zone.confirmation_question': 'Are you sure you want to delete "{title}" study?',
	'danger_zone.confirmation_message':
		'Study data will not be accessible and all data will be removed after 7 days.',
	'danger_zone.confirmation_title': 'Delete study',

	/**
	 * routes/_study/UpdateStudy/_components/DangerZone/_components/DeleteStudyConfirm/DeleteStudyConfirm.js
	 */
	'delete_study_confirm.confirm_button': 'Confirm study delete',

	/**
	 * routes/_study/UpdateStudy/_components/DangerZone/_components/DeleteStudy/DeleteStudy.js
	 */
	'delete_study.delete_title': 'Delete study',
	'delete_study.restore_title': 'Restore study',
	'delete_study.delete_study_warning':
		'The study {studyName} will not be accessible and it will be deleted in 7 days. It will be impossible to recover any data after that.',
	'delete_study.cancel_delete_schedule_warning':
		'The scheduled delete can be canceled any time during the 7 days period to restore the study data.',
	'delete_study.cannot_delete_study_warning': 'Only archived studies can be deleted',
	'delete_study.study_name_label': 'Confirm study name',
	'delete_study.study_mismatch_error': 'Study name and study name confirmation have to match',
	'delete_study.schedule_delete': 'Schedule delete',
	'delete_study.time_until_delete_message': 'Study "{title}" will be deleted {time}.',
	'delete_study.cancel_delete': 'Cancel delete',

	/**
	 * routes/Dashboard/_components/StudyInfo/StudyInfo.js
	 */
	'study_info.restore_study_warning': 'Are you sure you want to restore study "{title}"?',

	/**
	 * routes/_user/User/components/UserNotifications/UserNotifications.js
	 */
	'user_notifications.label': 'User notifications',

	/**
	 * routes/_presentation
	 */
	presentation_builder: 'Report Builder',
	'presentation.create_new': 'Create a new report',
	'presentation.untitled': 'Untitled report',
	report_not_found: 'Report not found',
	report_not_found_message:
		"This report was not found. Either it was deleted, or you don't have access.",
	'report_not_found_message.slide_url': 'Report url is not valid.',
	'report_not_found_message.slide_error': 'Report loading encountered error.',
	'create-first-presentation-title': 'Create your first LiveSlides presentation',
	'create-first-presentation-subtitle':
		'LiveSlides is a powerful tool that allows you to create shareable live presentations while the survey is still running.',

	/**
	 * routes/_presentation/_components/TemplateBuilder/TemplateBuilder.js
	 */
	'template_builder.add_section': 'Add section',
	'template_builder.add_slide': 'Add slide',
	'template_builder.select_from_templates': 'Select from Templates',
	'template_builder.add_table_of_contents': 'Create section',
	'template_builder.change_template': 'Change template?',
	'template_builder.change_content': 'The content on your slide will be replaced.',
	'template_builder.add_slide_above': 'Add slide above',
	'template_builder.add_slide_below': 'Add slide below',
	'template_builder.duplicate_slide': 'Duplicate slide',
	'template_builder.add_top_level_slide_above': 'Add top slide above',
	'template_builder.add_top_level_slide_below': 'Add top slide below',
	'template_builder.add_slide_nested': 'Add nested slide',
	'template_builder.remove_study_warning.message':
		'This action will remove {counters}. Do you wish to deselect the {studiesCount, plural, =0 {} one {study} other {studies}}?',
	'template_builder.remove_study_warning.title':
		'Deselect {studiesCount, plural, =0 {} one {study} other {studies}}',

	'template_builder.last_saved': 'Last saved',
	'template_builder.last_updated': '{date} at {time}',
	'template_builder.selected_studies': 'Selected studies',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/GlobalReportSettings/GlobalReportSettings.js
	 */
	'global_report_settings.highlight': 'Highlight current section',
	'global_report_settings.exclude': 'Hide sections',
	'global_report_settings.delete': 'Delete presentation',
	'global_report_settings.delete_message':
		'This presentation will no longer be accessible after deleting.',
	'global_report_settings.open_view_mode': 'Play report',
	'global_report_settings.print_pdf': 'Export to PDF',
	'global_report_settings.print_pdf.tooltip':
		'Click here to open a new tab width PDF export and follow the instructions in the new tab.<br /><br />Chrome & Firefox:<ul><li>open browser Print dialogue</li><li>select PDF exporter as printer</li><li>change paper size and layout in Print dialogue box</li><li>adjust the scale in the Print dialogue box as needed if the slides are displaying cut off or incomplete</li><li>save/print pdf</li></ul><br />Safari:<ul><li>Zoom out the page to make all content visible</li><li>click on "File"</li><li>select "Export as PDF option"</li></ul>',
	'global_report_settings.footer': 'Footer',
	'global_report_settings.show_page_numbers': 'Show page numbers',
	'global_report_settings.edit_footer': 'Edit footer',
	'global_report_settings.add_note': 'Add floating note',

	/**
	 * floating text
	 */
	'floating_text.add.tooltip': 'Click on a slide to add a note',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/ChartEditor/_components/Sort.js
	 */
	'template_builder.chart_editor.sort.tooltip': 'No more options for sorting available.',
	'template_builder.chart_editor.sort.add_sort': 'Add Sort',
	'template_builder.chart_editor.sort.sort_by': 'Sort by',
	'template_builder.chart_editor.sort.sort_themes_by': 'Sort themes by',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/ChartEditor/_components/ChartOptions.js
	 */
	'template_builder.chart_editor.options.label_style': 'Label style',
	'template_builder.chart_editor.options.label_source': 'Option label',
	'template_builder.chart_editor.options.use_themes': 'Chart appearance',
	'template_builder.chart_editor.options.display_theme_colors': 'Display theme colors',
	'template_builder.chart_editor.options.responses_count': 'Show Nr. of responses:',
	'template_builder.chart_editor.options.confidence_interval': '90% confidence interval',
	'template_builder.chart_editor.options.show_error_bars': 'Show error bars',
	'template_builder.chart_editor.options.average_score': 'Average score',
	'template_builder.chart_editor.options.show_weighted_average': 'Show weighted average',
	'template_builder.chart_editor.options.average_score_tooltip':
		'The weighted average is only shown when the chart data is grouped by Attributes. You can toggle between Options and Attributes in Chart Data.',

	/**
	 * components/reports/ChartEditor/_components/Data/_components/DataTabHeader/DataTabHeader.js
	 */
	'chart_editor.include_unthemed_answers': 'Show unthemed answers',
	'chart_editor.answers_limit_tip.quant_qual.themes':
		'The chart might become unreadable when the number of themes is more than 10.',
	'chart_editor.answers_limit_tip.quant_qual.answers':
		'The chart might become unreadable when the number of answers is more than 10.',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Headline/Headline.js
	 */
	'headline.placeholder': 'Type headline ...',
	'headline.subheadline_placeholder': 'Add subheadline',
	'headline.remove': 'Remove headline',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/SlideList/_components/RenameSectionTitle.js
	 */
	'rename_section_title.title': 'Rename the slide',
	'rename_section_title.description': 'Add a name to the slide for easier access later.',
	'rename_section_title.placeholder': 'Slide name',
	'rename_section_title.rename': 'Rename',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Footnote/Footnote.js
	 */
	'footnote.placeholder': 'Add footnote...',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Footer/Footer.js
	 */
	'footer.placeholder': 'Add footer text...',
	'footer.logo': 'Logo',
	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/AddContent/AddContent.js
	 */
	'add_content.title': 'Add Content',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/Column/_components/Cell.js
	 */
	'cell.spacer_title': 'Spacer',
	'cell.copy_cell_content': 'Copy',
	'cell.move_cell_up': 'Move Cell Up',
	'cell.move_cell_down': 'Move Cell Down',
	'cell.fit_chart': 'Fit chart',
	'cell.chart.remove_deleted_segments': 'Remove absent segments',
	'cell.chart.question_deleted': `The question was deleted. Can't display the chart{chartTitle, select, true { "{chartTitle}".} other {.}}`,
	'cell.chart.segmentation_typing_tool_deleted': `The segmentation typing tool was deleted. Can't display the chart{chartTitle, select, true { "{chartTitle}".} other {.}}`,
	'cell.chart.segment_error': `Some selected segments no longer exist or are not valid anymore. Remove absent and invalid segments to display the chart{chartTitle, select, true { "{chartTitle}".} other {.}}`,

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/Column/_components/Cell/_components/TerminationStatistics/TerminationStatistics.js
	 */
	'summary_statistics.termination.pre_screen': 'Pre-screen',
	'summary_statistics.termination.quality': 'Quality Terminates',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/Column/_components/Cell/_components/FroalaEditor/FroalaEditor.js
	 */
	'froala.media.replace': 'Replace media',
	'froala.media.error.bad_link': 'Media cannot be loaded from the passed link',
	'froala.media.error.no_link': 'No link in upload response',
	'froala.media.error.upload': 'Error during media upload',
	'froala.media.error.parsing': 'Parsing response failed',
	'froala.media.error.too_large': 'File is too large',
	'froala.media.error.invalid': 'Invalid media type',
	'froala.media.error.video_support': 'Your browser does not support HTML5 video',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/Column/Column.js
	 */
	'column.select_option_chart': 'Chart',
	'column.select_option_spacer': 'Spacer',
	'column.select_option_text': 'Text',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/SectionTemplatesSettings/SectionTemplatesSettings.js
	 */
	'section_templates.subheadline': 'Subtitle of the report',
	'section_templates.title_slide.title': 'Title slide',
	'section_templates.title_slide.headline': 'Main title with image',
	'section_templates.end_slide.title': 'Title slide',
	'section_templates.end_slide.headline': 'Main title with social media links',
	'section_templates.content_table.title': 'Sections',
	'section_templates.chart_on_right.title': 'Chart on right',
	'section_templates.chart_on_left.title': 'Chart on left',
	'section_templates.two_charts.title': 'Two charts',
	'section_templates.three_charts.title': 'Three charts',
	'section_templates.four_charts.title': 'Four charts',
	'section_templates.six_charts.title': 'Six charts',
	'section_templates.key_findings.title': 'Key Findings',
	'section_templates.groupsolver_approach.title': 'GroupSolver Approach',
	'section_templates.table_slide.title': 'Table slide',
	'section_templates.summary_statistics.title': 'Summary statistics',
	'section_templates.table_content.title': 'Content',
	'section_templates.groupsolver_approach.text.paragraph.01':
		'AutoThemes allows researchers to skip text analytics or manual coding of open-ended data and dive straight into insights',
	'section_templates.groupsolver_approach.text.paragraph.02':
		'Built on top of the platform that already automatically cleans and quantifies Voice of the Customer (VOC) data, the new feature saves time by organizing answers into logical clusters or themes',
	'section_templates.groupsolver_approach.text.paragraph.03':
		"GroupSolver delivers more accurate real-time insights by removing other platforms' subjective, time-consuming, error-prone manual coding of open-ends",
	'section_templates.groupsolver_approach.text.paragraph.04':
		"Processed in real-time, researchers can fine-tune theme coding on the fly by selecting algorithm's sensitivity",
	'section_templates.groupsolver_approach.text.note':
		'Note: US Patent 9,390,404 B2 was awarded to GroupSolver in 2006 covering core technology to find consensus solutions to open-ended questions',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/Section/_components/TableOfContents/_components/TableOfContentsInput/TableOfContentsInput.js
	 */
	'contents_input.placeholder': 'Enter section name...',
	'contents_input.hide': 'Hide',

	/**
	 * TemplateBuilder Section style settings
	 * routes/_presentation/_components/TemplateBuilder/_components/SharingOptions/_components/SharingOptionsMenu/SharingOptionsMenu.js
	 */
	share_report: 'Share report',
	'share_report.link.share': 'Everyone with the link can view.',
	'share_report.link.refresh': 'Refresh link? Old link will stop working.',
	'share_report.off': 'Sharing is turned off.',
	'share_report.turn_on': 'Turn on sharing.',

	/**
	 * TemplateBuilder Section style settings
	 */
	'template_builder.style_settings.background_image': 'Background Image',
	'template_builder.style_settings.change_style': 'Change Style',
	'template_builder.style_settings.add_image': 'Add Image',
	'template_builder.style_settings.text_preview': 'Aa',
	'template_builder.style_settings.cannot_delete_tooltip':
		'Cannot delete media file used in study interview',

	/**
	 * TemplateBuilder Section settings
	 */
	'template_builder.section.delete':
		'Are you sure you want to delete this slide from the presentation?',
	'template_builder.section.delete.with_children':
		'Do you want to delete this slide together with all slides that are nested below it?',
	'template_builder.section.delete.slide_only': 'Delete this slide only',
	'template_builder.section.delete.all': 'Delete whole group',
	'template_builder.section.delete.title': 'Delete slide',
	'template_builder.settings.change_layout': 'Change Layout',
	'template_builder.settings.tabs.general': 'General',
	'template_builder.settings.tabs.row': 'Row {index}',
	'template_builder.settings.general.show_headline': 'Show Headline',
	'template_builder.settings.general.show_footer': 'Show Footer',
	'template_builder.settings.general.footnote': 'Footnote',
	'template_builder.settings.vertical_align': 'Vertical Align',
	'template_builder.settings.delete_row': 'Delete Row',
	'template_builder.settings.add_row': 'Add Row',
	'template_builder.settings.hide_sections': 'Hide sections',
	'template_builder.settings.column.move.left': 'Move left',
	'template_builder.settings.column.move.left.disabled': 'Cannot move left',
	'template_builder.settings.column.move.right': 'Move right',
	'template_builder.settings.column.move.right.disabled': 'Cannot move right',

	/**
	 * TemplateBuilder Add Chart
	 */
	'template_builder.add_chart.add_chart': 'Add Chart',
	'template_builder.add_chart.search.placeholder': 'Start searching',
	'template_builder.add_chart.question_filter.all_types': 'All Question Types',
	'template_builder.add_chart.statistics_filter.all_types': 'All Statistics Types',
	'template_builder.add_chart.chart_type': 'Chart Type',
	'template_builder.add_chart.starting_segment': 'Starting Segment',
	'template_builder.add_chart.single_question': 'Single Question',
	'template_builder.add_chart.fielding_statistics': 'Fielding Statistics',
	'template_builder.add_chart.respondent_engagement.description':
		'Respondent statistics from the study collection.',
	'template_builder.add_chart.terminates_report': 'Terminates report',
	'template_builder.add_chart.terminates_report.description':
		'Detailed respondent termination statistics.',
	'template_builder.add_chart.overquota_report': 'Overquotas report',
	'template_builder.add_chart.overquota_report.description': 'Detailed quota-triggered statistics.',
	'template_builder.add_chart.segment_statistics_report': 'Segment statistics report',
	'template_builder.add_chart.segment_statistics_report.description':
		'Detailed segment statistics.',

	/**
	 * TemplateBuilder Chart Editor
	 */
	'template_builder.chart_editor.chart_options': 'Chart Options',
	'template_builder.chart_editor.finish_editing': 'Finish Editing',
	'template_builder.chart_editor.filter': 'Filter',
	'template_builder.chart_editor.filter_themes': 'Filter themes',
	'template_builder.chart_editor.filter.tooltip': 'No more options for filtering available.',
	'template_builder.chart_editor.add_filter': 'Add Filter',
	'template_builder.chart_editor.no_filters': 'Set conditions to filter out themes or answers.',
	'template_builder.chart_editor.no_filters_themes': 'Set conditions to filter out themes.',
	'template_builder.chart_editor.is': 'Is',
	'template_builder.chart_editor.is_not': 'Is not',
	'template_builder.chart_editor.contains': 'Contains',
	'template_builder.chart_editor.does_not_contain': 'Does not contain',
	'template_builder.chart_editor.is_empty': 'Is empty',
	'template_builder.chart_editor.is_not_empty': 'Is not empty',
	'template_builder.chart_editor.is_not_unthemed': 'Is not unthemed',
	'template_builder.chart_editor.is_unthemed': 'Is unthemed',
	'template_builder.chart_editor.is_equal': '=',
	'template_builder.chart_editor.greater': '>',
	'template_builder.chart_editor.lesser': '<',
	'template_builder.chart_editor.greater_equal': '>=',
	'template_builder.chart_editor.lesser_equal': '<=',
	'template_builder.chart_editor.in_range': 'In range',
	'template_builder.chart_editor.not_in_range': 'Not in range',
	'template_builder.chart_editor.value': 'Value',
	'template_builder.chart_editor.support_strength': 'Support strength',
	'template_builder.chart_editor.segment_support_strength': '{segmentLabel} support strength',
	'template_builder.chart_editor.correlation': 'Correlation',
	'template_builder.chart_editor.option_code': 'Option code',
	'template_builder.chart_editor.average_value': 'Average value',
	'template_builder.chart_editor.remove_chart.title': 'Remove Chart',
	'template_builder.chart_editor.remove_text.title': 'Remove Text',
	'template_builder.chart_editor.remove_chart.message': 'This action removes chart from the slide.',
	'template_builder.chart_editor.remove_text.message':
		'This action removes text block from the slide.',

	// predefined charts
	'template_builder.add_chart.charts.answers.all': 'All answers',
	'template_builder.add_chart.charts.answers.above_50': 'Answers above 50% support',
	'template_builder.add_chart.charts.answers.top_5': 'Top 5 answers',
	'template_builder.add_chart.charts.answers.top_20': 'Top 20 answers',

	'template_builder.add_chart.charts.options.all': 'All options',
	'template_builder.add_chart.charts.options.above_50': 'Options above 50%',
	'template_builder.add_chart.charts.options.top_10': 'Top 10 options',

	'template_builder.add_chart.charts.group_by.options': 'Group by options',
	'template_builder.add_chart.charts.group_by.attributes': 'Group by attributes',

	'template_builder.add_chart.intellisegment.axes': '{count} axes',

	'template_builder.add_chart.charts.excude_unthemed_answers': 'Exclude unthemed answers',

	'template_builder.add_chart.charts.create': 'Create',

	/**
	 * TemplateBuilder ChartEditor - Data
	 */
	'template_builder.chart_editor.chart_data': 'Chart Data',
	'template_builder.chart_editor.add_segment': 'Add segment',
	'template_builder.chart_editor.remove_segment_message':
		'Segment {label} will be removed from Chart Data table',
	'template_builder.chart_editor.change_segment_title': 'Change Segment',
	'template_builder.chart_editor.edit_themes': 'Edit themes',
	'template_builder.chart_editor.edit_themes_tooltip':
		'Please save or discard your changes on the chart before editing themes.',

	'template_builder.chart_editor.copy_to_clipboard.response':
		'Response: a normalized statement derived from one or more verbatim responses given by respondents.',
	'template_builder.chart_editor.copy_to_clipboard.answer':
		'Answer: a representative response that embodies the meaning of a group of responses.',
	'template_builder.chart_editor.copy_to_clipboard.expected_support':
		'Expected support: estimated percentage of the sample that would agree with an answer.',
	'template_builder.chart_editor.copy_to_clipboard.theme':
		'Theme: a group of answers with similar meaning.',
	'template_builder.chart_editor.copy_to_clipboard.theme_support':
		'Theme support: estimated percentage of the sample that would agree with a theme.',

	/**
	 * TemplateBuilder ChartEditor - Data - SegmentBuilder
	 */
	'template_builder.segment_builder.new_segment': 'New segment',
	'template_builder.segment_builder.learn_about': 'Learn about segments',
	'template_builder.segment_builder.untitled': 'Untitled segment',
	'template_builder.segment_builder.remove_message':
		'This will remove segment and all its conditions. This cannot be undone.',
	'template_builder.segment_builder.all_completes':
		'This is a default segment that represents all respondents who completed the study.',
	'template_builder.segment_builder.segment_saved': 'Segment saved',

	/**
	 * FORMIK
	 */

	/**
	 * components/_formik/_complex/StudyDesign/ChoiceOptionsMultiSelect/ChoiceOptionsMultiSelect.js
	 */
	'choice_options_multiselect.no_options_selected': 'Please select an Option',
	'choice_options_multiselect.no_languages_selected': 'Please select a language',
	'choice_options_multiselect.tooManyOptions':
		'There are more options evaluated than the current study object supports.',
	'choice_options_multiselect.tooManyLanguages': 'Respondent cannot have multiple languages.',
	'choice_options_multiselect.tooFewOptions':
		'There are less options evaluated than the minimal selection in current study object.',
	'choice_options_multiselect.segmentation_typing_tool.no_segments_selected':
		'Please select a segment',
	'choice_options_multiselect.segmentation_typing_tool.tooManyOptions':
		'Respondent cannot belong to more than one segment',

	/**
	 * panel industry options
	 */
	explicit_content: 'Explicit content',
	sensitive_content: 'Sensitive content',
	general_population: 'General population',
	websites_internet_ecommerce: 'Websites/Internet/Ecommerce',
	video_games: 'Video games',
	travel: 'Travel',
	transportation_shipping: 'Transportation/Shipping',
	toys: 'Toys',
	tobacco_smokers: 'Tobacco smokers',
	telecommunications_phone_cell_phone_cable: 'Telecommunications/Phone/Cell/Phone cable',
	sports: 'Sports',
	restaurants: 'Restaurants',
	publishing_newspaper_magazines_books: 'Publishing/Newspaper/Magazines/Books',
	politics: 'Politics',
	pets: 'Pets',
	personal_care_toiletries: 'Personal care/Toiletries',
	it_servers_databases_etc: 'IT/Servers/Databases',
	home_improvement_real_estate_construction: 'Home improvement/Real estate/Construction',
	home_entertainment_dvd_vhs: 'Home entertainment/DVD/VHS',
	home_utilities_appliances: 'Home utilities/Appliances',
	healthcare_pharmaceuticals: 'Healthcare/Pharmaceuticals',
	gambling_lottery: 'Gambling/Lottery',
	food_snacks: 'Food/Snacks',
	financial_services_insurance: 'Financial services/Insurance',
	fashion_clothing: 'Fashion/Clothing',
	entertainment_movies_music_tv_etc: 'Entertainment/Movies/Music/TV',
	electronics_computer_software: 'Electronics/Computer software',
	education: 'Education',
	beverages_non_alcoholic: 'Non-alcoholic beverages',
	beverages_alcoholic: 'Alcoholic beverages',
	beauty_cosmetics: 'Beauty/Cosmetics',
	automotive: 'Automotive',

	/**
	 * components/_scaffolding/AcceptLegal/_components/AcceptLegalDocument/AcceptLegalDocument.js
	 */
	'legal.terms.agree': 'I agree with these Terms and Conditions',
	'legal.privacy_policy.agree': 'I agree with the Privacy Policy',
	'legal.terms.have_to_accept':
		'In order to continue using GroupSolver you need to agree with updated Terms and Conditions',
	'legal.terms.header': 'Our Terms and Conditions were updated',
	'legal.terms.message': 'Please scroll down and read the Terms and Conditions carefully.',

	'legal.privacy_policy.have_to_accept':
		'In order to continue using GroupSolver you need to agree with updated Privacy Policy',

	'legal.privacy_policy.header': 'Our Privacy Policy has been updated',
	'legal.privacy_policy.message': 'Please scroll down and read the Privacy Policy carefully.',

	/**
	 * components/_scaffolding/PresentationBuilder/SelectStudies/SelectStudies.js
	 */
	n_studies_selected: '{count} {count, plural, =0 {studies} one {study} other {studies}} selected',
	select_studies: 'Select studies',
	available_studies: 'Available studies',
	selected_studies: 'Selected studies',

	/**
	 * components/_scaffolding/PresentationBuilder/SelectStudies/SelectStudies.js
	 */
	'select_studies.auto_report.add_report': 'Add report',
	'select_studies.auto_report.build_slides': 'Build slides',
	'select_studies.auto_report.build_from': 'Build slides from',
	'select_studies.auto_report.engagement-statistics': 'Engagement statistics',
	'select_studies.auto_report.outstanding-questions': 'Outstanding questions',
	'select_studies.auto_report.outstanding-questions-tooltip':
		"This section contains answers that weren't organised under blocks during the study design.",
	'select_studies.auto_report.column-chart-label': 'Column chart',
	'select_studies.auto_report.row-chart-label': 'Row chart',
	'select_studies.auto_report.charts-selected': 'charts selected',

	/**
	 * routes/_presentation/_components/TemplateBuilder/_components/PresentationColorSettings/PresentationColorSettings.js
	 */
	'presentation.color_settings.chart_color_settings': 'Chart color settings',
	'presentation.color_settings.close_ended': 'Charts displaying close ended questions',
	'presentation.color_settings.close_ended.edit': 'Edit close ended questions color set',
	'presentation.color_settings.open_ended': 'Charts displaying open ended questions',
	'presentation.color_settings.open_ended.edit': 'Edit open ended questions color set',
	'presentation.color_settings.select_existing': 'Select existing color set',
	'presentation.color_settings.save_global': 'Save to account',
	'presentation.color_settings.save.tooltip': 'Save color set changes in this presentation.',
	'presentation.color_settings.save_global.tooltip':
		'Save color set changes in this presentation and create new global color set that can be reused in other presentations.',
	'presentation.color_settings.save_global.info':
		'Global color sets can be managed in <a>Account settings</a>',
	'presentation.color_settings.select_placeholder': 'Select an existing color set',

	/**
	 * components/_scaffolding/_input/MultiSelectTextInput/MultiSelectTextInput.js
	 */
	'multi_select_input.placeholder': 'Enter keywords to start searching...',

	/**
	 * routes/Presentations/_components/PresentationsList/PresentationsList.js
	 */
	'presentation.create': 'Create presentation',
	'presentation.default.name': 'Untitled report',

	'presentations_list.sort_options.name': 'Sort by name',
	'presentations_list.sort_options.create_date': 'Sort by date created',
	'presentations_list.table.studies': 'Studies',
	'presentations_list.sort_options.data_update_date': 'Sort by last modified',

	'presentations_list.table.last_modified': 'Last modified',
	'presentations_list.table.created': 'Created',
	'presentations_list.table.number_of_slides': '# of slides',
	'presentations_list.table.empty': 'Empty',
	'presentations_list.table.sharing': 'Sharing',
	'presentations_list.table.public': 'Public',
	'presentations_list.table.private': 'Private',

	/**
	 * routes/_admin/Legal/Legal.js
	 */
	'legal.admin.manage': 'Manage legal documents',
	'legal.admin.description':
		'Updating Terms and Conditions or Privacy Policy active date will display Terms and Conditons or Privacy Policy to all Dashboard Users. Users will not be able to use Dahsboard wouthout accepting Terms and Conditions or Privacy Policy',
	'legal.admin.current.terms': 'Current version of Terms and Conditions can be viewed here',
	'legal.admin.current.privacy_policy': 'Current version of Privacy Policy can be viewed here',

	/**
	 * routes/_admin/Legal/_components/SetLegalDocumentDate/SetLegalDocumentDate.js
	 */
	'legal.privacy_policy.active_date': 'Privacy Policy active from',
	'legal.terms.active_date': 'Terms and Conditions active from',

	/**
	 * credit card
	 */
	add_card: 'Add card',
	change_card: 'Change card',
	credit_card_expiration: '{month}/{year}',
	credit_card_number: '•••• {number}',
	credit_card_saved_card_number: 'Saved card •••• {number}',
	'credit_card.delete': 'Delete Card?',
	'credit_card.delete.message': 'Are you sure you want to delete the saved card?',
	'credit_card.expired': 'Your saved card is expired.',
	'credit_card.not_account_admin': "You don't have permissions to edit payment methods.",
	'credit_card.remove.title': 'Remove credit/debit card info?',
	'credit_card.remove': 'Remove card',
	'credit_card.update_or_change': 'Update/Change credit/debit card info?',
	'credit_card.update': 'Update card',
	'credit_card.add_credit_or_debit_card': 'Add credit/debit card info?',

	/**
	 * credit card stripe errors
	 */
	'credit_card.stripe_error.setup_intent_authentication_failure':
		'We are unable to authenticate your payment method. Please choose a different payment method or try again.',
	'credit_card.stripe_error.incomplete_cvc': 'Your card security code is incomplete.',
	'credit_card.stripe_error.invalid_cvc': 'Your card security code is invalid.',
	'credit_card.stripe_error.incomplete_number': 'Your card number is incomplete.',
	'credit_card.stripe_error.incorrect_number': 'Your card number is incorrect.',
	'credit_card.stripe_error.invalid_expiry_year': 'Your card expiration year is incorrect',
	'credit_card.stripe_error.default': "There's been an unknown problem with your credit card.",

	/**
	 * existing templates
	 */
	'template.concept_testing.short_description': 'Test a new product concept or a creative asset.',

	'template.ideation.short_description': 'Crowd-source new ideas to solve a tough problem.',

	'template.brand_positioning.short_description':
		'Find out how your brand measures up to competitors.',

	'template.willingness_to_pay.short_description':
		'Understand how much customers will pay for your product or service.',

	'template.nps.short_description':
		'Discover why your customers will recommend your product or service.',

	'template.product_naming.short_description':
		'Enlist the crowd to find a name for your new product or service.',

	'template.employee_satisfaction.short_description':
		'Hear actionable feedback from your employees.',

	'template.customer_journey.short_description': 'Explore the mindset of your target audience.',

	'template.scratch.short_description': 'Build your own study.',
	'template.post_analytics.short_description': 'Upload and analyze existing data.',

	/**
	 * layouts/_components/TopBar/TopBar.js
	 */
	'top_bar.dropdown.profile_settings': 'Profile settings',
	'top_bar.dropdown.admin_dashboard': 'Admin dashboard',
	'top_bar.dropdown.terms_and_privacy': 'Terms and privacy',
	'top_bar.dropdown.sign_out': 'Sign out',
	'top_bar.audience': 'Audience',
	'top_bar.oeq_manager': 'AI Open-End™ manager',
	'top_bar.import_data': 'Import data',
	'top_bar.tooltip_message': 'Add questions to study interview to continue',
	'top_bar.tooltip_message_open_answer': 'You have no AI Open-End™ questions in your interview.',
	'top_bar.template_design': 'Template design',

	/**
	 * layouts\SplitLayout\_components\PersistentNotificationManager\_components\PersistentNotification
	 */
	'persistnet_notification.verify': 'Please, <a>verify your payment here.</a>',
	'persistnet_notification.not_successful': 'Payment not successful. <a>Please, try again.</a>',

	/**
	 * layouts/_components/TopBar/_components/ProfileSettingsOverlay/_components/PersonalDetailsTab/PersonalDetailsTab.js
	 */
	'personal_details.avatar_description': 'Profile photos make team collaboration easier.',
	'personal_details.upload_photo': 'Upload photo',
	'personal_details.display_name_description':
		'You will be referred as {displayName} in comments and notifications.',
	'personal_details.password_successfully_changed': 'Password has been changed successfully!',
	'personal_details.choose_strong_password': 'Choose a strong password',
	'personal_details.enter_password': 'Enter your password',
	'personal_details.old_password_wrong': 'Old password is wrong',

	/**
	 * layouts/SplitLayout/_components/NoAccountsComponent/_components/NoAccounts/NoAccounts.js
	 */
	'no_accounts.title': 'No company account',
	'no_accounts.subtitle':
		'There is no company account assigned to you. Contact us for more information.',

	/**
	 * TranslateStudyOverlay
	 */
	'backend_translate.study_translate.wrong_number_of_columns_3':
		'Uploaded file has to have 3 columns.',
	'backend_translate.study_translate.worksheet_count': 'Cannot process file with multiple sheets.',

	/**
	 * product tours
	 */
	'product_tours.cannot_save_changes': 'Cannot save changes in product tour study',
	'product_tours.study.cannot_delete': 'Product tour studies cannot be deleted',
	'product_tours.study.cannot_launch': 'Product tour studies cannot be launched',
	'product_tours.report.cannot_delete': 'Reports in product tour studies cannot be deleted',

	/**
	 * Password strength conditions
	 */
	[PASSWORD_STRENGTH.ONE_LOWERCASE]: 'One lowercase letter',
	[PASSWORD_STRENGTH.ONE_UPPERCASE]: 'One uppercase letter',
	[PASSWORD_STRENGTH.ONE_NUMBER]: 'One number',
	[PASSWORD_STRENGTH.MINIMUM_CHARACTERS]: '8 characters minimum',
	[PASSWORD_STRENGTH.PASSWORDS_MATCH]: 'Passwords match',

	/**
	 * routes
	 */
	[PATHS.ADMIN_ACCOUNT_ASSIGN_STUDY]: 'Manage Studies',
	[PATHS.ADMIN_ACCOUNT_ASSIGN_USER]: 'Manage Users',
	[PATHS.ADMIN_ACCOUNT_LIST]: 'Account Management',
	[PATHS.ADMIN_CREATE_ACCOUNT]: 'Create Account',
	[PATHS.ADMIN_CREATE_TEST_USER]: 'Create test user',
	[PATHS.ADMIN_CREATE_USER]: 'Create user',
	[PATHS.ADMIN_HOME]: 'Admin Page',
	[PATHS.ADMIN_MANAGE_DOCUMENTS]: 'Manage Legal Documents',
	[PATHS.ADMIN_STUDY_DETAIL]: 'Study Detail',
	[PATHS.ADMIN_STUDY_LIST]: 'Study Management',
	[PATHS.ADMIN_UPDATE_ACCOUNT]: 'Update account',
	[PATHS.ADMIN_UPDATE_USER]: 'Update user',
	[PATHS.ADMIN_USER_ASSIGN_ACCOUNT]: 'Manage Access',
	[PATHS.ADMIN_USER_LIST]: 'User Management',

	[PATHS.ACCOUNT]: 'Account settings',
	[PATHS.ALLOCATION_ANSWERS]: 'Allocation Answers',
	[PATHS.ANSWER_MANAGER]: 'Answer Manager',
	[PATHS.CHOICE_ANSWERS]: 'Choice Answers',
	[PATHS.COMMUNITY]: 'Community',
	[PATHS.CONSENSUS]: 'Consensus',
	[PATHS.DASHBOARD]: 'Studies',
	[PATHS.DATA_DOWNLOADER]: 'Data Downloader',
	[PATHS.DATA_IMPORTER]: 'Data Importer',
	[PATHS.FREE_TEXT]: 'FreeText',
	[PATHS.HOME]: 'Home',
	[PATHS.IDEACLOUD]: 'IdeaCloud',
	[PATHS.IDEACLUSTER]: 'IdeaCluster',
	[PATHS.INBOX]: 'Inbox',
	[PATHS.INTELLISEGMENT]: 'Intellisegment',
	[PATHS.LIBRARIES]: 'Libraries',
	[PATHS.LIVESLIDES]: liveSlides,
	[PATHS.MATRIX]: 'Matrix',
	[PATHS.NEW_IDEACLOUD]: 'New IdeaCloud',
	[PATHS.OPEN_ANSWERS]: 'AI Open-End',
	[PATHS.ORGANIZATION]: 'Organization',
	[PATHS.PANELS]: 'Panels & statistics',
	[PATHS.PRESENTATION_LIST]: liveSlides,
	[PATHS.QUANT_QUAL]: 'QuantQual',
	[PATHS.SUNBURST]: 'Sunburst',
	[PATHS.RANKING_ANSWERS]: 'Ranking Answers',
	[PATHS.REPORT]: 'Reports',
	[PATHS.RESPONDENT_MANAGER]: 'Respondent Manager',
	[PATHS.SEGMENTS]: 'Segments',
	[PATHS.STUDY_DESIGN]: 'Study design',
	[PATHS.SUMMARY]: 'Summary',
	[PATHS.UPGRADE]: 'Upgrade',

	/**
	 * default flow
	 */
	flow_message_module_disclaimer_text:
		'In this study, you may be asked to evaluate opinions expressed by others. Their answers will be provided to you as written and they will NOT be edited for content.',
	flow_message_module_proceed_text:
		'By clicking "I Agree" you acknowledge and agree to participate in the study.',
	flow_button_module_disclaimer_text: 'I agree',
	flow_message_module_end_study_companion_text_conclude: 'This concludes our study.',
	flow_terminate_overquota_message:
		'Thank you for your interest. Unfortunately, we are looking for respondents with a different profile.',
	flow_message_module_end_study_companion_text_participation: 'Thank you for your participation!',
	flow_message_module_powered_by:
		'This survey is powered by [GroupSolver](https://groupsolver.com) - an intelligent QuantQual survey platform. Start building AI-powered surveys [for free today](https://groupsolver.com/pricing-plans/)!',

	/** Color picker */
	'color_picker.placeholder': 'Hello there!',
	'color_picker.range_info':
		'We support only colors that work for white text. Your hexcode was changed to closest\nmatch from our range.',

	/**
	 * BACKEND
	 */
	'backend_translate.error_message_report_slide_central_statement':
		'The central answer for this slide no longer exists. Please select a new one.',
	'backend_translate.error_message_report_slide_segments':
		'Segments selected in slide settings no longer exist. Please change the slide settings.',
	'backend_translate.error_message_report_slide_invalid_segments':
		'Segments selected in slide settings are invalid',
	'backend_translate.error_message_report_slide_study_object':
		'The study object selected in slide settings no longer exists. Please change the slide settings',

	'backend_translate.multi_factor_auth_required': 'Multi-factor auth required!',
	'backend_translate.invalid_credentials': 'Invalid credentials!',
	'backend_translate.too_many_request': `Access to this account has been temporarily disabled due to many failed login attempts. Please try again later or {contactUs}`,
	'backend_translate.try_again_later': tryAgainLater,

	/**
	 * COUNTRY NAMES
	 */
	'united_kingdom-northern_ireland': 'United Kingdom - Northern Ireland',

	/**
	 * src/components/_charts/types/QuantQual/QuantQual.js
	 */

	'quant_qual.title': 'Answer your burning questions with QuantQual™',
	'quant_qual.title_access_granted': 'Welcome to QuantQual™',
	'quant_qual.description':
		'Which specific complaints drag down your NPS? Which brand attributes make your customers most likely to purchase your new product? Our intelligent technology connects the <strong>what</strong> to the <strong>why</strong> by estimating the impact of open-ended customer answers on your most important business metrics.',
	'quant_qual.description_access_granted':
		'Now you have access to the QuantQual™. To get started, select data and make your first chart. So simple!',
	'quant_qual.still_curious': 'Still curious?',
	'quant_qual.average_score': 'Average score',
	'quant_qual.number_of_respondents': '# of respondents',
	'quant_qual.number_of_respondents_long': 'Number of respondents (N)',
	'quant_qual.estimated_score': 'Estimated score',
	'quant_qual.estimate_for_agrees': 'Estimate for agrees',
	'quant_qual.estimate_for_neutrals': 'Estimate for neutrals',
	'quant_qual.estimate_for_disagrees': 'Estimate for disagrees',
	'quant_qual.number_of_agrees': '# of agrees',

	'quant_qual.number_of_neutrals': '# of neutrals',
	'quant_qual.number_of_disagrees': '# of disagrees',
	'quant_qual.not_enough_agrees': 'Not enough agrees',
	'quant_qual.not_enough_neutrals': 'Not enough neutrals',
	'quant_qual.not_enough_disagrees': 'Not enough disagrees',
	'quant_qual.p_value': 'P-value',
	'quant_qual.p_value_less_than_01': 'P-value less than 0.1',
	'quant_qual.p_value_higher_than_01': 'P-value higher than 0.1',
	'quant_qual.table.answer_title': 'Answers to “{title}”',
	'quant_qual.table.themes_answers_title': 'Themes and unthemed answers from “{title}”',
	'quant_qual.table.theme_title': 'Themes from “{title}”',

	/**
	 * src/components/_charts/types/Sunburst/Sunburst.js
	 */
	'sunburst.number_of_responses': 'Nr. of responses',
	'sunburst.unthemed': 'Unthemed',
	'sunburst.incompatible':
		'This chart is limited because the study runs on an outdated version of our open-ended data model.',
	'sunburst.table_header.naming.tooltip': `
        <strong>Themes:</strong> Broader topics answers fall into.{br}
    <strong>Answers:</strong> Groups of responses having the same meaning.{br}
    <strong>Responses:</strong> Normalized statements derived from one or more verbatims.`,
	'sunburst.table_header.support.tooltip':
		'Estimated percentage of the sample that would agree with an answer or a theme.',
	'sunburst.table_header.responses_number.tooltip':
		'The number of responses that contributed to an answer or a theme.',
	'sunburst.grouped_answer':
		'This group represents multiple answers combined via the AI Open-End manager.',
	/**
	 * src/routes/_study/QuantQual/_components/Selector/Selector.js
	 */
	'quant_qual_selector.analyze': 'Analyze',
	'quant_qual_selector.for': 'for',
	'quant_qual_selector.create_chart': 'Create chart',
	'quant_qual_selector.refresh_chart': 'Refresh chart',
	'quant_qual_selector.no_choice_options': 'There are no choice questions in this study',
	'quant_qual_selector.no_open_ended_options': 'There are no AI Open-End questions in this study',

	/**
	 * src/routes/_study/QuantQual/_components/Loading/Loading.js
	 */
	'quant_qual_loading.please_wait': 'Please wait. Your report is loading.',
	'quant_qual_loading.it_might_take_a_while':
		'It might take us a little while to crunch the numbers. You can leave this page and check back later.',

	/**
	 * src/routes/_study/QuantQual/_components/SortAndFilter/SortAndFilter.js
	 */
	'quant_qual.sort_and_filter.answers_with_n':
		'{answersLimit, plural, one {answer} other {answers}} with N greater than',
	'quant_qual.sort_and_filter.themes_with_n':
		'{answersLimit, plural, one {theme} other {themes}} with N greater than',
	'quant_qual.sort_and_filter.respondents_sorted_by':
		'{respondentCount, plural, one {respondent} other {respondents}}, sorted by',

	'quant_qual.highlight_significant_datapoints':
		'Highlight data points with P-value higher than 0.1',
	'quant_qual.n_filter_value_label': 'Show only data points with N greater than:',
	'quant_qual.default_title': 'Estimated quant question score per answers to qual question',
	'quant_qual.quant': 'Quant',
	'quant_qual.qual': 'Qual',

	/**
	 * src/components/_scaffolding/QuantQualOverlay/_components/QuantQualOverlayContent/_components/QuantQualOverlayContentBanner/QuantQualOverlayContentBanner.js
	 */
	'quant_qual.banner.heading': 'Answer your burning questions with QuantQual',
	'quant_qual.banner.text':
		'Which features did your biggest promoters suggest? What topics concern your customers the most? Our unique tech lets you connect the what to the why by estimating the impact of open-ended answers to quantitative data.',
	'quant_qual.banner.anchor': 'How does QuantQual work?',

	/**
	 * src/components/_scaffolding/QuantQualOverlay/_components/QuantQualOverlayContent/_components/QuantQualSlideCreator/QuantQualSlideCreator.js
	 */
	'quant_qual.creator.heading': 'Create new QuantQual report',
	'quant_qual.creator.select_quant_question': 'Select quant question',
	'quant_qual.creator.select_qual_question': 'Select qual question',
	'quant_qual.creator.create': 'Create report',

	/**
	 * src/components/_scaffolding/QuantQualOverlay/_components/QuantQualOverlayContent/_components/QuantQualSearchReports/QuantQualSearchReports.js
	 */
	'quant_qual.search_reports.missing_quant_module': 'Quant module no longer exists',
	'quant_qual.search_reports.missing_qual_module': 'Qual module no longer exists',

	/**
	 * src/components/_scaffolding/QuantQualOverlay/_components/QuantQualOverlayContent/_components/QuantQualSearchReports/_components/SearchReportsInput/SearchReportsInput.js
	 */
	'quant_qual.search_reports_input.placeholder': 'Search reports',

	/**
	 * src/components/_scaffolding/QuantQualOverlay/_components/QuantQualOverlayContent/_components/QuantQualSearchReports/_components/ListedReport/ListedReport.js
	 */
	'quant_qual.listed_report.description':
		'Estimated score of {quantQuestionLabel} per answers to {qualQuestionLabel}',

	/**
	 * quantqual copy to clipboard
	 */
	'quant_qual.copy.n.description':
		'N: Number of respondents who evaluated the answer, or the theme.',
	'quant_qual.copy.average_score.description':
		'Average score: Average quant question score rated by respondents who evaluated an individual answer from the qual question.',
	'quant_qual.copy.estimated_score.description':
		'Estimated score: Quant question score estimated for a subset of respondents who agreed, disagreed, or were neutral with the qual answer, or the theme.',
	'quant_qual.copy.p_value.description':
		'P-value: The probability of obtaining test results at least as extreme as a result observed, assuming the null hypothesis is correct.',
	'quant_qual.copy.agrees.description':
		'Agrees: Respondents who agreed with the answer or the theme.',
	'quant_qual.copy.disagrees.description':
		'Disagrees: Respondents who disagreed with the answer or the theme.',
	'quant_qual.copy.neutrals.description':
		'Neutrals: Respondents who were neutral with the answer or the theme.',
	'quant_qual.copy.quant_question': 'Quant question: {questionName} "{questionMessage}"',
	'quant_qual.copy.qual_question': 'Qual question: {questionName} "{questionMessage}"',
	'quant_qual.copy.answer.description':
		'Answer: A representative response that embodies the meaning of a group of responses.',
	'quant_qual.copy.theme.description': 'Theme: A group of answers with similar meaning.',
	'quant_qual.copy.segment': 'Segment: {segmentLabel}',
	'quant_qual.copy.n.label': 'N',
	'quant_qual.copy.agrees.label': 'agrees',
	'quant_qual.copy.disagrees.label': 'disagrees',
	'quant_qual.copy.neutrals.label': 'neutrals',
	'quant_qual.copy.estimated_score.evaluation': 'Estimated score - {position}',
	'quant_qual.copy.n.evaluation': 'N - {position}',
	'quant_qual.copy.p_value.evaluation': 'P value - {position}',
	'quant_qual.copy.theme_or_answer': 'Theme/unthemed answer',

	/**
	 * copy to clipboard
	 */
	ALLOCATION_OPTION: 'Option:\tOption presented to respondent.',
	RANKING_OPTION: 'Option:\tOption presented to respondent.',
	ANSWER: 'Answer:\tA representative response that embodies the meaning of a group of responses.',
	ANSWER_OPINION:
		'Agree, neutral, disagree:\tThe number of completes that agreed, disagreed, or were indifferent with a particular answer.',
	AVERAGE_VALUE: 'Average:\tAverage value entered by respondents.',
	COMPLETES:
		'Completes:\tNumber of respondents that expressed their stance towards a particular answer.',
	CONFIDENCE_INTERVAL: 'Confidence interval:\tRange of expected support with 90% confidence.',
	CORRELATION: "Correlation:\tAn answer's statistical association to the central answer.",
	CHOICE_ANSWER: 'Answer:\tAnswer selected by respondent.',
	EXPECTED_SUPPORT:
		'Expected support:\tEstimated percentage of the sample that would agree with an answer.',
	FREE_TEXT_ANSWER: 'Answer:\tAnswer entered by respondent.',
	FREE_TEXT_COMPLETES: 'Completes:\tNumber of completes that entered the answer',
	NUMBER_OF_ANSWERS: '# of answers:\tNumber of answers grouped within a theme.',
	OPTION: 'Option:\tOption selected by respondent.',
	PERCENTAGE: 'Percentage:\tA share of respondents that picked a particular answer.',
	STATEMENT_THEME_NAME: 'Answer theme:\tName of a theme to which the answer is assigned.',
	SUPPORT: 'Support:\tThe lower 90% credible interval on the relative support.',
	SUPPORT_STRENGTH: 'Support Strength:\tThe lower 90% credible interval on the relative support.',
	THEME: 'Theme:\tA group of open-ended answers with subject commonalities.',
	THEME_SUPPORT:
		'Theme support:\tEstimated percentage of the sample that would agree with a theme.',

	/**
	 * src/components/reports/ChartEditor/_components/Data/_components/QuantQualQuestionsSettings/QuantQualQuestionsSettings.js
	 */
	'quant_qual.change_questions.quant_label': 'Quant:',
	'quant_qual.change_questions.qual_label': 'Qual:',
	'quant_qual.change_questions.select_questions':
		'Please select quant and qual questions to submit changes',

	/**
	 * src/components/reports/ReportContent/_components/ReportNoContentToShow/ReportNoContentToShow.js
	 */
	'report_no_content_to_show.empty_before_processing_quant_qual':
		'There is not enough data collected to show a QuantQual report for this question pair.',
	'report_no_content_to_show.empty_after_processing':
		'Try broadening your filters. Alternatively, review Advanced settings in Chart Options.',

	/**
	 * routes/_study/Panels/_components/RecruitRespondents/_components/CommunityPanelForm/_components/CommunityEmailSettings/CommunityEmailSettings.js
	 */
	'community_filter.email_column': 'Community column with email addresses',
	'community_filter.email_column_error': 'Community column with email addresses is not set',
	'community_filter.name_column': "Community column with respondents' names",
	'community_filter.email_subject': 'Subject',
	'community_filter.email_subject.default': ' Invitation to participate in a survey',
	'community_filter.email_template': 'Text',
	'community_filter.email_template.default':
		'You have been selected to participate in a survey. Your feedback is important to us and will help us improve our products and services. Thank you for your time and participation.',
	'community_filter.email_button_label': 'Open survey button label',
	'community_filter.email_button_label.default': 'Take survey',
	'community_filter.email_headline': 'Headline',
	'community_filter.email_headline.default': ' Invitation to participate in a survey',
	'community_filter.preview_mode': 'Preview mode',
	'community_filter.tooltip.label': 'Some formatting allowed',
	'community_filter.tooltip.info': 'You can format email content using markdown.',
	'community_filter.tooltip.instruction': 'Use following notation:',
	'community_filter.tooltip.bold': 'Bold',
	'community_filter.tooltip.italic': 'Italic',
	'community_filter.tooltip.link': 'Link text',
	'community_filter.update_conditions': 'Update conditions',

	/**
	 * routes/_study/Panels/_components/RecruitRespondents/RecruitRespondents.js
	 */
	'recruit_respondents.community_file_exists_tooltip':
		'Community file exists in study. Can not create non-community panel.',
	'recruit_respondents.community_file_must_exist_tooltip':
		'Community file must exist in study to create community panel.',

	/**
	 * src\helpers\studySolverLink.js
	 */
	'community.preview_actions_disabled_tooltip':
		'Preview actions are disabled on studies with community file uploaded.',

	/**
	 * src\layouts\_components\TopBar\_components\StudyTopBar\_components\StudyStateActions\StudyStateActions.js
	 */
	'community.only_community_panels_allowed':
		'Studies with community file must only contain community panels. Check audience tab for more information.',
	'community.must_contain_at_least_one_community_panel':
		'Study with community must contain at least one community panel. Create community panel from audience tab.',
	'community.all_existing_community_panels_must_be_enabled':
		'All existing community panels must be enabled. Delete disabled panels from audience tab.',
	'non_community.no_community_panels_allowed': `Studies without community file can't have community panels. Delete community panels from audience tab.`,
	'non_community.must_contain_at_least_one_panel':
		'Study must contain at least one panel. Create panel from audience tab.',
	'non_community.all_existing_panels_must_be_enabled':
		'All existing panels must be enabled. Delete disabled panels from audience tab.',

	/**
	 * src/routes/_study/Panels/_components/Panel/_components/PanelEmailPreview/PanelEmailPreview.js
	 */
	'panel_email_preview.headline': 'Select users:',
	'panel_email_preview.send_button_title': 'Send to selected users',
	'panel_email_preview.send_emails_success': 'Preview email invitations sent successfully',

	/**
	 * Heatmap gradient names
	 */
	[HEATMAP_GRADIENT_NAMES.COLOR_SPECTRUM]: 'Color spectrum',
	[HEATMAP_GRADIENT_NAMES.BLACK_AQUA_WHITE]: 'Black - Aqua - White',
	[HEATMAP_GRADIENT_NAMES.INCANDESCENT]: 'Incandescent',

	/**
	 * src/components/reports/ChartEditor/_components/ChartOptions/_components/HeatmapQuestion/HeatmapQuestion.js
	 */
	'heatmap_question.gradient_name_label': 'Gradient',
	'heatmap_question.blur_label': 'Blur: {value}%',
	'heatmap_question.opacity_label': 'Opacity: {value}%',
	'heatmap_question.radius_label': 'Radius: {value}',

	/**
	 * src/routes/_presentation/_components/TemplateBuilder/_components/Section/_components/Row/_components/Column/_components/Cell/_components/SegmentStatistics/SegmentStatistics.js
	 */
	'segment_statistics.title': 'Segment statistics',

	/**
	 * segmentation typing tool
	 */
	'segmentation_typing_tool.delete.title': 'Delete segmentation typing tool',
	'segmentation_typing_tool.delete.message':
		'Are you sure you want to delete the segmentation typing tool? This cannot be reversed',
	'segmentation_typing_tool.no_modules': 'There are no modules to base segments on yet',
	'segmentation_typing_tool.add_rule': 'Add rule',
	'segmentation_typing_tool.list_empty': 'Create new segmentation typing tool to continue',
	'segmentation_typing_tool.not_found': 'Segmentation typing tool not found',
	'segmentation_typing_tool.url_not_valid': 'Segmentation typing tool url is not valid.',
	'segmentation_typing_tool.deleted': 'Segmentation typing tool no longer exists.',
	'segmentation_typing_tool.add_segments': 'Add segments to continue',
	'segmentation_typing_tool.tooltip.segments':
		'Segments\n\nCreate individual segments to be included in the typing tool.\n\nAfter segments are created and individual rules (or linear models) for each segment are built, typing tool will evaluate model fit for each segment and assign each respondent to the segment that fits the best.',
	'segmentation_typing_tool.tooltip.rules':
		"Rules\n\nCreate any number of rules for the typing tool that will calculate a numeric value for each segment for every respondent.\n\nBuilding rules is equivalent to creating individual linear models for all segments. Each model can include a constant and a set of numeric factors for each model variable.\n\nFor each respondent, the typing tool will calculate the total value for each segment's linear model and will assign respondent to the segment corresponding to the model with the highest calculated value.",
	'segmentation_typing_tool.tooltip.factors':
		'Factors\n\nFactors are coefficients in linear equations that make up the typing tool model. Type in the factors (coefficients) that apply to this rule (variable) for each segment.',
	'segmentation_typing_tool.tooltip.conditions':
		'Conditions\n\nUse condition builder to define the typing tool model variable, to which the factors (model coefficients) will be applied. If the condition is met for a respondent, the linear factor (coefficient) will be multiplied by “1” and it will be added to the total sum of the linear equation. If the condition is not met, the linear factor will be multiplied by “0” and it will not be added to the sum of the linear equation.\n\nConditions can be defined from a single model variable or from combining multiple variables.',
	'segmentation_typing_tool.tooltip.rule_name':
		'Rule name\n\nAn individual rule in the typing tool is equivalent to a variable in a linear model. Give each rule a unique name that helps you remember which variable this rule applies to.',
	'segmentation_typing_tool.tooltip.constants':
		'Constants\n\nAs you build rules for your segments - i.e., linear models that the typing tool will estimate for each segment - you can add a constant to that model. If your segmentation model has no constant, type in “0”',
	'segmentation_typing_tool.tooltip.info_banner':
		'<bold>Assign respondents to predefined segments.</bold> Typing tool allows you to apply an existing segmentation model to the respondent data you are collecting. Build linear models for predefined segments and let the typing tool calculate model values for your respondents and assign them to appropriate segments.',

	'validation.required': '{name} is required',
	'validation.valid_email': '{name} must be a valid email address',
	'validation.valid_email_domain': '{name} domain is not valid',
	'validation.too_short': '{name} is too short',
	'validation.too_long': '{name} is too long',

	/**
	 *src/routes/_account/Account/_components/PlanTiles/_components/Plan/_components/PlanPerk/PlanPerk.js
	 */
	'plans_and_billing.perks.access.title': 'Access',
	'plans_and_billing.perks.access.users':
		'{users} {users, plural, =0 {users} one {user} other {users}}{br}+ additional users at {additionalUsersAt, number, ::! K currency/USD}/month{br}+ {accessForMonths, number} month data access',

	'plans_and_billing.perks.surveys.title': 'Surveys',
	'plans_and_billing.perks.surveys.unlimited_surveys': 'Unlimited surveys',
	'plans_and_billing.perks.surveys.completes_per_year': '{completes, number} completes per year',
	'plans_and_billing.perks.surveys.completes_per_year.tooltip.title': 'Survey size:',
	'plans_and_billing.perks.surveys.completes_per_year.tooltip.up_to_completes_per_study':
		'Up to {completes} completes per study',
	'plans_and_billing.perks.surveys.completes_per_year.tooltip.over_completes_monthly':
		'Over {completes} completes monthly',
	'plans_and_billing.perks.surveys.one_project': 'One project with multiple surveys',
	'plans_and_billing.perks.surveys.one_project.tooltip.great_for':
		'Great for multi-language studies, trackers, market segmentations and others.',
	'plans_and_billing.perks.surveys.up_to_completes_per_survey':
		'Up to {completes, number} completes per survey',

	'plans_and_billing.perks.research_services.title': 'Research services',
	'plans_and_billing.perks.research_services.research_team_access':
		// eslint-disable-next-line no-template-curly-in-string
		'Access to our research team starting at ${perProject, number} per project',
	'plans_and_billing.perks.research_services.research_team_hours_included':
		'10 research hours included{br}+ Additional support from $999',
	'plans_and_billing.perks.research_services.tooltip.title': 'Research services:',
	'plans_and_billing.perks.research_services.tooltip.hours_coverage':
		'{hours} included {hours, plural, =0 {hours} one {hour} other {hours}} typically cover study design, programming, collection, monitoring and LiveSlides™ report.',

	'plans_and_billing.perks.features.title': 'Features',
	'plans_and_billing.perks.features.live_slides': 'LiveSlides™',
	'plans_and_billing.perks.features.live_slides.tooltip.presentation_solution':
		'Built-in live presentation solution.',
	'plans_and_billing.perks.features.custom_logo_and_colors': 'Custom survey logo and colors',
	'plans_and_billing.perks.features.everything_in_subscription': 'Everything in subscription',
	'plans_and_billing.perks.features.multi_language_studies': 'Multi-language studies',
	'plans_and_billing.perks.features.brand_spark': 'BrandSpark™',
	'plans_and_billing.perks.features.brand_spark.tooltip.understand':
		'Understand how specific attributes of the brand resonate with your potential and current customers.',
	'plans_and_billing.perks.features.segmentation_analysis': 'Segmentation analysis',
	'plans_and_billing.perks.features.typing_tool': 'Typing tool',

	'plans_and_billing.perks.custom_packages.title': 'Custom packages',
	'plans_and_billing.perks.custom_packages.dedicated_success_manager': 'Dedicated success manager',
	'plans_and_billing.perks.custom_packages.customized_analytics_services':
		'Customized Analytics Services',
	'plans_and_billing.perks.custom_packages.customized_research_services':
		'Customized Research Services',
	'plans_and_billing.perks.custom_packages.access_packages': 'Access packages',
	'plans_and_billing.perks.custom_packages.access_packages.tooltip.unlimited_teams_and_users':
		'Unlimited teams and users',
	'plans_and_billing.perks.custom_packages.access_packages.tooltip.sso_support':
		'Single sign-on (SSO) support',
	'plans_and_billing.perks.custom_packages.access_packages.tooltip.customized_billing':
		'Customized billing',
	'plans_and_billing.perks.custom_packages.access_packages.tooltip.unlimited_api_access':
		'Unlimited API access',

	'plans_and_billing.perks.advanced_features.title': 'Advanced features',
	'plans_and_billing.perks.advanced_features.custom_features': 'Custom features and integrations',
	'plans_and_billing.perks.advanced_features.sso': 'Single sign-on (SSO)',
	'plans_and_billing.perks.advanced_features.unlimited_api_access': 'Unlimited API access',

	'plans_and_billing.see_all_plan_features': '+ see all plan features',
	'plans_and_billing.sign_up_now': 'Sign-up now',
	'plans_and_billing.book_a_meeting': 'Book a meeting',

	// charts AI description
	'report.ai_description.support': '{segmentLabel} support',
	'report.ai_description.score': '{segmentLabel} score',
	'report.ai_description.rank': '{segmentLabel} rank',
	'report.ai_description.matrix.group_by_row':
		'Percentage of {optionLabel} answers in answers to {questionLabel} question',
	'report.ai_description.matrix.group_by_column':
		'Percentage of question {questionLabel} in all {optionLabel} answers',
	'report.ai_description.allocation_answers':
		'Chart displays average value allocated to each option of an Allocation question.',
	'report.ai_description.choice_answers':
		'Chart displays percentual support of options of a Choice question.',
	'report.ai_description.consensus':
		'Chart displays correlation between all responses to AI Open-End question and one selected central answer to the same question.',
	'report.ai_description.free_text': 'Chart displays answers to text question.',
	'report.ai_description.ai_open_end':
		'Chart displays percentual support of answers to AI Open-End question',
	'report.ai_description.matrix_answers.group_by_row':
		'Chart displays percentual support of matrix columns in matrix rows. Matrix rows are Choice questions and matrix columns are options',
	'report.ai_description.matrix_answers.group_by_column':
		'Chart displays percentual representation of matrix rows in matrix columns. Matrix rows are Choice questions and matrix columns are options',
	'report.ai_description.ranking_answers':
		'Chart displays average rank assigned to each option of a Ranking question.',
	'report.ai_description.label': 'AI Export',

	// organization
	'organization.sso_settings': 'SSO settings',
	'organization.provider_id': 'Provider id',
	'organization.sso.set_up.info': 'Use the form to set up the SSO for your organization',
	'organization.sso.set_up.button': 'Add SSO settings',
	'organization.sso.update.info': 'SSO set up is complete. Use the form to change the settings',
	'organization.sso.update.button': 'Change SSO settings',
	'organization.sso.form.url': 'SSO url',
	'organization.sso.form.cert': 'x509Certificate',
	'organization.sso.form.entity': 'Entity id',
	'organization.sso.updated_notification': 'SSO settings changed',
	'organization.callback_url': 'Callback URL',
	'organization.change_role.admin.button': 'Change role to admin?',
	'organization.change_role.admin.message':
		'Admins can modify SSO settings and change roles of other users and admins.',
	'organization.change_role.user.button': 'Change role to user?',
	'organization.change_role.user.message':
		'Users cannot modify SSO settings or change roles of other users or admins.',

	url_validation_skip: `Add "${URL_VALIDATION_SKIP_PREFIX}" prefix to skip URL validation.\nRespondents with invalid redirect url will not be able to complete the study.`,

	/**
	 * FLOW MODULE DEFAULTS
	 */
	allocation_option: 'Allocation option',
	choice_option: 'Choice option',
	freetext_date_placeholder: 'Enter a date',
	freetext_date_inputHint: 'e.g. mm/dd/year',
	freetext_phone_placeholder: 'Enter a phone number',
	freetext_prhone_inputHint: 'e.g. 000-123-4567',
	freetext_email_placeholder: 'Enter your email',
	freetext_email_inputHint: 'e.g. my@mail.com',
	freetext_zip_placeholder: 'Enter your ZIP code',
	freetext_zip_inputHint: 'e.g. 12345',
	freetext_answer_placeholder: 'Enter your answer',
	freetext_integer_inputHint: 'e.g. 123',
	freetext_number_inputHint: 'e.g. 1.5',
	freetext_amount_placeholder: 'Enter amount',
	freetext_amount_inputHint: 'e.g. 1000.00',
	matrix_question: 'Matrix question',
	matrix_loop_question:
		'Make sure to include [[item]] in question text to present unique question for every list item',
	maxdiff_option: 'Item',
	maxdiff_best_question: 'In your opinion, which of these are the **most** important attributes?',
	maxdiff_worst_question: 'In your opinion, which of these are the **least** important attributes?',
	new_message: 'New message',
	ranking_option: 'Ranking option',

	question_default: 'Type in your question',
	none_of_these: 'None of these',
	study_languages_language_choice_message: 'What is your preferred language?',

	// used in language choice options
	ar: 'Arabic',
	zh: 'Chinese',
	da: 'Danish',
	nl: 'Dutch',
	en: 'English',
	fr: 'French',
	de: 'German',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesian',
	it: 'Italian',
	ja: 'Japanese',
	ko: 'Korean',
	pl: 'Polish',
	pt: 'Portuguese',
	ro: 'Romanian',
	ru: 'Russian',
	sk: 'Slovak',
	es: 'Spanish',
	sv: 'Swedish',
	tl: 'Tagalog',
	th: 'Thai',
	tr: 'Turkish',
	yo: 'Yoruba',

	...config.platformPreset.translations,
}
