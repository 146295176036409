/**
 * @enum {string} REPORT_TYPES
 */
export const REPORT_TYPES = {
	ALLOCATION_ANSWERS: 'ALLOCATION_ANSWERS',
	CHOICE_ANSWERS: 'CHOICE_ANSWERS',
	CONSENSUS: 'CONSENSUS',
	FREE_TEXT: 'FREE_TEXT',
	HEATMAP: 'HEATMAP',
	IDEACLOUD: 'IDEACLOUD',
	IDEACLUSTER: 'IDEACLUSTER',
	INTELLISEGMENT: 'INTELLISEGMENT',
	MATRIX: 'MATRIX',
	MAXDIFF: 'MAXDIFF',
	OPEN_ANSWERS: 'OPEN_ANSWERS',
	QUANT_QUAL: 'QUANT_QUAL',
	RANKING_ANSWERS: 'RANKING_ANSWERS',
	SUNBURST: 'SUNBURST',
	SEGMENTATION_TYPING_TOOL: 'SEGMENTATION_TYPING_TOOL',
}

/**
 * @enum {string} AI_OPEN_END_REPORTS
 */
export const AI_OPEN_END_REPORTS = [
	REPORT_TYPES.CONSENSUS,
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.IDEACLUSTER,
	REPORT_TYPES.INTELLISEGMENT,
	REPORT_TYPES.OPEN_ANSWERS,
	REPORT_TYPES.QUANT_QUAL,
	REPORT_TYPES.SUNBURST,
]

/**
 * @enum {string} AI_OPEN_END_THEME_VIEW_REPORTS
 */
export const AI_OPEN_END_THEME_VIEW_REPORTS = [
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.OPEN_ANSWERS,
	REPORT_TYPES.QUANT_QUAL,
	REPORT_TYPES.INTELLISEGMENT,
]

/**
 * @enum {string} REPORT_BLOB_TYPES
 */
export const REPORT_BLOB_TYPES = {
	ALLOCATION_STATISTICS: 'ALLOCATION_STATISTICS',
	CHOICE_STATISTICS: 'CHOICE_STATISTICS',
	FREE_TEXT_STATISTICS: 'FREE_TEXT_STATISTICS',
	HEATMAP_STATISTICS: 'HEATMAP_STATISTICS',
	MATRIX_STATISTICS: 'MATRIX_STATISTICS',
	MAXDIFF_STATISTICS: 'MAXDIFF_STATISTICS',
	OEQ_CONSENSUS: 'OEQ_CONSENSUS',
	OEQ_IDEACLUSTER: 'OEQ_IDEACLUSTER',
	OEQ_STATEMENT_SUPPORT: 'OEQ_STATEMENT_SUPPORT',
	OEQ_STATEMENT_VERBATIM: 'OEQ_STATEMENT_VERBATIM',
	OEQ_THEME_EVALUATIONS: 'OEQ_THEME_EVALUATIONS',
	OEQ_THEME_SUPPORT: 'OEQ_THEME_SUPPORT',
	QUANT_QUAL: 'QUANT_QUAL',
	RANKING_STATISTICS: 'RANKING_STATISTICS',
	SUNBURST: 'SUNBURST',
	SEGMENTATION_TYPING_TOOL_STATISTICS: 'SEGMENTATION_TYPING_TOOL_STATISTICS',
}

/**
 * @enum {string[]} REPORT_TYPES_TO_BLOB_TYPES
 */
export const REPORT_TYPES_TO_BLOB_TYPES = {
	ALLOCATION_ANSWERS: [REPORT_BLOB_TYPES.ALLOCATION_STATISTICS],
	CHOICE_ANSWERS: [REPORT_BLOB_TYPES.CHOICE_STATISTICS],
	CONSENSUS: [REPORT_BLOB_TYPES.OEQ_CONSENSUS],
	FREE_TEXT: [REPORT_BLOB_TYPES.FREE_TEXT_STATISTICS],
	HEATMAP: [REPORT_BLOB_TYPES.HEATMAP_STATISTICS],
	IDEACLOUD: [REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT],
	IDEACLUSTER: [REPORT_BLOB_TYPES.OEQ_IDEACLUSTER],
	INTELLISEGMENT: [REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT],
	MATRIX: [REPORT_BLOB_TYPES.MATRIX_STATISTICS],
	MAXDIFF: [REPORT_BLOB_TYPES.MAXDIFF_STATISTICS],
	OPEN_ANSWERS: [REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT],
	QUANT_QUAL: [REPORT_BLOB_TYPES.CHOICE_STATISTICS, REPORT_BLOB_TYPES.QUANT_QUAL],
	RANKING_ANSWERS: [REPORT_BLOB_TYPES.RANKING_STATISTICS],
	SUNBURST: [
		REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
		REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
		REPORT_BLOB_TYPES.SUNBURST,
	],
	SUNBURST_IPM_INCOMPATIBLE: [
		REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
		REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
	],
	SEGMENTATION_TYPING_TOOL: [REPORT_BLOB_TYPES.SEGMENTATION_TYPING_TOOL_STATISTICS],
}

/**
 * @enum {string[]} MATRIX_ATTRIBUTE_LABEL_KEY
 */
export const MATRIX_ATTRIBUTE_LABEL_KEY = {
	TEXT: 'text',
	SHORTNAME: 'shortName',
}

export const CHART_TYPES_WITH_UNIFIED_THEME_VIEW = [
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.INTELLISEGMENT,
	REPORT_TYPES.OPEN_ANSWERS,
	REPORT_TYPES.QUANT_QUAL,
]

export const AI_DESCRIPTION_SUPPORTED_CHARTS = [
	REPORT_TYPES.ALLOCATION_ANSWERS,
	REPORT_TYPES.CHOICE_ANSWERS,
	REPORT_TYPES.CONSENSUS,
	REPORT_TYPES.FREE_TEXT,
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.INTELLISEGMENT,
	REPORT_TYPES.MATRIX,
	REPORT_TYPES.OPEN_ANSWERS,
	REPORT_TYPES.RANKING_ANSWERS,
]

export const RESULT_NOT_READY = 'RESULT_NOT_READY'

export const MAX_REPORTS_COUNT_LIMIT = 1000

export const SUPPORT_KEYS = {
	STATEMENT_SUPPORTS: 'statementSupports',
	THEME_SUPPORT: 'themeSupport',
}

export const DEFAULT_THEME_VIEW_SETTINGS = {
	isActive: false,
	excludedThemes: [],
	excludedStatements: [],
	showTopUnthemedStatements: true,
}

export const DEFAULT_SCALES_SETTINGS = {
	filter: {
		from: 0,
		to: 100,
	},
	useAutoscale: true,
}

export const STATEMENTS_VIEW = {
	THEMES: 'THEMES',
	ANSWERS: 'ANSWERS',
}

export const LEGEND_COLUMN_HEADERS = {
	ANSWER: 'Answer',
	THEME: 'Theme',
	THEME_NAME: 'Theme name',
	CORRELATION: 'Correlation',
	EXPECTED_SUPPORT: 'Expected Support',
	THEME_SUPPORT: 'Theme support',
	LETTER: 'Letter',
	OPTION_CODE: 'Option code',
	STATEMENT_THEME_NAME: 'Answer theme',
	SUPPORT: 'Support',
	SUPPORT_STRENGTH: 'Support Strength',
	TOTAL_SUPPORT: 'Total Expected Support',
	QUESTION: 'Question',
	OPTION: 'Option',
	ALLOCATION_OPTION: 'Allocation option',
	AVERAGE_VALUE: 'Average',
	RESPONDENTS_COUNT: 'Respondents count',
	ANSWERS_COUNT: '# of answers',
	RESPONSES_COUNT: 'Nr. of responses',
	// copy to clipboard
	COMPLETES: 'Completes',
	PERCENTAGE: 'Percentage',
	AGREE: 'Agree',
	NEUTRAL: 'Neutral',
	DISAGREE: 'Disagree',
	CONFIDENCE_INTERVAL: 'Confidence interval',
	// SEGMENTATION TYPING TOOL
	SEGMENT_NAME: 'Segment name',
}

export const LEGEND_COLUMN_TYPES = {
	ANSWER: 'name',
	ANSWERS_COUNT: 'answersCount',
	CORRELATION: 'correlation',
	LETTER: 'letter',
	MATRIX_OPTION: 'matrixOption',
	MATRIX_QUESTION: 'matrixQuestion',
	OPTION_CODE: 'code',
	STATEMENT_THEME_NAME: 'themeName',
	SUPPORT: 'supportPercent',
	SUPPORT_STRENGTH: 'totalSupportPercent',
	TOTAL_SUPPORT: 'totalExpectedSupport',
	AVERAGE_VALUE: 'average',
	THEME_SUPPORT: 'support',
	// FREETEXT
	VALUE: 'value',
	// INTELLISEGMENT
	TOP_SUPPORT: 'topSupport',
	RIGHT_SUPPORT: 'rightSupport',
	BOTTOM_SUPPORT: 'bottomSupport',
	LEFT_SUPPORT: 'leftSupport',
}

export const NEW_FORMAT_SLIDES = [
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.IDEACLUSTER,
	REPORT_TYPES.INTELLISEGMENT,
]

export const REPORTS_WITH_BUILT_IN_PREVIEW = [
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.IDEACLUSTER,
	REPORT_TYPES.INTELLISEGMENT,
]

export const GRAPH_TYPE_OPTIONS = {
	BAR: 'bar',
	DONUT: 'donut',
}

export const MAXDIFF_ROUND_TYPE_OPTIONS = {
	BEST: {
		VALUE: 'BEST',
		QUESTION_KEY: 'bestQuestion',
		ANSWER_KEY: 'bestAnswer',
	},
	WORST: {
		VALUE: 'WORST',
		QUESTION_KEY: 'worstQuestion',
		ANSWER_KEY: 'worstAnswer',
	},
	DIFF: {
		VALUE: 'DIFF',
		QUESTION_KEY: null,
		ANSWER_KEY: null,
	},
}

// every question with options (choice, ranking, ...)
export const OPTION_LABEL_SOURCE = {
	LABEL: 'LABEL',
	SHORTNAME: 'SHORTNAME',
}

export const MATRIX_CHART_TYPE_OPTIONS = {
	BAR: 'bar',
	STACKED: 'stacked',
}

// MATRIX GROUP BY
export const GROUP_BY_OPTIONS = {
	ROWS: 'rows',
	COLUMNS: 'columns',
}

// MATRIX ATTRIBUTE LABEL
export const ATTRIBUTE_LABEL_OPTIONS = {
	QUESTION: 'QUESTION',
	DISPLAY_NAME: 'DISPLAY_NAME',
}

export const REPORT_EXPORT_WIDTH = 1200

export const CHART_TITLE_HEIGHT = 30

// TIMES and DELAYS
export const ANIMATION_TIME = 250
export const ANIMATION_DELAY = 250
export const DOUBLE_CLICK_WAIT = 200

// GRAPH and SIZES
export const MIN_BUBBLE_WIDTH = 150
export const UNTHEMED_STATEMENTS_TABLE_WIDTH = 300

export const CHARTS_WITH_DETAIL_BUBBLES = [
	REPORT_TYPES.IDEACLUSTER,
	REPORT_TYPES.IDEACLOUD,
	REPORT_TYPES.INTELLISEGMENT,
]

/**
 * NOTE: https://learn.microsoft.com/en-us/bingmaps/v8-web-control/map-control-concepts/heat-map-module-examples/heat-map-color-gradients
 *
 * @enum {string}
 */
export const HEATMAP_GRADIENT_NAMES = {
	COLOR_SPECTRUM: 'COLOR_SPECTRUM',
	BLACK_AQUA_WHITE: 'BLACK_AQUA_WHITE',
	INCANDESCENT: 'INCANDESCENT',
}

/**
 * NOTE: https://developer.mozilla.org/en-US/docs/Web/CSS/named-color
 *
 * @enum {Object.<string, string>}
 */
export const HEATMAP_GRADIENTS = {
	[HEATMAP_GRADIENT_NAMES.COLOR_SPECTRUM]: {
		'0': 'navy',
		'0.25': 'blue',
		'0.5': 'green',
		'0.75': 'yellow',
		'1': 'red',
	},
	[HEATMAP_GRADIENT_NAMES.BLACK_AQUA_WHITE]: {
		'0': 'black',
		'0.5': 'aqua',
		'1': 'white',
	},
	[HEATMAP_GRADIENT_NAMES.INCANDESCENT]: {
		'0': 'black',
		'0.33': 'darkred',
		'0.66': 'yellow',
		'1': 'white',
	},
}

export const ICON_PATHS = {
	chevronDown:
		'M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM273 369.9l135.5-135.5c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L256 285.1 154.4 183.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L239 369.9c9.4 9.4 24.6 9.4 34 0z',

	chevronUp:
		'M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm231-113.9L103.5 277.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L256 226.9l101.6 101.6c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L273 142.1c-9.4-9.4-24.6-9.4-34 0z',

	close:
		'M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z',

	plus:
		'M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z',

	inner_star:
		'M437.64 242.46l-66.05 64.23 15.63 90.86a12 12 0 0 1-17.4 12.66L288 367.27l-81.82 42.94a12 12 0 0 1-17.4-12.66l15.63-90.86-66-64.23A12 12 0 0 1 145 222l91.34-13.28 40.9-82.81a12 12 0 0 1 21.52 0l40.9 82.81L431 222a12 12 0 0 1 6.64 20.46z',

	outer_star:
		'M528.53 171.5l-146.36-21.3-65.43-132.39c-11.71-23.59-45.68-23.89-57.48 0L193.83 150.2 47.47 171.5c-26.27 3.79-36.79 36.08-17.75 54.58l105.91 103-25 145.49c-4.52 26.3 23.22 46 46.48 33.69L288 439.56l130.93 68.69c23.26 12.21 51-7.39 46.48-33.69l-25-145.49 105.91-103c19-18.49 8.48-50.78-17.79-54.57zm-90.89 71l-66.05 64.23 15.63 90.86a12 12 0 0 1-17.4 12.66L288 367.27l-81.82 42.94a12 12 0 0 1-17.4-12.66l15.63-90.86-66-64.23A12 12 0 0 1 145 222l91.34-13.28 40.9-82.81a12 12 0 0 1 21.52 0l40.9 82.81L431 222a12 12 0 0 1 6.64 20.46z',

	exlude_stripe:
		'M172.12 101.27A311.47 311.47 0 0 1 320 64c122.93 0 230.29 71.59 284.52 177.4a32.35 32.35 0 0 1 0 29.19 332.58 332.58 0 0 1-81 102.25l-72.81-56.27a144 144 0 0 0-222.2-171.73zm239 184.73c.55-1.68 1.07-3.38 1.54-5.11a95 95 0 0 0-118-117.08 47.73 47.73 0 0 1 8.21 38.55zM320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a143.62 143.62 0 0 1-26 2.61z',

	exclude_eye:
		'M636.64 480.55L617 505.82a16 16 0 0 1-22.45 2.8L6.18 53.9a16 16 0 0 1-2.81-22.45L23 6.18a16 16 0 0 1 22.45-2.8L633.82 458.1a16 16 0 0 1 2.82 22.45z',

	exclude:
		'M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z',

	gs_logo_01:
		'M6.48561 9.00948L7.38746 9.94281L6.1786 11.0857L4.70111 9.58091V8.419L12.741 0.19043H13.9498L18.9579 5.29519V6.4571L10.9181 14.6857H9.70923L8.53875 13.4857L9.74761 12.3238L10.3233 12.9142L17.1926 5.86662L13.355 1.96186L6.48561 9.00948Z',
	gs_logo_02:
		'M8.65388 1.96186L9.22952 2.55233L10.4384 1.39043L9.24871 0.19043H8.03985L0 8.43805V9.59995L5.00812 14.7047H6.21698L14.2568 6.4571V5.31424L12.7793 3.80948L11.5705 4.95233L12.4723 5.88567L5.60295 12.9333L1.7845 9.00948L8.65388 1.96186Z',
	gs_logo_03:
		'M6.48561 9.00948L7.38746 9.94281L6.1786 11.0857L4.70111 9.58091V8.419L12.741 0.19043H13.9498L18.9579 5.29519V6.4571L10.9181 14.6857H9.70923L8.53875 13.4857L9.74761 12.3238L10.3233 12.9142L17.1926 5.86662L13.355 1.96186L6.48561 9.00948Z',
	gs_logo_04:
		'M27.4967 9.50469H31.0849C30.893 10.0952 30.49 10.5904 29.972 10.9333C29.3579 11.3333 28.6288 11.5428 27.8996 11.5237C27.2856 11.5237 26.6716 11.3714 26.1343 11.0475C25.597 10.7428 25.1557 10.3047 24.8487 9.79041C24.5417 9.25707 24.3882 8.6666 24.3882 8.03803C24.3882 7.40945 24.5417 6.81898 24.8487 6.28564C25.1557 5.75231 25.597 5.33326 26.1343 5.0285C26.6524 4.72374 27.2472 4.57136 27.8613 4.57136C28.4753 4.57136 29.0701 4.72374 29.5882 5.00945C30.1063 5.29517 30.5284 5.69517 30.8546 6.19041L30.9122 6.28564L32.3513 5.19993L32.2937 5.12374C31.814 4.41898 31.1616 3.8285 30.4133 3.4285C29.6458 3.00945 28.7823 2.78088 27.8996 2.79993C26.9594 2.79993 26.0384 3.04755 25.2325 3.50469C24.4266 3.96184 23.7742 4.6285 23.2945 5.4285C22.834 6.2285 22.5845 7.14279 22.5845 8.05707C22.5845 8.97136 22.834 9.88564 23.2945 10.6856C23.755 11.4856 24.4266 12.1333 25.2325 12.6095C26.0384 13.0856 26.9594 13.3142 27.8996 13.3142C28.8015 13.3142 29.7033 13.1047 30.49 12.6856C31.2576 12.2856 31.8908 11.6952 32.3705 10.9714C32.831 10.2856 33.0613 9.4666 33.0613 8.64755V7.80945H27.4967V9.50469V9.50469Z',
	gs_logo_05:
		'M38.1078 5.63807C37.5897 5.61902 37.0716 5.7714 36.6303 6.05712C36.3616 6.22854 36.1314 6.45712 35.9395 6.72378V5.79045H34.2318V13.1428H35.9395V9.18093C35.9395 8.5714 36.1314 8.07616 36.5151 7.75235C36.9181 7.4095 37.417 7.21902 37.9543 7.23807C38.0502 7.23807 38.1461 7.25712 38.2229 7.27616L38.338 7.31426L38.4723 5.67616L38.3764 5.65712C38.2805 5.63807 38.2037 5.63807 38.1078 5.63807Z',
	gs_logo_06:
		'M44.4399 6.15235C43.845 5.80949 43.1734 5.63806 42.4827 5.63806C41.7919 5.63806 41.1203 5.80949 40.5255 6.15235C39.9306 6.4952 39.4509 6.97139 39.1055 7.54282C38.7602 8.1333 38.5875 8.78092 38.5875 9.46663C38.5875 10.1523 38.7602 10.819 39.1055 11.3904C39.4509 11.9809 39.9306 12.4571 40.5255 12.8C41.1203 13.1428 41.7919 13.3143 42.4827 13.3143C43.1734 13.3143 43.845 13.1428 44.4399 12.8C45.0347 12.4571 45.5144 11.9809 45.8598 11.3904C46.2052 10.8 46.3779 10.1333 46.3779 9.46663C46.3779 8.79997 46.2052 8.1333 45.8598 7.54282C45.4952 6.97139 45.0155 6.4952 44.4399 6.15235ZM43.5764 11.3333C43.2502 11.5238 42.8664 11.619 42.4827 11.619C42.0989 11.619 41.7151 11.5238 41.3889 11.3333C41.0627 11.1428 40.7941 10.8762 40.6022 10.5523C40.4103 10.2285 40.3144 9.84759 40.3144 9.46663C40.3144 9.08568 40.4103 8.72378 40.6022 8.38092C40.7941 8.05711 41.0627 7.79044 41.3889 7.59997C41.8111 7.37139 42.2908 7.27616 42.7513 7.3333C43.231 7.39044 43.6723 7.61901 43.9985 7.94282C44.3439 8.28568 44.555 8.70473 44.6126 9.18092C44.6701 9.65711 44.5742 10.1333 44.3439 10.5333C44.1712 10.8762 43.9026 11.1428 43.5764 11.3333Z',
	gs_logo_07:
		'M52.2303 9.88577C52.2303 10.5524 52.0768 11.0477 51.7697 11.3524C51.6162 11.5048 51.4435 11.6001 51.2517 11.6762C51.0598 11.7524 50.8487 11.7905 50.6568 11.7905C50.2347 11.7905 49.8317 11.6572 49.5055 11.4096C49.1985 11.162 49.0258 10.7429 49.0258 10.1715V5.79053H47.3181V10.1715C47.3181 10.9524 47.4908 11.6001 47.817 12.0762C48.1048 12.5143 48.5078 12.8572 48.9875 13.0667C49.4096 13.2381 49.8509 13.3334 50.3114 13.3334C50.7144 13.3334 51.0982 13.2381 51.4627 13.0667C51.7314 12.9334 52 12.762 52.2303 12.5524V13.1429H53.938V5.79053H52.2303V9.88577Z',
	gs_logo_08:
		'M61.5557 6.13339C60.9609 5.79053 60.2893 5.6191 59.5985 5.63815C59.1188 5.63815 58.6391 5.73339 58.1978 5.92386C57.8332 6.07624 57.4878 6.28577 57.1808 6.55244V5.80958H55.4539V16.0001H57.1616V12.4001C57.4686 12.6667 57.814 12.8762 58.1786 13.0477C58.6199 13.2382 59.0996 13.3334 59.5793 13.3334C60.2701 13.3524 60.9417 13.162 61.5365 12.8191C62.093 12.4762 62.5535 12.0001 62.8413 11.4286C63.1484 10.8191 63.3019 10.1524 63.3019 9.46672C63.3019 8.78101 63.1484 8.11434 62.8413 7.50482C62.5727 6.93339 62.1122 6.4572 61.5557 6.13339ZM61.6325 9.46672C61.6325 9.86672 61.5365 10.2477 61.3447 10.5905C61.1528 10.9143 60.8841 11.181 60.5579 11.3715C60.2126 11.562 59.8288 11.6572 59.4258 11.6572C59.0229 11.6572 58.6391 11.562 58.2937 11.3715C57.9675 11.181 57.6797 10.9143 57.4878 10.5905C57.296 10.2477 57.1808 9.86672 57.2 9.46672C57.2 9.06672 57.296 8.66672 57.4878 8.32386C57.6797 7.98101 57.9675 7.71434 58.2937 7.52386C58.6391 7.33339 59.0229 7.23815 59.4258 7.23815C59.8288 7.23815 60.2126 7.33339 60.5579 7.52386C60.8841 7.71434 61.172 7.98101 61.3447 8.32386C61.5365 8.68577 61.6325 9.06672 61.6325 9.46672Z',
	gs_logo_09:
		'M69.8834 7.80948C69.327 7.54282 68.7513 7.33329 68.1565 7.16186C67.7343 7.02853 67.3314 6.8952 66.9284 6.72377C66.6982 6.62853 66.4871 6.4952 66.3144 6.30472C66.1801 6.13329 66.1033 5.92377 66.1225 5.71424C66.1225 5.56186 66.1609 5.39043 66.2377 5.2571C66.3144 5.10472 66.4103 4.99043 66.5447 4.8952C66.8325 4.66663 67.1971 4.53329 67.6576 4.53329C68.617 4.53329 69.2694 4.97139 69.6531 5.86663L69.6915 5.96186L71.2458 5.08567L71.2074 5.00948C70.9196 4.32377 70.4207 3.75234 69.7875 3.35234C69.1543 2.95234 68.4059 2.74282 67.6576 2.78091C67.0819 2.78091 66.5255 2.8952 66.0074 3.14282C65.5085 3.37139 65.0864 3.73329 64.7985 4.17139C64.4915 4.64758 64.338 5.18091 64.338 5.75234C64.338 6.34282 64.4915 6.83805 64.7602 7.21901C65.048 7.59996 65.4126 7.90472 65.8539 8.11424C66.3912 8.36186 66.9284 8.57139 67.4849 8.74282C68.2908 8.97139 68.8664 9.19996 69.2118 9.42853C69.538 9.63805 69.7107 9.90472 69.7107 10.2476C69.7107 10.6095 69.5572 10.9142 69.2502 11.1809C68.9432 11.4476 68.5019 11.6 67.907 11.6C67.2738 11.6 66.7557 11.4666 66.3528 11.1809C65.9306 10.8952 65.6236 10.4761 65.4701 9.99996L65.4317 9.88567L63.8775 10.7619L63.8967 10.8381C64.1653 11.5809 64.6642 12.2285 65.3358 12.6666C66.0266 13.1047 66.8709 13.3333 67.8495 13.3333C68.4827 13.3523 69.1351 13.219 69.7107 12.9714C70.2288 12.7428 70.6893 12.3809 71.0155 11.9238C71.3417 11.4476 71.4952 10.8952 71.4952 10.3238C71.4952 9.71424 71.3417 9.18091 71.0347 8.78091C70.7277 8.36186 70.3439 8.03805 69.8834 7.80948Z',
	gs_logo_10:
		'M77.9808 6.15235C77.386 5.80949 76.7144 5.63806 76.0236 5.63806C75.3328 5.63806 74.6613 5.80949 74.0664 6.15235C73.4716 6.4952 72.9919 6.97139 72.6465 7.54282C72.3011 8.1333 72.1284 8.78092 72.1284 9.46663C72.1284 10.1523 72.3011 10.819 72.6465 11.3904C72.9919 11.9809 73.4716 12.4571 74.0664 12.8C74.6613 13.1428 75.3328 13.3143 76.0236 13.3143C76.7144 13.3143 77.386 13.1428 77.9808 12.8C78.5757 12.4571 79.0554 11.9809 79.4007 11.3904C79.7461 10.8 79.9188 10.1333 79.9188 9.46663C79.9188 8.79997 79.7461 8.1333 79.4007 7.54282C79.0554 6.97139 78.5565 6.47616 77.9808 6.15235ZM77.1173 11.3333C76.7911 11.5238 76.4074 11.619 76.0236 11.619C75.6399 11.619 75.2561 11.5238 74.9299 11.3333C74.6037 11.1428 74.3351 10.8762 74.1432 10.5523C73.9513 10.2285 73.8554 9.84759 73.8554 9.46663C73.8554 9.08568 73.9513 8.70473 74.1432 8.38092C74.3351 8.05711 74.6037 7.79044 74.9299 7.59997C75.2561 7.40949 75.6399 7.31425 76.0236 7.31425C76.4074 7.31425 76.7911 7.40949 77.1173 7.59997C77.4435 7.79044 77.7122 8.05711 77.9041 8.38092C78.0959 8.70473 78.1919 9.08568 78.1919 9.46663C78.1919 9.84759 78.0959 10.2095 77.9041 10.5523C77.7122 10.8762 77.4435 11.1428 77.1173 11.3333Z',
	gs_logo_11:
		'M89.3211 5.79053L87.3063 10.9905L85.3107 5.84767L85.2915 5.79053H83.4687L86.3085 13.1429H88.3041L91.1439 5.79053H89.3211Z',
	gs_logo_12:
		'M96.8812 6.13327C96.3055 5.79041 95.634 5.59993 94.9624 5.61898C94.2524 5.59993 93.5616 5.79041 92.9476 6.13327C92.372 6.45708 91.8923 6.93327 91.5661 7.52374C91.2399 8.13327 91.0672 8.79993 91.0672 9.48565C91.0672 10.1714 91.2399 10.8571 91.5661 11.4476C91.8923 12.038 92.3912 12.5142 92.9668 12.819C93.6 13.1618 94.3292 13.3333 95.0583 13.3142C95.7107 13.3333 96.3631 13.1809 96.9579 12.8952C97.4952 12.6285 97.9557 12.2095 98.3011 11.7142L98.3587 11.619L96.9963 10.838L96.9388 10.9142C96.7469 11.1999 96.4974 11.4095 96.1712 11.5428C95.8258 11.7142 95.4613 11.7904 95.0775 11.7904C94.4635 11.7904 93.9454 11.619 93.5424 11.2952C93.1779 10.9904 92.9092 10.5714 92.7941 10.0952H98.5889L98.6081 10.019C98.7616 9.16184 98.6273 8.28565 98.186 7.52374C97.8982 6.97136 97.4376 6.47612 96.8812 6.13327ZM93.8495 7.37136C94.1948 7.19993 94.5594 7.12374 94.9432 7.12374C95.5188 7.12374 95.9793 7.27612 96.3631 7.58088C96.7085 7.88565 96.9388 8.28565 97.0155 8.74279H92.7749C92.8133 8.47612 92.9092 8.2285 93.0627 7.99993C93.2738 7.73327 93.5424 7.50469 93.8495 7.37136Z',
	gs_logo_13:
		'M103.904 5.65712C103.808 5.63807 103.731 5.63807 103.635 5.63807C103.098 5.61902 102.58 5.7714 102.139 6.05712C101.87 6.22854 101.64 6.45712 101.448 6.72378V5.79045H99.7402V13.1428H101.448V9.18093C101.448 8.5714 101.64 8.07616 102.024 7.75235C102.427 7.4095 102.925 7.21902 103.463 7.23807C103.559 7.23807 103.655 7.25712 103.731 7.27616L103.847 7.31426L103.981 5.67616L103.904 5.65712Z',
	gs_logo_14: 'M82.7011 2.93335H80.9934V13.181H82.7011V2.93335Z',
}

// TODO: temp fix
// magic number so we don't have to update expected values in tests
export const DEFAULT_CHART_HEIGHT = 397
